import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import {
  ADD_TAX_CODE,
  GET_TAX_CODES,
  GET_TAX_CODE_BY_ID,
  ITaxCode,
  UPDATE_TAX_CODE,
} from '../../utils/TaxCode';
import { CommandBarSticky } from '../../components/parts';
import { commandBarThemeCounter, commandBarTheme } from '../../theme';
import {
  DEFAULT_TOAST_POSITION,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_ERROR_MESSAGE,
} from '../../utils';
import { toastSuccess, toastError } from '../../utils/toast';
import CostCenterDetail from '../cost-center/components/CostCenterWingsDetail';
import CostCenterDetailsList from '../cost-center/components/CostCentersDetailsList';
import TaxCodeDetail from './TaxCodeDetail';
import TaxCodeDetailsList from './TaxCodeDetailList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  taxCodes: ITaxCode[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  refetch?: any;
}

const TaxCodesOverview = ({
  taxCodes,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  refetch,
}: Props) => {
  const [taxCode, setTaxCode] = useState<ITaxCode | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [AddTaxCode] = useMutation(ADD_TAX_CODE);
  const [modifyTaxCode] = useMutation(UPDATE_TAX_CODE);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ITaxCode | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchTaxCode } = useQuery(GET_TAX_CODE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setTaxCode(data.findOneTaxCode);
    },
  });

  const openTaxCodeDetail = (newTaxCode?: boolean) => {
    if (selectionDetails && !newTaxCode) {
      // getCostCenter({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setTaxCode(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveTaxCode = async () => {
    try {
      if (taxCode) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              rate: taxCode?.rate ? Number(taxCode.rate) : 0,
              label: taxCode.label,
              code: taxCode.code,
              description: taxCode.description,
              requireInformation: taxCode.requireInformation,
            };

            if (taxCode.id) {
              modifyTaxCode({
                variables: {
                  id: taxCode.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  setIsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              AddTaxCode({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createTaxCode) {
          setTaxCode(x.createTaxCode);
          toastSuccess('BTW code succesvol opgeslagen');
          refetch();
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${taxCodes.length} / ${
        taxCodes.length > 0 ? taxCodes[0].prisma_total : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openTaxCodeDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openTaxCodeDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMoreCallback();
      }
    });
  });

  useEffect(() => {
    if (taxCode && isPanelOpen) {
      document.title = `3bouw | BTW code - ${taxCode.label}`;
    } else {
      document.title = '3bouw | BTW codes';
    }
  }, [taxCode, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1034px'
        maxWidth='1034px'
      />

      <TaxCodeDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        taxCode={taxCode}
        saveTaxCode={saveTaxCode}
        setTaxCode={setTaxCode}
        refetch={refetchTaxCode}
      />

      {taxCodes && (
        <>
          <TaxCodeDetailsList
            items={taxCodes}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openTaxCodeDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === costCenters.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && taxCodes.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default TaxCodesOverview;
