import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  ADD_COST_CENTER,
  GET_COST_CENTERS,
  GET_COST_CENTER_BY_ID,
  ICostCenter,
  UPDATE_COST_CENTER,
} from '../../../utils/CostCenter';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  IContactPerson,
} from '../../../utils';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { toastSuccess, toastError } from '../../../utils/toast';
import CostCenterDetail from './CostCenterDetail';
import CostCenterDetailsList from './CostCentersDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  costCenters: ICostCenter[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
}

const CostCentersOverview = ({
  costCenters,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: Props) => {
  const [costCenter, setCostCenter] = useState<ICostCenter | undefined>(
    undefined,
  );
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addCostCenter] = useMutation(ADD_COST_CENTER);
  const [modifyCostCenter] = useMutation(UPDATE_COST_CENTER);

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICostCenter | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchCostCenter } = useQuery(GET_COST_CENTER_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setCostCenter(data.findOneCostCenter);
    },
  });

  const openCostCenterDetail = (newCostCenter?: boolean) => {
    if (selectionDetails && !newCostCenter) {
      // getCostCenter({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setCostCenter(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveCostCenter = async () => {
    try {
      if (costCenter) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              name: costCenter.name,
              active: costCenter.active || false,
              customer_specific: costCenter.customer_specific || false,
            };

            if (costCenter.id) {
              modifyCostCenter({
                variables: {
                  id: costCenter.id,
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  setIsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              addCostCenter({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createCostCenter) {
          setCostCenter(x.createCostCenter);
          toastSuccess('Boekhoudkundige post succesvol opgeslagen');
        }
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${costCenters.length} / ${costCenters.length > 0 ? costCenters[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCostCenterDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCostCenterDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMoreCallback();
      }
    });
  });

  useEffect(() => {
    if (costCenter && isPanelOpen) {
      document.title = `3bouw | Boekhoudkundige post - ${costCenter.name}`;
    } else {
      document.title = '3bouw | Boekhoudkundige posten';
    }
  }, [costCenter, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1034px'
        maxWidth='1034px'
      />

      <CostCenterDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        costCenter={costCenter}
        saveCostCenter={saveCostCenter}
        setCostCenter={setCostCenter}
        refetch={refetchCostCenter}
      />

      {costCenters && (
        <>
          <CostCenterDetailsList
            items={costCenters}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openCostCenterDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
                // display: items.length === costCenters.length ? 'block' : 'none',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && costCenters.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default CostCentersOverview;
