import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  IconButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
} from '@fluentui/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useAppDispatch } from '../../../redux/hooks';
import { GET_WORK_ORDER_BY_ID, IWorkOrder } from '../../../utils/WorkOrder';
import { cancelIcon, iconButtonStyles, stackTokens5 } from '../../../utils';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import WorkOrderDownloadTemplate from './WorkOrderDownloadTemplate';

const WorkOrdersDynamicPdf = ({ workOrderSource, setShowPdf }: any) => {
  const dispatch = useAppDispatch();
  const [workOrder, setWorkOrder] = useState<IWorkOrder | undefined>();

  const { loading, error } = useQuery(GET_WORK_ORDER_BY_ID, {
    variables: {
      where: {
        id: workOrderSource.id,
      },
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'workOrderPdf',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    onCompleted: (data: any) => {
      setWorkOrder(data.findOneDefectWorkOrder);
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText='PDF Bekijken'
      type={PanelType.custom}
      customWidth='100%'
    >
      {!loading && !error && workOrder && (
        <Stack
          styles={{
            root: {
              position: 'fixed',
              top: 60,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'white',
              marginTop: '0!important',
              display: 'flex',
              padding: '20px',
            },
          }}
        >
          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <WorkOrderDownloadTemplate workOrder={workOrder} />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Panel>
  );
};

export default WorkOrdersDynamicPdf;
