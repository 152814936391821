import React from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { modalContentStyles } from '../../../utils';
import DateView from '../../../components/parts/DateView';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';

const PaymentDetail = ({
  isOpen,
  dismissPanel,
  savePayment,
  payment,
  setPayment,
}: any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setPayment((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue,
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        payment && payment.id ? 'Betaling wijzigen' : 'Betaling toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {payment && payment.id && <Label>ID: {payment.id}</Label>}

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={payment}
              setItem={setPayment}
              date={payment && payment.payment_date}
              label='Datum'
              field='payment_date'
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='amount'
              label='Bedrag'
              prefix='€'
              value={payment && payment.amount ? payment.amount : ''}
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Referentie / Nota'
              multiline
              value={payment && payment.comments ? payment.comments : ''}
              onChange={onChangeTextFieldValue}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !payment ||
                  (payment && (!payment.payment_date || !payment.amount))
                }
                save={savePayment}
                permission='write:customerInvoicePayments'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default PaymentDetail;
