/* eslint-disable no-unneeded-ternary */
import {
  Checkbox,
  DefaultButton,
  IconButton,
  Label,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  Text,
  IComboBoxOption,
  IStackItemStyles,
} from '@fluentui/react';
import { getTheme } from '@fluentui/react/lib/Styling';
import React, { FormEvent, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  ComboboxWithFilter,
  DatePickerNL,
  TimePicker,
} from '../../../components/parts';
import {
  cancelIcon,
  convertSuppliersToComboBoxOptions,
  iconButtonStyles,
  IConstructionSite,
  IPurchaseOrder,
  ISupplier,
  modalContentStyles,
  stackTokens5,
  stackTokens50,
} from '../../../utils';

const theme = getTheme();

const styleTitle: IStackItemStyles = {
  root: {
    background: theme.palette.neutralLight,
    padding: '10px',
    marginTop: '15px',
    marginBottom: '15px',
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    h3: {
      margin: 0,
    },
  },
};

type PurchaseOrderFooterProps = {
  purchaseOrder?: IPurchaseOrder;
  constructionSite?: IConstructionSite;
  setPurchaseOrder: React.Dispatch<
    React.SetStateAction<IPurchaseOrder | undefined>
  >;
  suppliers?: ISupplier[];
  setSupplierFilter: React.Dispatch<React.SetStateAction<string | undefined>>
};

const PurchaseOrderFooter = ({
  purchaseOrder,
  setPurchaseOrder,
  constructionSite,
  suppliers,
  setSupplierFilter,
}: PurchaseOrderFooterProps) => {
  // Constants

  // Event handlers
  const onChangeDate = (newDate: Date) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});

    let currentDate;
    let hour;
    let minutes;
    if (purchaseOrder && purchaseOrder.delivery_date) {
      currentDate = moment(purchaseOrder.delivery_date);
      hour = currentDate.format('HH');
      minutes = currentDate.format('mm');
    }

    const newParsedDate = moment(newDate);

    if (currentDate && hour && minutes) {
      newParsedDate
        .set('h', parseFloat(hour))
        .set('m', parseFloat(minutes))
        .add(1, 's');
    }

    if (currentOrder) {
      currentOrder.delivery_date = newParsedDate.toISOString();
      setPurchaseOrder(currentOrder);
    }
  };

  const onChangeDeliverToConstructionSite = (
    ev?: FormEvent<HTMLInputElement | HTMLElement> | undefined,
    checked?: boolean | undefined,
  ) => {
    if (checked !== undefined) {
      const currentOrder = _.cloneDeep(purchaseOrder || {});
      if (currentOrder) {
        currentOrder.delivery_to_other_supplier = !checked;
        console.log(currentOrder);
        setPurchaseOrder(currentOrder);
      }
    }
  };

  const setDeliverySupplier = (deliverySupplier: ISupplier) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    if (currentOrder) {
      currentOrder.deliver_to_supplier = deliverySupplier;
      setPurchaseOrder(currentOrder);
    }
  };

  const onChangeRemarks = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    if (currentOrder) {
      currentOrder.remarks = newValue;
      setPurchaseOrder(currentOrder);
    }
  };

  const updateIsOnCall = (isOnCall: boolean) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    if (currentOrder) {
      currentOrder.is_on_call = !!isOnCall;
      setPurchaseOrder(currentOrder);
    }
  };

  const updatePickUp = (pickUp: boolean) => {
    const currentOrder = _.cloneDeep(purchaseOrder || {});
    if (currentOrder) {
      currentOrder.pick_up = !!pickUp;
      setPurchaseOrder(currentOrder);
    }
  };

  // Display functions
  const getDeliveryAddressLabel = () => {
    if (purchaseOrder && purchaseOrder.delivery_to_other_supplier === true) {
      if (purchaseOrder.deliver_to_supplier) {
        return (
          <Text style={{ marginTop: 0, marginBottom: 3 }}>
            {purchaseOrder.deliver_to_supplier.name}
            {' - '}
            {purchaseOrder.deliver_to_supplier.address}
            {', '}
            {purchaseOrder.deliver_to_supplier.zip_code}{' '}
            {purchaseOrder.deliver_to_supplier.city}
          </Text>
        );
      }
    } else {
      if (purchaseOrder && purchaseOrder.construction_site) {
        return (
          <Text style={{ marginTop: 0, marginBottom: 3 }}>
            {/* {order.construction_site.name} */}
            {'Levering op de werf: '}
            {purchaseOrder.construction_site.address}
            {', '}
            {purchaseOrder.construction_site.zip_code}{' '}
            {purchaseOrder.construction_site.city}{' '}
            {/* {order.construction_site.comments} */}
          </Text>
        );
      }
      if (constructionSite) {
        return (
          <Text style={{ marginTop: 0, marginBottom: 3 }}>
            {/* {constructionSite.name} */}
            {'Levering op de werf: '}
            {constructionSite.address}
            {', '}
            {constructionSite.zip_code} {constructionSite.city}{' '}
            {/* {constructionSite.comments} */}
          </Text>
        );
      }
    }
  };

  const callBackTimePicker = (value: string) => {
    setPurchaseOrder(prevState => {
      const currentOrder = _.cloneDeep(purchaseOrder || {});
      currentOrder.delivery_date = value;
      return currentOrder;
    });
  };

  const changeDeliveryDuringDay = (
    ev?: FormEvent<HTMLInputElement | HTMLElement> | undefined,
    checked?: boolean | undefined,
  ) => {
    const newValue = checked !== undefined ? checked : false;
    const currentOrder = _.cloneDeep(purchaseOrder || {});

    if (currentOrder) {
      currentOrder.no_delivery_hour = newValue;
      setPurchaseOrder(currentOrder);
    }
  };

  const formatDeliveryDate = (value: string) => moment(value).toISOString();
  return (
    <Stack
      // horizontal
      // horizontalAlign='start'
      tokens={{ childrenGap: 30 }}
      style={{ padding: '0 24px 0px 24px', width: '100%' }}
    >
      <Stack
        // onClick={() => toggleItem(id)}
        styles={styleTitle}
        horizontal
        horizontalAlign='space-between'
        style={{ marginBottom: '0px' }}
      >
        <Stack.Item>
          <h3>Levering</h3>
        </Stack.Item>
      </Stack>
      <Stack tokens={stackTokens5}>
        <Stack horizontal tokens={stackTokens50}>
          <Stack tokens={stackTokens5}>
            <Label>Waar leveren?:</Label>
            <Checkbox
              label='Afhalen bij leverancier'
              checked={purchaseOrder && purchaseOrder.pick_up ? true : false}
              onChange={(
                ev?:
                  | React.FormEvent<HTMLInputElement | HTMLElement>
                  | undefined,
                checked?: boolean | undefined,
              ) => {
                updatePickUp(checked !== undefined ? checked : false);
              }}
            />
            <Checkbox
              label='Levering op de werf'
              checked={
                            purchaseOrder && !purchaseOrder.delivery_to_other_supplier
                          }
              onChange={onChangeDeliverToConstructionSite}
              styles={{ root: { marginTop: '10px' } }}
            />
          </Stack>
          {purchaseOrder && !purchaseOrder.pick_up && (
            <Stack tokens={stackTokens5} style={{ minWidth: '200px' }}>
              <Label>Leveringsadres:</Label>
              {getDeliveryAddressLabel()}
            </Stack>
          )}
          {purchaseOrder && purchaseOrder.delivery_to_other_supplier && (
          <Stack>
            <Stack.Item>

              {purchaseOrder &&
                          purchaseOrder.delivery_to_other_supplier &&
                          suppliers && (
                            <ComboboxWithFilter
                              allowFreeForm
                              label='Leverancier'
                              callBack={(selection: IComboBoxOption[]) => {
                                const ids = selection.map(option => option.key);
                                if (suppliers.length > 0) {
                                  const selectedSupplier = suppliers.filter(supplier => {
                                    if (supplier && ids.includes(supplier.id)) {
                                      return true;
                                    }
                                    return false;
                                  })[0]; // can only select one otherwise leave [0] out @TP

                                  if (selectedSupplier) {
                                    setDeliverySupplier(selectedSupplier);
                                  }
                                }
                                // setSelectedSupplier(0);
                              }}
                              options={
                                suppliers && Array.isArray(suppliers)
                                  ? convertSuppliersToComboBoxOptions(suppliers)
                                  : []
                              }
                              setFilter={(filter: string) => { setSupplierFilter(filter); }}
                              multiline={false}
                              value={
                                purchaseOrder && purchaseOrder.deliver_to_supplier
                                  ? [purchaseOrder.deliver_to_supplier.id]
                                  : undefined
                              }
                            />
                          )}
            </Stack.Item>
          </Stack>
          )}
        </Stack>
        <Stack horizontal tokens={stackTokens50} style={{ marginTop: '15px' }}>
          {purchaseOrder && (
            <Stack tokens={stackTokens5}>
              <Label>Op afroep?:</Label>
              <Checkbox
                label='Op afroep'
                checked={!!(purchaseOrder && purchaseOrder.is_on_call)}
                onChange={(
                  ev?:
                    | React.FormEvent<HTMLInputElement | HTMLElement>
                    | undefined,
                  checked?: boolean | undefined,
                ) => {
                  updateIsOnCall(checked !== undefined ? checked : false);
                }}
              />
            </Stack>
          )}

          <Stack tokens={stackTokens5}>
            {purchaseOrder && purchaseOrder.is_on_call && purchaseOrder.delivery_date ? (
              <>
                <Label>Richtdatum (week):</Label>
                <h5 style={{ marginBottom: 0 }}>
                  Week: {moment(purchaseOrder.delivery_date).week()}
                </h5>
              </>
            ) : (
              <Label>Leverdatum:</Label>
            )}

            <DatePickerNL
              updateParent={onChangeDate}
              showWeekNumbers={purchaseOrder && purchaseOrder.is_on_call}
              value={
                purchaseOrder && purchaseOrder.delivery_date
                  ? formatDeliveryDate(purchaseOrder.delivery_date)
                  : undefined
              }
            />
          </Stack>

          <Stack tokens={stackTokens5}>
            {purchaseOrder && !purchaseOrder.is_on_call && purchaseOrder.delivery_date && (
              <Stack>
                <Label>Tijdstip:</Label>
                <StackItem tokens={stackTokens50}>
                  <Checkbox
                    label='In de loop van de dag'
                    checked={purchaseOrder && purchaseOrder.no_delivery_hour}
                    onChange={changeDeliveryDuringDay}
                  />
                  {purchaseOrder && !purchaseOrder.no_delivery_hour && (
                    <StackItem style={{ marginTop: 15 }}>
                      <TimePicker
                        callback={callBackTimePicker}
                        currentDate={purchaseOrder.delivery_date}
                        divider=':'
                      />
                    </StackItem>
                  )}
                </StackItem>
              </Stack>
            )}
          </Stack>
        </Stack>

        <TextField
          label='Opmerkingen:'
          value={
            purchaseOrder && purchaseOrder.remarks ? purchaseOrder.remarks : ''
          }
          multiline
          styles={{ fieldGroup: { width: '100%' } }}
          rows={6}
          onChange={onChangeRemarks}
        />

        {/* {getContactDetailsDelivery()} */}
      </Stack>
    </Stack>
  );
};

export default PurchaseOrderFooter;
