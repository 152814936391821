import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  Dialog,
  DialogFooter,
  TextField,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { modalContentStyles } from '../../../utils';
import {
  dialogModelProps,
  dialogRemoveConfirmationPropsGeneral,
} from '../../../utils/Styles';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { ICostCenterWings } from '../../../utils/ConstCenterWings';

const CostCenterDetail = ({
  isOpen,
  dismissPanel,
  saveCostCenterWings,
  costCenterWings,
  setCostCenterWings,
  refetchCostCenter,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCostCenterWings((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]:
        // eslint-disable-next-line no-restricted-globals
        (newValue && !isNaN(parseInt(newValue, 10))
          ? parseInt(newValue as any, 10)
          : '') || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        costCenterWings && costCenterWings.id
          ? 'Nummer wijzigen'
          : 'Nummer toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {costCenterWings && costCenterWings.id && (
            <Label>ID: {costCenterWings.id}</Label>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <TextField
              name='wings_number'
              label='Nummer'
              type='number'
              value={
                costCenterWings && costCenterWings.wings_number
                  ? costCenterWings.wings_number
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={!costCenterWings}
                save={saveCostCenterWings}
                permission='write:carDrivers' // TODO
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default CostCenterDetail;
