import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  ComboBox,
  DefaultButton,
  Dialog,
  DialogFooter,
  IComboBox,
  IComboBoxOption,
  Label,
  Modal,
  Panel,
  PanelType,
  TextField,
  PrimaryButton,
  Selection,
  Stack,
  Toggle,
  DialogType,
} from '@fluentui/react';
import React, { FormEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {
  convertPropertyListTypeToComboBoxOptions,
  modalContentStyles,
  overviewControlStyles,
  property_types,
  stackTokens10,
  stackTokens5,
  template_types,
} from '../../../utils';
import {
  TemplateAddExistingItem,
  TemplateAddModifyItem,
  TemplateCommandBar,
  TemplateDetailsList,
} from '..';
import { ISuggestedValueCategory } from '../../../utils/SuggestedValueCategory';
import { IPropertyListType } from '../../../utils/PropertyListType';
import { IPropertyType } from '../../../utils/PropertyType';
import { ITemplateItem } from '../../../utils/TemplateItem';
import { ITemplateTemplateItem } from '../../../utils/TemplateTemplateItem';
import { ITemplate } from '../../../utils/Template';
import { ITemplateState } from '../Template';

const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: 450 } },
};

type ITemplateOverviewProps = {
  templateState: ITemplateState;
  setTemplateState: React.Dispatch<React.SetStateAction<ITemplateState>>;
  propertyTypes: IPropertyType[];
  saveTemplate: any;
  deleteTemplateItemChildren: any;
  deleteTemplateItemFromTemplate: any;
  listTypes?: IPropertyListType[];
  suggestedValueCategories?: ISuggestedValueCategory[];
};

const TemplateOverview = ({
  templateState,
  setTemplateState,
  propertyTypes,
  saveTemplate,
  deleteTemplateItemChildren,
  deleteTemplateItemFromTemplate,
  suggestedValueCategories = [],
  listTypes = [],
}: ITemplateOverviewProps) => {
  // constants
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const {
    selectedItem,
    selectedItemParent,
    templateType,
    selectedListType,
    hasName,
    // hasNewItemName,
    modalOpen,
    modalModify,
    confirmationHidden,
    // selectedItemAddToExecutionList: addToExecutionList,
    // selectedItemAddToConstructionList: addToConstructionSheet,
    // selectionExcluded,
    selectionIncluded,
    template,
    searchText,
  } = templateState;

  const [addSelectionToExecutionList, setAddSelectionToExecutionList] =
    useState<boolean | undefined>();
  const [addSelectionToConstructionSheet, setAddSelectiontoConstructionSheet] =
    useState<boolean | undefined>();

  const onSelectionExcludedChanged = () => {
    const selection: any = _selectionExcluded.getSelection();
    if (templateState) {
      setTemplateState(prevState => ({
        ...prevState,
        selectionExcluded: selection,
      }));
    }
  };

  const onSelectionIncludedChanged = () => {
    const selection: any = _selectionIncluded.getSelection();
    if (templateState && selection.length > 0) {
      setTemplateState(prevState => ({
        ...prevState,
        selectionIncluded: selection,
        selectedItem: selection[0].template_item,
        newItemPositionConstruction: selection[0].weight_for_construction_sheet,
        newItemPositionExecution: selection[0].weight_for_execution_list,
        /* selectedItemAddToConstructionList: !!selection[0]
          .weight_for_construction_sheet,
        selectedItemAddToExecutionList: !!selection[0].weight_for_execution_list, */
      }));

      setAddSelectionToExecutionList(
        selection[0].weight_for_execution_list !== -1,
      );
      setAddSelectiontoConstructionSheet(
        selection[0].weight_for_construction_sheet !== -1,
      );
    } else {
      setTemplateState(prevState => ({
        ...prevState,
        selectionIncluded: selection,
        newItemPositionConstruction: -1,
        newItemPositionExecution: -1,
      }));
      setAddSelectionToExecutionList(false);
      setAddSelectiontoConstructionSheet(false);
    }
  };

  const [_selectionExcluded, setSelectionExcluded] = useState(
    new Selection({
      onSelectionChanged: onSelectionExcludedChanged,
    }),
  );
  const [_selectionIncluded, setSelectionIncluded] = useState(
    new Selection({
      onSelectionChanged: onSelectionIncludedChanged,
    }),
  );

  const addExistingItem = () => {
    toggleModal();
  };

  const getMinimumWeight = (items: ITemplateTemplateItem[]) => {
    const weights: number[] = [];
    if (items) {
      items.forEach(item => {
        if (item.weight_for_execution_list) {
          weights.push(item.weight_for_execution_list);
        } else if (item.weight_for_construction_sheet) {
          weights.push(item.weight_for_construction_sheet);
        }
      });
    }

    return Math.min(...weights);
  };

  const getMaximumWeight = (items: ITemplateTemplateItem[]) => {
    const weights: number[] = [];
    if (items) {
      items.forEach(item => {
        if (item.weight_for_execution_list) {
          weights.push(item.weight_for_execution_list);
        } else if (item.weight_for_construction_sheet) {
          weights.push(item.weight_for_construction_sheet);
        }
      });
    }

    return Math.max(...weights);
  };

  const cannotMoveUp = () => {
    if (template && template.template_template_items) {
      const minWeight = getMinimumWeight(template.template_template_items);

      const selection: any = selectionIncluded;

      if (selection && selection.length > 0) {
        // solve type issue
        const templateItem: ITemplateTemplateItem = selection[0];

        if (
          templateItem &&
          templateType === template_types.CONSTRUCTION_SHEET
        ) {
          return minWeight >= templateItem.weight_for_construction_sheet;
        }
        if (templateItem) {
          return minWeight >= templateItem.weight_for_execution_list;
        }
      } else return true;
    }

    return true;
  };

  const cannotMoveDown = () => {
    if (template && template.template_template_items) {
      const maxWeight = getMaximumWeight(template.template_template_items);

      const selection: any = selectionIncluded;

      if (selection && selection.length > 0) {
        // solve type issue
        const templateItem: ITemplateTemplateItem = selection[0];

        if (
          templateItem &&
          templateType === template_types.CONSTRUCTION_SHEET
        ) {
          return maxWeight <= templateItem.weight_for_construction_sheet;
        }
        if (templateItem) {
          return maxWeight <= templateItem.weight_for_execution_list;
        }
      } else return true;
    }

    return true;
  };

  const changeToggle = () => {
    _selectionIncluded.setItems(_selectionIncluded.getSelection(), true);
    const newType =
      templateType === template_types.EXECUTION_LIST
        ? template_types.CONSTRUCTION_SHEET
        : template_types.EXECUTION_LIST;

    setTemplateState(prevState => ({
      ...prevState,
      templateType: newType,
    }));
  };

  const clearModal = () => {
    setTemplateState(prevState => ({
      ...prevState,

      modalModify: false,
    }));
  };

  const containsChanges = () => {
    if (template && template.template_template_items) {
      return (
        template.template_template_items.filter(
          (item: ITemplateTemplateItem) => item.template_item.draft,
        ).length > 0
      );
    }

    return false;
  };

  const getParentIndex = (
    parentId: number,
    items: ITemplateTemplateItem[],
  ): number | undefined => {
    if (items) {
      let parentIndex: number | undefined;
      let i;
      for (i = 0; i < items.length; i++) {
        const template_item_item = items[i];
        const template_item = template_item_item.template_item;
        if (template_item.id === parentId) parentIndex = i;
      }

      return parentIndex;
    }
    return undefined;
  };

  const getChildIndex = (
    childId: number,
    items: ITemplateItem[],
  ): number | undefined => {
    if (items) {
      let childIndex: number | undefined;
      let i;
      for (i = 0; i < items.length; i++) {
        const template_item = items[i];
        if (template_item.id === childId) childIndex = i;
      }

      return childIndex;
    }
    return undefined;
  };

  const deleteChild = ({
    parent,
    child,
    index,
  }: {
    parent: number;
    child?: number;
    index: number;
  }) => {
    const parentId = parent;
    const childId = child;

    const parentIndex = template
      ? getParentIndex(parentId, template.template_template_items)
      : undefined;
    const parentItem =
      (parentIndex || parentIndex === 0) && template
        ? { ...template.template_template_items[parentIndex].template_item }
        : undefined;
    const childIndex =
      parentItem && parentItem.children && childId
        ? getChildIndex(childId, parentItem?.children)
        : index;
    const childItem: ITemplateItem | undefined =
      (childIndex || childIndex === 0) && parentItem && parentItem.children
        ? { ...parentItem.children[childIndex] }
        : undefined;

    const currentChildren =
      parentItem && parentItem.children ? parentItem.children : undefined;
    const newChildren = currentChildren;

    if ((childIndex || childIndex === 0) && newChildren) {
      newChildren[childIndex].delete = true;
    }

    /*
    if (
      templateType === template_types.EXECUTION_LIST &&
      newChildren &&
      childItem
    ) {
      newChildren = newChildren.map((child: ITemplateItem) => {
        if (
          child.child_pos_execution_list &&
          childItem.child_pos_execution_list &&
          child.child_pos_execution_list > childItem.child_pos_execution_list
        ) {
          child.child_pos_execution_list -= 1;
          child.dirty = true;
        }
        return child;
      });
    } else if (newChildren && childItem) {
      newChildren = newChildren.map((child: ITemplateItem) => {
        if (
          childItem.child_pos_construction_sheet &&
          child.child_pos_construction_sheet &&
          child.child_pos_construction_sheet >
            childItem.child_pos_construction_sheet
        ) {
          child.child_pos_construction_sheet -= 1;
          child.dirty = true;
        }
        return child;
      });
    }

    if (childItem && !childItem.draft) {
      deleteTemplateItemChildren({
        variables: {
          ids: [childId],
          parent_id: parentId,
        },
      });
    }

    */

    if (parentItem) {
      parentItem.dirty = true;
      parentItem.children = newChildren;

      // reset parent type if no other children
      if (parentItem.children && parentItem.children.length === 0) {
        if (parentItem.suggested_value_category_id) {
          parentItem.type = propertyTypes.filter(
            (el: IPropertyType) => el.name === property_types.SUGGESTED_VALUE,
          )[0];
        } else if (parentItem.document) {
          parentItem.type = propertyTypes.filter(
            (el: IPropertyType) => el.name === property_types.DOCUMENT,
          )[0];
        } else if (parentItem.name.toLowerCase().indexOf('datum') > -1) {
          parentItem.type = propertyTypes.filter(
            (el: IPropertyType) => el.name === property_types.DATE,
          )[0];
        } else {
          parentItem.type = propertyTypes.filter(
            (el: IPropertyType) => el.name === property_types.STRING,
          )[0];
        }
      }
    }

    if (template && (parentIndex || parentIndex === 0) && parentItem) {
      const newTemplate = _.cloneDeep(template);
      const previousItem = template.template_template_items[parentIndex];
      const previousItemJSON = JSON.stringify(previousItem);
      const newItem = previousItem;
      newItem.template_item = parentItem;

      // if(!newTemplate.template_template_items) newTemplate.template_template_items = [];

      newTemplate.template_template_items[parentIndex] = newItem;

      setTemplateState(prevState => ({
        ...prevState,
        template: newTemplate,
      }));
    }
  };

  const deleteItem = () => {
    if (selectionIncluded.length === 1) {
      const selectedItem = { ...selectionIncluded[0] };
      let newItems;

      if (template && templateType === template_types.EXECUTION_LIST) {
        newItems = template.template_template_items.map(
          (item: ITemplateTemplateItem) => {
            if (item.weight_for_execution_list) {
              if (
                item.weight_for_execution_list ===
                selectedItem.weight_for_execution_list
              ) {
                item.template_item.dirty = true;
                item.weight_for_execution_list = 0;
              } else if (
                item.weight_for_execution_list >
                selectedItem.weight_for_execution_list
              ) {
                item.template_item.dirty = true;
                item.weight_for_execution_list -= 1;
              }
            }
            return item;
          },
        );
      } else if (
        template &&
        templateType === template_types.CONSTRUCTION_SHEET
      ) {
        newItems = template.template_template_items.map(
          (item: ITemplateTemplateItem) => {
            if (item.weight_for_construction_sheet) {
              if (
                item.weight_for_construction_sheet ===
                selectedItem.weight_for_construction_sheet
              ) {
                item.template_item.dirty = true;
                item.weight_for_construction_sheet = 0;
              } else if (
                item.weight_for_construction_sheet >
                selectedItem.weight_for_construction_sheet
              ) {
                item.template_item.dirty = true;
                item.weight_for_construction_sheet -= 1;
              }
            }
            return item;
          },
        );
      }

      const currentTemplate = template;
      if (currentTemplate && newItems) {
        const newTemplate: ITemplate = { ...currentTemplate };
        newTemplate.template_template_items = newItems;

        setTemplateState(prevState => ({
          ...prevState,
          template: newTemplate,
        }));
      }
    }
  };

  const deleteItemFromTemplate = (templateItem?: ITemplateTemplateItem) => {
    // delete connection between template item & template
    if (!templateItem) return;

    if (template) {
      const itemToBeDeleted = { ...templateItem };
      const isConstructionSheet =
        itemToBeDeleted.weight_for_construction_sheet > 0;
      const isExecutionList = itemToBeDeleted.weight_for_execution_list > 0;
      const newItems = template.template_template_items
        .filter(
          (item: ITemplateTemplateItem) =>
            item.template_item.id !== itemToBeDeleted.template_item.id,
        )
        .map((inputItem: ITemplateTemplateItem) => {
          const item = { ...inputItem };
          if (isConstructionSheet) {
            if (
              item.weight_for_construction_sheet >
              itemToBeDeleted.weight_for_construction_sheet
            ) {
              item.weight_for_construction_sheet -= 1;
            }
          }

          if (isExecutionList) {
            if (
              item.weight_for_execution_list >
              itemToBeDeleted.weight_for_execution_list
            ) {
              item.weight_for_execution_list -= 1;
            }
          }

          return item;
        });

      const currentTemplate = { ...template };
      if (currentTemplate && newItems) {
        const newTemplate: ITemplate = { ...currentTemplate };
        newTemplate.template_template_items = newItems;

        setTemplateState(prevState => ({
          ...prevState,
          template: newTemplate,
        }));
      }

      if (!itemToBeDeleted.template_item.draft) {
        deleteTemplateItemFromTemplate({
          variables: {
            template_id: template.id,
            id: itemToBeDeleted.template_item.id,
          },
        });
      }

      toggleConfirmationDialog();
    }
  };

  const doAddExistingItem = (sourceItem: ITemplateItem) => {
    const item = _.cloneDeep(sourceItem);
    if (item) {
      item.draft = true;
      item.existing = true;
      item.dirty = false;

      let weight_for_execution_list = 0;
      let weight_for_construction_sheet = 0;

      if (templateType === template_types.EXECUTION_LIST) {
        weight_for_execution_list =
          templateState.newItemPositionExecution === -1
            ? getNextWeight(template_types.EXECUTION_LIST)
            : templateState.newItemPositionExecution;
      } else {
        weight_for_construction_sheet =
          templateState.newItemPositionConstruction === -1
            ? getNextWeight(template_types.CONSTRUCTION_SHEET)
            : templateState.newItemPositionConstruction;
      }

      const itemToAdd: ITemplateTemplateItem | undefined = template
        ? {
            weight_for_construction_sheet,
            weight_for_execution_list,
            template,
            template_item: item,
          }
        : undefined;

      let newItems: ITemplateTemplateItem[] =
        template && template.template_template_items
          ? [...template.template_template_items]
          : [];
      if (itemToAdd) {
        newItems.push(itemToAdd);
      }

      newItems = itemToAdd
        ? reorderItems(
            newItems,
            itemToAdd,
            templateState.newItemPositionExecution,
            templateState.newItemPositionConstruction,
          )
        : newItems;

      const currentTemplate: ITemplate | undefined = template
        ? { ...template }
        : undefined;
      if (currentTemplate && newItems) {
        const newTemplate = { ...currentTemplate };
        newTemplate.template_template_items = newItems;

        setTemplateState(prevState => ({
          ...prevState,
          template: newTemplate,
        }));
      }
    }
    toggleModal();
  };

  const filterTypeOptions = () => {
    const data: IComboBoxOption[] =
      convertPropertyListTypeToComboBoxOptions(listTypes);
    const allOption: IComboBoxOption = {
      key: 0,
      text: 'Toon alles',
    };
    return [allOption].concat(data);
  };

  // This is to add a child to parent.
  // When there is no id, it does not know where to add it to!!!
  const getNextId = () => {
    if (template && template.template_template_items) {
      const parentIds = template.template_template_items.map(
        (el: ITemplateTemplateItem) => el.template_item.id,
      );
      const childIds = template.template_template_items
        .filter(
          (el: ITemplateTemplateItem) =>
            el.template_item.children && el.template_item.children.length > 0,
        )
        .map((parent: ITemplateTemplateItem) =>
          (parent.template_item.children
            ? parent.template_item.children?.map(
                (child: ITemplateItem) => child.id,
              )
            : []))
        .flat();
      return Math.max(0, ...parentIds, ...childIds) + 1;
    }

    return 1;
  };

  const getNextWeight = (templateType: string) => {
    if (
      template &&
      template.template_template_items &&
      template.template_template_items.length > 0
    ) {
      // select next weight (at back of the list)
      return (
        Math.max(
          0,
          ...template.template_template_items.map(
            (item: ITemplateTemplateItem) =>
              (templateType === template_types.EXECUTION_LIST
                ? item.weight_for_execution_list
                : item.weight_for_construction_sheet),
          ),
        ) + 1
      );
    }
    return 1;
  };

  const gotoOverview = () => {
    navigate('/templates/');
  };

  const modifyItem = () => {
    setTemplateState(prevState => ({
        ...prevState,
        modalModify: true,
      }));
  };

  // direction: +1 to go down,
  // direction: -1 to go up
  const moveItem = (direction: number) => {
    if (selectionIncluded && selectionIncluded.length === 1 && template) {
      const currentItem = selectionIncluded[0];
      let nextItem: ITemplateTemplateItem;

      if (templateType === template_types.EXECUTION_LIST) {
        nextItem = template.template_template_items.filter(
          (item: ITemplateTemplateItem) =>
            item.weight_for_execution_list ===
            currentItem.weight_for_execution_list + direction,
        )[0];
        currentItem.weight_for_execution_list += direction;
        nextItem.weight_for_execution_list! -= direction;
      } else if (templateType === template_types.CONSTRUCTION_SHEET) {
        nextItem = template.template_template_items.filter(
          (item: ITemplateTemplateItem) =>
            item.weight_for_construction_sheet ===
            currentItem.weight_for_construction_sheet + direction,
        )[0];
        currentItem.weight_for_construction_sheet += direction;
        nextItem.weight_for_construction_sheet! -= direction;
      }

      _selectionIncluded.setItems(_selectionIncluded.getSelection(), true);

      const newItems = template.template_template_items.map(
        (item: ITemplateTemplateItem) => {
          if (item.template_item.id === currentItem.template_item.id) {
            return currentItem;
          }
          if (item.template_item.id === nextItem.template_item.id) {
            return nextItem;
          }
          return item;
        },
      );

      const currentTemplate = template;
      const newTemplate: ITemplate = { ...currentTemplate };
      newTemplate.template_template_items = newItems;
      setTemplateState(prevState => ({
        ...prevState,
        template: newTemplate,
      }));
    }
  };

  const moveItemDown = () => {
    moveItem(1);
  };

  const moveItemUp = () => {
    moveItem(-1);
  };

  const onListTypeChange = (
    event: FormEvent<IComboBox>,
    option?: IComboBoxOption,
    // index?: number,
    // value?: string,
  ) => {
    if (option) {
      setTemplateState(prevState => ({
        ...prevState,
        selectedListType: +option.key,
      }));
    }
  };

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue !== undefined ? newValue.toLowerCase() : '';
    setTemplateState(prevState => ({
      ...prevState,
      searchText: filterString,
    }));
  };

  const openDialog = () => {
    toggleConfirmationDialog();
  };

  const reAddItem = (item: ITemplateTemplateItem) => {
    if (template) {
      if (!item.template_item.draft) {
        item.template_item.dirty = true;
      }

      item.weight_for_execution_list = 0;
      item.weight_for_construction_sheet = 0;

      if (templateType === template_types.EXECUTION_LIST) {
        item.weight_for_execution_list =
          templateState.newItemPositionExecution === -1
            ? getNextWeight(template_types.EXECUTION_LIST)
            : templateState.newItemPositionExecution;
      } else {
        item.weight_for_construction_sheet =
          templateState.newItemPositionConstruction === -1
            ? getNextWeight(template_types.CONSTRUCTION_SHEET)
            : templateState.newItemPositionConstruction;
      }

      let newItems = template.template_template_items.map(
        (el: ITemplateTemplateItem) => {
          if (el.template_item.id === item.template_item.id) {
            return item;
          }
          return el;
        },
      );

      newItems = reorderItems(
        newItems,
        item,
        templateState.newItemPositionExecution,
        templateState.newItemPositionConstruction,
      );

      const currentTemplate = { ...template };
      const newTemplate: ITemplate = { ...currentTemplate };
      newTemplate.template_template_items = newItems;

      setTemplateState(prevState => ({
        ...prevState,
        template: newTemplate,
      }));
      _selectionExcluded.setAllSelected(false);
    }
  };

  const reorderItems = (
    currentItemsSource: ITemplateTemplateItem[],
    newItem: ITemplateTemplateItem,
    newExecutionPosition?: number,
    newConstructionPosition?: number,
  ): ITemplateTemplateItem[] => {
    const currentItems = _.cloneDeep(currentItemsSource);
    const newWeightForConstruction =
      newConstructionPosition && newConstructionPosition > -1
        ? newConstructionPosition
        : newItem.weight_for_construction_sheet;
    const newWeightForExecution =
      newExecutionPosition && newExecutionPosition > -1
        ? newExecutionPosition
        : newItem.weight_for_execution_list;
    if (currentItems && currentItems.length > 0) {
      // insert for executionlist
      for (let i = 0; i < currentItems.length; i++) {
        const currentItem = currentItems[i];
        // let nextItem = currentItems[i + 1];
        if (
          currentItem.weight_for_execution_list !== -1 &&
          currentItem.weight_for_execution_list >= newWeightForExecution &&
          currentItem.template_item.id !== newItem.template_item.id
        ) {
          currentItem.weight_for_execution_list += 1;

          currentItems[i] = currentItem;
        }
      }

      if (templateState.templateType === template_types.EXECUTION_LIST) {
        currentItems.sort(
          (a, b) => a.weight_for_execution_list - b.weight_for_execution_list,
        );
      }

      // insert for constructionSheet
      for (let i = 0; i < currentItems.length; i++) {
        const currentItem = currentItems[i];
        // let nextItem = currentItems[i + 1];
        if (
          currentItem.weight_for_construction_sheet !== -1 &&
          currentItem.weight_for_construction_sheet >=
            newWeightForConstruction &&
          currentItem.template_item.id !== newItem.template_item.id
        ) {
          currentItem.weight_for_construction_sheet += 1;

          currentItems[i] = currentItem;
        }
      }

      if (templateState.templateType === template_types.CONSTRUCTION_SHEET) {
        currentItems.sort(
          (a, b) =>
            a.weight_for_construction_sheet - b.weight_for_construction_sheet,
        );
      }

      for (let i = 0; i < currentItems.length; i++) {
        // add dirty to all items to save new order to DB
        currentItems[i].template_item.dirty = true;
      }

      return currentItems;
    }
    return [];
  };

  const saveModal = () => {
    if (selectedItem && selectedItem.id) {
      // update
      const newItem = _.cloneDeep(selectedItem);
      newItem.dirty = true;
      const currentTemplate = template;
      const newTemplate: ITemplate | undefined = _.cloneDeep(currentTemplate);
      const currentItems = newTemplate
        ? newTemplate.template_template_items
        : undefined;
      if (currentItems) {
        for (let i = 0; i < currentItems.length; i++) {
          const existingItem = currentItems[i];
          if (existingItem && existingItem.template_item.id === newItem.id) {
            currentItems[i].template_item = newItem;

            if (
              !!addSelectionToConstructionSheet &&
              currentItems[i].weight_for_construction_sheet < 1
            ) {
              currentItems[i].weight_for_construction_sheet = getNextWeight(
                template_types.CONSTRUCTION_SHEET,
              );
            } else if (!addSelectionToConstructionSheet) {
              currentItems[i].weight_for_construction_sheet = -1;
            }

            if (
              !!addSelectionToExecutionList &&
              currentItems[i].weight_for_execution_list < 1
            ) {
              currentItems[i].weight_for_execution_list = getNextWeight(
                template_types.EXECUTION_LIST,
              );
            } else if (!addSelectionToExecutionList) {
              currentItems[i].weight_for_execution_list = -1;
            }
          }
        }
      }

      if (newTemplate && currentItems) {
        newTemplate.template_template_items = currentItems;
        _selectionIncluded.setItems(_selectionIncluded.getSelection(), true);
        setTemplateState(prevState => ({
          ...prevState,
          template: newTemplate,
          selectedItem: undefined,
          selectedItemParent: undefined,
          modalModify: false,
          newItemPositionExecution: -1,
          newItemPositionConstruction: -1,
        }));
      }
    } else if (selectedItem) {
      // add new
      if (template) {
        const newItem = _.cloneDeep(selectedItem);

        if (!newItem.id) {
          newItem.id = moment().unix();
        }
        newItem.draft = true;
        newItem.dirty = true;

        let weight_for_construction_sheet = -1;
        let weight_for_execution_list = -1;

        if (addSelectionToExecutionList) {
          weight_for_execution_list =
            templateState.newItemPositionExecution === -1
              ? getNextWeight(template_types.EXECUTION_LIST)
              : templateState.newItemPositionExecution;
        }

        if (addSelectionToConstructionSheet) {
          weight_for_construction_sheet =
            addSelectionToConstructionSheet &&
            templateState.newItemPositionConstruction === -1
              ? getNextWeight(template_types.CONSTRUCTION_SHEET)
              : templateState.newItemPositionConstruction;
        }

        let newItems = _.cloneDeep(template.template_template_items);
        const newTemplateTemplateItem: ITemplateTemplateItem = {
          template: { id: template.id, name: template.name },
          template_item: newItem,
          weight_for_execution_list,
          weight_for_construction_sheet,
        };

        newItems.push(newTemplateTemplateItem);

        if (
          templateState.newItemPositionExecution > -1 ||
          templateState.newItemPositionConstruction > -1
        ) {
          newItems = reorderItems(
            newItems,
            newTemplateTemplateItem,
            templateState.newItemPositionExecution,
            templateState.newItemPositionConstruction,
          );
        }

        const currentTemplate = template;
        const newTemplate: ITemplate = _.cloneDeep(currentTemplate);
        newTemplate.template_template_items = newItems;
        _selectionIncluded.setItems(_selectionIncluded.getSelection(), true);
        setTemplateState(prevState => ({
          ...prevState,
          template: newTemplate,
          selectedItem: undefined,
          selectedItemParent: undefined,
          modalModify: false,
          newItemPositionConstruction: -1,
          newItemPositionExecution: -1,
        }));
      }
    } else {
      _selectionIncluded.setItems(_selectionIncluded.getSelection(), true);
      setTemplateState(prevState => ({
        ...prevState,
        selectedItem: undefined,
        selectedItemParent: undefined,
        modalModify: false,
        newItemPositionConstruction: -1,
        newItemPositionExecution: -1,
      }));
    }
  };

  const toggleConfirmationDialog = () => {
    setTemplateState(prevState => ({
      ...prevState,
      confirmationHidden: !templateState.confirmationHidden,
    }));
  };

  const toggleModal = () => {
    setTemplateState(prevState => ({
      ...prevState,
      modalOpen: !templateState.modalOpen,
    }));
  };

  const toggleModifyModal = (clearItem?: boolean) => {
    if (clearItem) {
      setTemplateState(prevState => ({
        ...prevState,
        modalModify: !templateState.modalModify,
        selectedItem: undefined,
        selectedItemParent: undefined,
      }));
    } else {
      setTemplateState(prevState => ({
        ...prevState,
        modalModify: !templateState.modalModify,
      }));
    }
  };

  const updatePropertiesOnlyIncluded = (items: ITemplateTemplateItem[]) => {
    if (template) {
      const newTemplate = { ...template };
      const notIncludedItems = template.template_template_items.filter(
        (item: ITemplateTemplateItem) =>
          (templateType === template_types.EXECUTION_LIST
            ? item.weight_for_execution_list === 0
            : item.weight_for_construction_sheet === 0),
      );
      newTemplate.template_template_items = [...items, ...notIncludedItems];

      setTemplateState(prevState => ({
        ...prevState,
        template: newTemplate,
      }));
    }
  };

  // Rendering
  if (!isAuthenticated) return <p>Verboden</p>;
  // if (loading || loadingListTypes) return <p>Laden...</p>;
  // if (error || errorListTypes) return <p>Fout :(</p>;

  const propertyTypeParent = propertyTypes.filter(
    (el: IPropertyType) => el.name === property_types.PARENT,
  )[0];
  const tapPoints = listTypes.filter(
    (el: IPropertyListType) => el.name === 'Tappunten',
  )[0];

  const getOriginalPropertyType = () => {
    if (
      selectedItem &&
      selectedItem.type &&
      selectedItem.type.id === propertyTypeParent.id
    ) {
      if (selectedItem?.document) {
        return propertyTypes.filter(
          (el: IPropertyType) => el.name === property_types.DOCUMENT,
        )[0];
      }
      if (selectedItem?.suggested_value_category_id) {
        return propertyTypes.filter(
          (el: IPropertyType) => el.name === property_types.SUGGESTED_VALUE,
        )[0];
      }
      if (
        selectedItem?.name &&
        selectedItem.name.toLowerCase().indexOf('datum') > -1
      ) {
        return propertyTypes.filter(
          (el: IPropertyType) => el.name === property_types.DATE,
        )[0];
      }
      return propertyTypes.filter(
        (el: IPropertyType) => el.name === property_types.STRING,
      )[0];
    }
    return undefined;
  };

  const validateTemplateItem = (item: ITemplateItem) => {
    let valid = true;
    if (item) {
      if (!item.name || item.name.length < 1) valid = false;
      if (!item.type) valid = false;
      else {
        switch (item.type.id) {
          case 14: // multi value
            if (!item.nested_type) valid = false;
            break;
          case 7: // document
            break;
          case 8: // parent
            break;
          case 9: // string
            break;
          case 10: // suggested-value
            break;
          case 12: // date
            break;
          case 13: // note
            if (!item.value || item.value.length < 1) valid = false;
            break;
          default:
            break;
        }
      }
    }

    return valid;
  };

  const validateChildren = (items: ITemplateItem[] | undefined) => {
    let valid = true;

    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (!validateTemplateItem(items[i])) valid = false;
      }
    }

    return valid;
  };

  const isValidForm = () => {
    const currentItem = selectedItem;
    let valid = true;
    if (currentItem) {
      if (!validateTemplateItem(currentItem)) valid = false;
      if (currentItem.children && currentItem.children.length > 0) {
        if (!validateChildren(currentItem.children)) valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  };

  const dialogRemoveParameterConfirmationProps = {
    type: DialogType.largeHeader,
    title: 'Verwijder sjabloon',
    subText: `Ben je zeker dat je de parameter ${
      templateState.selectedItemToBeDeleted
        ? templateState.selectedItemToBeDeleted?.template_item.name
        : 'undefined'
    } wil verijderen? Deze actie kan niet ongedaan worden!`,
  };

  return (
    <div>
      <Stack tokens={stackTokens10}>
        <TextField
          label='Naam sjabloon'
          value={template && template.name ? template.name : ''}
          placeholder='Voer de naam voor het sjabloon in'
          onChange={(
            event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
          ) => {
            const newName = newValue || '';

            const newTemplate: ITemplate = template
              ? { ...template }
              : { name: '', template_template_items: [] };
            newTemplate.name = newName;

            setTemplateState(prevState => ({
              ...prevState,
              template: newTemplate,
              hasName: !!newValue,
            }));
          }}
          errorMessage={hasName ? '' : 'Naam moet zijn ingevuld!'}
          required
        />

        <TemplateCommandBar
          addItem={() => toggleModifyModal(true)}
          addExistingItem={addExistingItem}
          callOutContinue={gotoOverview}
          callOutCondition={containsChanges}
          deleteDisabled={selectionIncluded.length < 1}
          deleteItem={deleteItem}
          modifyDisabled={selectionIncluded.length < 1}
          modifyItem={modifyItem}
          moveDownDisabled={cannotMoveDown}
          moveItemDown={moveItemDown}
          moveItemUp={moveItemUp}
          moveUpDisbaled={cannotMoveUp}
          saveTemplate={saveTemplate}
        />

        <Stack tokens={stackTokens10} horizontal>
          <Label>Uitvoeringslijst</Label>
          <Toggle label='' onText='' offText='' onChange={changeToggle} />
          <Label>Werffiche</Label>
        </Stack>

        <Stack horizontal tokens={stackTokens5}>
          <TextField
            label='Filter op omschrijving:'
            value={searchText}
            onChange={onSearchValueChange}
            styles={overviewControlStyles}
          />
          <ComboBox
            label='Filter op lijst types:'
            selectedKey={selectedListType}
            options={filterTypeOptions()}
            onChange={onListTypeChange}
          />
        </Stack>

        <TemplateDetailsList
          dragAndDropEnabled
          items={
            template && template.template_template_items
              ? template.template_template_items
              : []
          }
          listTypes={listTypes}
          searchText={searchText}
          selectedListType={selectedListType}
          selection={_selectionIncluded}
          templateType={templateType}
          updateProperties={updatePropertiesOnlyIncluded}
          editItem={modifyItem}
        />

        <Label>
          Beschikbare items uit de{' '}
          {templateType === template_types.EXECUTION_LIST
            ? 'werffiche'
            : 'uitvoeringslijst'}
          :
        </Label>

        <TemplateDetailsList
          items={
            template && template.template_template_items
              ? template.template_template_items
              : []
          }
          selection={_selectionExcluded}
          templateType={
            templateType === template_types.EXECUTION_LIST
              ? template_types.CONSTRUCTION_SHEET
              : template_types.EXECUTION_LIST
          }
          isIncluded={false}
          reAddItem={reAddItem}
          stageItemDelete={(item: ITemplateTemplateItem) => {
            openDialog();
            setTemplateState(prevState => ({
              ...prevState,
              selectedItemToBeDeleted: item,
            }));
          }}
        />
      </Stack>

      <Panel
        isLightDismiss
        isOpen={modalModify}
        onDismiss={clearModal}
        closeButtonAriaLabel='Close'
        headerText='Item toevoegen of verwijderen'
        type={PanelType.custom}
        customWidth='500px'
      >
        <div>
          <div className={modalContentStyles.header}>
            <span>
              {selectedItem && selectedItem.id > 0
                ? 'Wijzig item'
                : 'Voeg item toe'}
            </span>
          </div>
          <div className={modalContentStyles.body}>
            {/* CheckBoxes */}

            <Stack horizontal tokens={stackTokens10} disableShrink>
              <Stack.Item>
                <Checkbox
                  label='Uitvoeringslijst'
                  checked={!!addSelectionToExecutionList}
                  onChange={() =>
                    setAddSelectionToExecutionList(!addSelectionToExecutionList)
                  }
                />
              </Stack.Item>
              <Stack.Item>
                <Checkbox
                  label='Werffiche'
                  checked={!!addSelectionToConstructionSheet}
                  onChange={() =>
                    setAddSelectiontoConstructionSheet(
                      !addSelectionToConstructionSheet,
                    )
                  }
                />
              </Stack.Item>
            </Stack>

            <TemplateAddModifyItem
              types={propertyTypes}
              originalType={getOriginalPropertyType()}
              selectedItem={_.cloneDeep(selectedItem)}
              // selectedItemParent={_.cloneDeep(selectedItemParent)}
              // callBackItem={callBackItem}
              suggestedValuecategories={suggestedValueCategories}
              listTypes={listTypes}
              templateID={
                templateState && templateState.template
                  ? templateState.template.id
                  : undefined
              }
              tappoints={tapPoints}
              parentId={selectedItemParent ? selectedItemParent.id : 0}
              // hasNewItemName={hasNewItemName}
              setCurrentItem={item => {
                const dirtyItem = item;
                dirtyItem.dirty = true;
                setTemplateState(prevState => ({
                  ...prevState,
                  selectedItem: dirtyItem,
                }));
              }}
              validateTemplateItem={validateTemplateItem}
            />
          </div>
          <div className={modalContentStyles.footer}>
            <Stack horizontal reversed tokens={stackTokens5}>
              <DefaultButton onClick={clearModal} text='Annuleren' />
              <PrimaryButton
                onClick={saveModal}
                disabled={
                  !(
                    addSelectionToConstructionSheet ||
                    addSelectionToExecutionList
                  ) || !isValidForm()
                }
                text={
                  selectedItem && selectedItem.id > 0 ? 'Wijzigen' : 'Toevoegen'
                }
              />
            </Stack>
          </div>
        </div>
      </Panel>

      <Dialog
        hidden={confirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveParameterConfirmationProps}
        modalProps={modelProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() =>
              deleteItemFromTemplate(templateState.selectedItemToBeDeleted)
            }
            text='Verwijderen'
          />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>

      <Modal
        isOpen={modalOpen}
        onDismiss={toggleModal}
        containerClassName={modalContentStyles.container}
        isBlocking={false}
      >
        {/* {calledTemplateItems && loadingTemplateItems && (
          <Stack styles={{ root: { marginTop: "75px" } }}>
            <SpinnerDefault />
          </Stack>
        )} */}

        {/* {
                     calledTemplateItems && !loadingTemplateItems && <TemplateAddExistingItem
                        addItem={doAddExistingItem}
                        addItems={doAddExistingItems}
                        includedItems={template && template.template_template_items ? template.template_template_items : []}
                        templateItems={filterItems(dataTemplateItems.findManyTemplateItems)}
                        toggleModal={toggleModal}
                        forModal={true} />
                 } */}

        <TemplateAddExistingItem
          addItem={doAddExistingItem}
          template={template}
          forModal
          toggleModal={toggleModal}
        />
      </Modal>
    </div>
  );
};

export default TemplateOverview;
