import {
 DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import React, { lazy, useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { IConstructionSite } from '../../../utils';

interface props {
  items: IConstructionSite[],
  selection?: ISelection<IObjectWithKey> | undefined,
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
}

const ConstructionSitesDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  //   updateItems,
  isSortedAsc = false,
  sortedField,
  setSorting, //    = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
}: props) => {
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      if (column.fieldName === sortedColumn[0].fieldName) {
        const newColumns = columns.map((col: IColumn) => {
          if (col.fieldName === column.fieldName) {
            col.isSortedDescending = !col.isSortedDescending;

            fieldName = column.fieldName;
            sortDescending = col.isSortedDescending;
          }
          return col;
        });
        setColumns(newColumns);
      } else {
        const newColumns = columns.map((col: IColumn) => {
          if (col.fieldName === column.fieldName) {
            col.isSorted = true;
            col.isSortedDescending = false;

            fieldName = column.fieldName;
            sortDescending = col.isSortedDescending;
          } else {
            col.isSorted = false;
          }
          return col;
        });
        setColumns(newColumns);
      }

      setSorting(!sortDescending, fieldName);
    }
  };

  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 210,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van A tot Z',
      sortDescendingAriaLabel: 'Sorteer van Z tot A',
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.name}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Adres',
      fieldName: 'address',
      minWidth: 180,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van A tot Z',
      sortDescendingAriaLabel: 'Sorteer van Z tot A',
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.address}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Postcode',
      fieldName: 'zip_code',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
      sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.zip_code}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Stad',
      fieldName: 'city',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van A tot Z',
      sortDescendingAriaLabel: 'Sorteer van Z tot A',
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.city}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5x',
      name: 'Status',
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item?.status?.label ? item.status.label : ''}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Werfleider',
      fieldName: 'project_manager',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.project_manager && (<>{item.project_manager.first_name} {item.project_manager.last_name}</>)}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Verkoper',
      fieldName: 'sales_rep',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van oud tot nieuw',
      sortDescendingAriaLabel: 'Sorteer van nieuw tot oud',
      onColumnClick,
      onRender: (item: IConstructionSite) => <span>{item.sales_rep && (<>{item.sales_rep.first_name} {item.sales_rep.last_name}</>)}</span>,
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
    if (column.fieldName === sortedField) {
      column.isSorted = true;
      column.isSortedDescending = !isSortedAsc;
      return column;
    }
    return column;
  });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default ConstructionSitesDetailsList;
