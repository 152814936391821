import { useMutation, useQuery } from '@apollo/client';
import {
  Panel,
  PanelType,
  Stack,
  TextField,
  Checkbox,
  CommandBar,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DetailsList,
  SelectionMode,
  Label,
  StackItem,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import _ from 'lodash';
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Accordion, { AccordionItem } from '../../components/parts/Accordion';
import SaveButtonWithPermissions from '../../components/parts/SaveButtonWithPermissions';
import { commandBarTheme } from '../../theme';
import {
  DEFAULT_TOAST_POSITION,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_ERROR_MESSAGE,
  modalContentStyles,
} from '../../utils';
import {
  ICostCenterWings,
  ADD_COST_CENTER_WINGS,
  UPDATE_COST_CENTER_WINGS,
  DELETE_COST_CENTER_WINGS,
  GET_COST_CENTER_WINGS_BY_ID,
} from '../../utils/ConstCenterWings';
import { ITaxCode } from '../../utils/TaxCode';
import { toastSuccess, toastError } from '../../utils/toast';
import CostCenterWingsDetail from '../cost-center/components/CostCenterWingsDetail';

interface Props {
  isOpen: any;
  dismissPanel: any;
  saveTaxCode: any;
  taxCode: ITaxCode | undefined;
  setTaxCode: any;
  refetch: any;
}

const CostCenterDetail = ({
  isOpen,
  dismissPanel,
  saveTaxCode,
  taxCode,
  setTaxCode,
  refetch,
}: Props) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setTaxCode((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        taxCode && taxCode.id ? 'BTW code wijzigen' : 'BTW code toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <p>ID: {taxCode?.id}</p>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='label'
                label='Naam'
                value={taxCode && taxCode.label ? taxCode.label : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  taxCode && !taxCode.label ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='rate'
                label='Percentage'
                type='number'
                value={taxCode?.rate as any}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  taxCode &&
                  (taxCode.rate === null ||
                    taxCode.rate === undefined ||
                    taxCode.rate < 0)
                    ? 'Dit veld is verplicht'
                    : ''
                }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='code'
                label='Wings Code'
                value={taxCode && taxCode.code ? taxCode.code : ''}
                onChange={onChangeTextFieldValue}
                // required
                // errorMessage={
                //   taxCode && !taxCode.code ? 'Dit veld is verplicht' : ''
                // }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='description'
                label='Beschrijving'
                value={
                  taxCode && taxCode.description ? taxCode.description : ''
                }
                onChange={onChangeTextFieldValue}
                multiline
                rows={7}
              />
            </Stack>

            {/* <Stack style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                name='active'
                label='Actief?'
                checked={taxCode && taxCode.active}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    active:
                      costCenter && costCenter.active
                        ? !costCenter.active
                        : true,
                  }));
                }}
              />
            </Stack> */}

            {/* <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                name='customer_specific'
                label='Werfpost?'
                checked={taxCode && taxCode.customer_specific}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    customer_specific:
                      costCenter && costCenter.customer_specific
                        ? !costCenter.customer_specific
                        : true,
                  }));
                }}
              />
            </Stack> */}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={!taxCode || (taxCode && !taxCode.label)}
              save={saveTaxCode}
              permission='write:costCenters' // boekhouding is allowed to write costCenters so should also be allowed to change taxcodes...
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CostCenterDetail;
