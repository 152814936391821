/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { displayNameLead } from '../../../utils/Lead';
import {
  convertConstructionSitesToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  displayNameEmployee,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  IEmployee,
} from '../../../utils';
import { GET_SUGGESTED_VALUE } from '../../../utils/SuggestedValue';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import { useAppDispatch } from '../../../redux/hooks';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
 Filter, FilterField, FilterFieldState, FilterPanel, FilterSection, FilterState, getParsedValue, initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import { convertCostCentersToComboBoxOptions, GET_COST_CENTERS } from '../../../utils/CostCenter';
import useDebounce from '../../../components/hooks/useDebounce';

const TimesheetStatus = (suggestedValue: any) => {
  const id = +suggestedValue.substr(suggestedValue.length - 4);
  const { data } = useQuery(GET_SUGGESTED_VALUE, {
    variables: {
      where: {
        id,
      },
    },
  });

  if (data) {
    return <span>{data.findOneSuggestedValue.suggested_value}</span>;
  }
  return <span />;
};

const TimesheetsOverview = ({
  timesheets,
  loading,
  initialLoad,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  setAdvancedFilter,
  downloadFile,
}: any) => {
  // Sorting
  const dispatch = useAppDispatch();
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  const [employees, setEmployees] = useState([]);
  const [costCenter, setCostCenter] = useState([]);

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { loading: projectsLoading, data: projectsData } =
  useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      orderBy: {
        name: 'asc',
      },
      filter:
      debouncedFilter ? {
          name: {
            contains: debouncedFilter,
          },
        } : undefined,
      take: 15,
    },
  });

  // Columns
  const columnsList = [
    {
      key: 'column1',
      name: 'Datum',
      fieldName: 'entry_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => (
        <span>
          {timesheet.entry_date &&
            moment(timesheet.entry_date).format('DD-MM-YYYY')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
      isSorted: true,
      isSortedDescending: true,
      sortAscendingAriaLabel: 'Sorteer van laag tot hoog',
      sortDescendingAriaLabel: 'Sorteer van hoog tot laag',
    },
    {
      key: 'column2',
      name: 'Uren',
      fieldName: 'hours',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => timesheet.hours,
      data: 'string',
      isPadded: true,
      onColumnClick,
    },
    {
      key: 'column3',
      name: 'Medewerker',
      fieldName: 'employee',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
      timesheet.employee && displayNameEmployee(timesheet.employee),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Status',
      fieldName: 'status',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
        timesheet.status && TimesheetStatus(timesheet.status),
      data: 'string',
      isPadded: true,
      onColumnClick,
    },
    {
      key: 'column5',
      name: 'Klant',
      fieldName: 'customer',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) =>
        (timesheet.construction_site ? timesheet.construction_site.name : displayNameLead(timesheet.lead)),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Post',
      fieldName: 'cost_center',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      onRender: (timesheet: ITimesheetEntry) => (
        <span>
          {timesheet && timesheet.cost_center && timesheet.cost_center.name}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const filter_schema: Filter = {
    id: 'timesheets_filter_schema_rev3',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_0',
        label: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'section_0_0',
            name: 'Startdatum',
            fieldName: 'entry_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            initialValue: moment().startOf('week').format('YYYY-MM-DD'),
            callback: (fieldValue: string) => ({
              gte: moment(fieldValue).startOf('day').toDate(),
            }),
          },
          {
            id: 'section_0_1',
            name: 'Einddatum',
            fieldName: 'entry_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            callback: (fieldValue: string) => ({
              lte: moment(fieldValue).startOf('day').toDate(),
            }),
          },
          {
            id: 'section_0_2',
            name: 'Status',
            fieldName: 'status',
            data: 'string',
            type: 'suggested',
            prefix: 'tse_stat_',
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return `tse_stat_${fieldValue}`;
              }
            },
          },
          {
            id: 'section_0_3',
            name: 'Medewerker',
            fieldName: 'employee',
            data: 'string',
            type: 'list',
            options: convertEmployeesToComboBoxOptions(
              employees,
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: {
                    equals: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_0_4',
            name: 'CostCenter',
            fieldName: 'cost_center',
            data: 'string',
            type: 'list',
            options: convertCostCentersToComboBoxOptions(
              costCenter,
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  id: parseInt(fieldValue, 10),
                };
              }
            },
          },
        ],
      },
      {
        id: 'section_1',
        label: 'Klant',
        width: 48,
        fields: [
          {
            id: 'section_1_1',
            name: 'Achternaam',
            fieldName: 'lead',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              last_name1: { startsWith: fieldValue },
            }),
          },
          {
            id: 'section_1_2',
            name: 'Voornaam',
            fieldName: 'lead',
            data: 'string',
            type: 'text',
            width: 49.5,
            callback: (fieldValue: string) => ({
              first_name1: {
                contains: fieldValue,
              },
            }),
          },
          {
            id: 'section_1_3',
            name: 'Adres',
            fieldName: 'lead',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              address1: { startsWith: fieldValue },
            }),
          },
          {
            id: 'section_1_4',
            name: 'Stad',
            fieldName: 'lead',
            data: 'string',
            type: 'text',
            width: 69.5,
            callback: (fieldValue: string) => ({
              city1: { startsWith: fieldValue },
            }),
          },
          {
            id: 'section_1_5',
            name: 'Post code',
            fieldName: 'lead',
            data: 'number',
            type: 'number',
            width: 29.5,
            callback: (fieldValue: string) => ({
              zip_code1: parseInt(fieldValue, 10),
            }),
          },
          {
            id: 'section_1_8',
            name: 'E-mailadres',
            fieldName: 'lead',
            data: 'string',
            type: 'text',
            callback: (fieldValue: string) => ({
              email1: {
                startsWith: fieldValue,
              },
            }),
          },
        ],
      },
      {
        id: 'section_2',
        label: 'Werf',
        width: 48,
        fields: [
          {
            id: 'section_2_8',
            name: 'Project',
            fieldName: 'construction_site',
            data: 'string',
            type: 'debounceList',
            options: convertConstructionSitesToComboBoxOptions(
              projectsData && projectsData.findManyConstructionSites ? projectsData.findManyConstructionSites : [],
            ),
            setFilter: setConstructionSiteFilter,
            callback: (fieldValue: string) => ({
              id: {
                equals: parseInt(fieldValue, 10),
              },
            }),
          },
        ],
      },
    ],
  };

  useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      if (x && x.findManyEmployees) {
        setEmployees(x.findManyEmployees);
      }
    },
  });

  useQuery(GET_COST_CENTERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
    setCostCenter(x.findManyCostCenters);
    },
  });

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'filter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilter(),
      theme: commandBarTheme,
    },
    {
      key: 'download',
      text: 'Excel',
      iconProps: { iconName: 'Download' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !selectionDetails,
    },
  ];

  document.title = '3bouw | Timesheets';

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  const addInitialValueFieldToState = (
    section: FilterSection,
    field: FilterField,
    value: any,
    prevState: FilterState,
  ) => {
      const newState = _.cloneDeep(prevState);
      const sectionIndex = newState.schema.findIndex(
        item => section.id === item.id,
      );

      if (sectionIndex === -1) {
        const fieldList: FilterFieldState[] = [];
        fieldList.push({
          id: field.id,
          name: field.fieldName,
          value,
          parsedValue: getParsedValue(field, value),
        });

        newState.schema.push({
          id: section.id,
          fields: fieldList,
        });
      }

      if (sectionIndex > -1) {
        const fieldIndex = newState.schema[sectionIndex].fields.findIndex(
          item => item.id === field.id,
        );

        if (fieldIndex === -1) {
          const fieldList: FilterFieldState[] =
            newState.schema[sectionIndex].fields;
          fieldList.push({
            id: field.id,
            name: field.fieldName,
            value,
            parsedValue: getParsedValue(field, value),
          });

          newState.schema[sectionIndex] = {
            ...newState.schema[sectionIndex],
            fields: fieldList,
          };
        }

        if (fieldIndex > -1) {
          const fieldList: FilterFieldState[] =
            newState.schema[sectionIndex].fields;
          fieldList[fieldIndex] = {
            ...newState.schema[sectionIndex].fields[fieldIndex],
            value,
            parsedValue: getParsedValue(field, value),
          };

          newState.schema[sectionIndex] = {
            ...newState.schema[sectionIndex],
            fields: fieldList,
          };
        }
      }

      return newState;
  };

  useEffect(() => {
    // if there would be "initialValue" in one of the filterfields, set the state to that value
    let newState = filterState;
    filter_schema.schema.forEach(section => {
      section.fields.forEach(field => {
        if (field.initialValue) {
          newState = addInitialValueFieldToState(section, field, field.initialValue, newState);
        }
      });
    });

    setFilterState(newState);
    setAdvancedFilter(newState);
  }, []);

  return (
    <>
      {timesheets && (
        <>
          <CommandBarSticky
            items={commandBaritems}
            theme={commandBarTheme}
            maxWidth='2000px'
          />
          <ShimmeredDetailsList
            items={timesheets}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading && initialLoad}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}

      {isFilterPanelOpen && (
        <FilterPanel
          isOpen={isFilterPanelOpen}
          dismissPanel={() => {
            setIsFilterPanelOpen(false);
          }}
          filter={filter_schema} // this is the advanced schema
          setFilter={setAdvancedFilter} // this is the filter in the upper component
          filterState={filterState} // this is the state while editing the filter
          setFilterState={setFilterState} // this is the state while editing the filter
        />
      )}

      {!loading && !showLoadMore && timesheets.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default TimesheetsOverview;
