import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import { IProperty } from './Property';
import { IEmployee } from './Employee';
import { ILead } from './Lead';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ICustomerPaymentTransaction } from './CustomerPaymentTransaction';
import { IInvoiceCustomer } from './ConstructionSiteInvoiceCustomer';
import { IConstructionSiteStatus } from './ConstructionSiteStatus';
import { ICustomerInvoice } from './CustomerInvoice';
import { IArchitect, ISalesRep } from '.';

export const GET_CONSTRUCTION_SITES = gql`
  query getConstructionSites(
    $filter: ConstructionSiteFilterInput
    $take: Int
    $skip: Int
    $orderBy: ConstructionSiteOrderByInput
  ) {
    findManyConstructionSites(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      prisma_total
      status {
        id
        label
      }
      name
      address
      archive_no
      city
      zip_code
      comments
      contract_amount
      architect_id
      lead_id
      lead {
        id
        first_name1
        first_name2
        last_name1
        last_name2
      }
      epb_reporter_id
      manager_id
      project_manager_id
      sales_rep_id
      safety_coordinator_id
      creation_date
      project_manager {
        id
        first_name
        last_name
        mobile
      }
      sales_rep {
        id
        first_name
        last_name
      }
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
      status {
        id
        label
      }
    }
  }
`;

export const GET_CONSTRUCTION_SITE_BY_ID_DETAILS = gql`
  query getConstructionSiteByIdDetails(
    $where: ConstructionSiteWhereInput!
    $whereProps: PropertyWhereInput
  ) {
    findOneConstructionSite(where: $where) {
      id
      status {
        id
        label
      }
      name
      address
      archive_no
      city
      zip_code
      comments
      contract_amount
      degree_of_completion
      execution_list_internal_remarks
      wings_code
      wings_account_code
      type_of_home
      style_of_home
      billboard_comments
      billboard_required
      billboard_status
      k_level
      e_level
      airtightness
      date_of_contract
      date_sold
      date_completed
      sounding_required
      sounding_status
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      architect {
        id
        first_name
        last_name
      }
      sales_rep {
        id
        first_name
        last_name
      }
      epb_reporter {
        id
        first_name
        last_name
      }
      safety_coordinator {
        id
        first_name
        last_name
      }
      site_manager {
        id
        first_name
        last_name
      }
      project_manager {
        id
        first_name
        last_name
      }
      purchase_orders {
        id
        creation_date
        delivery_date
        remarks
        description
        delivery_to_other_supplier
        is_default_contact_person
        no_delivery_hour
        is_on_call
        pick_up
        on_call_value
        is_purchase_order
        purchase_order_no
        contact_person {
          id
          first_name
          last_name
        }
        status {
          id
          label
        }
        supplier {
          id
          name
          address
          phone
          fax
          city
          zip_code
          email
          contact
          contact_persons {
            id
            first_name
            last_name
            email
            notes
            mobile
            phone
          }
        }
        deliver_to_supplier {
          id
          name
          address
          phone
          fax
          city
          zip_code
        }
        construction_site {
          id
          name
          address
          city
          zip_code
          comments
          project_manager {
            id
            first_name
            last_name
            mobile
          }
          site_manager {
            id
            first_name
            last_name
            mobile
          }
        }
        line_items {
          id
          name
          description
          sku
          unit_price
          comment
          quantity
          weight
          measuring_unit {
            id
            name
          }
        }
      }
      payment_transactions {
        customer {
          first_name1
          last_name1
          title1
          title2
          first_name2
          last_name2
        }
        description
        payment_date
        customer_id
        percentage
        id
        customer_invoices {
          id
          invoice_date
          invoice_due_date
          invoice_no
          invoice_paid
          sent
        }
        construction_site {
          id
          name
          address
          city
          zip_code
          contract_amount
          wings_code
          wings_account_code
          customer_contract_updates {
            id
            document_no
            document_date
            status
            summary
            split_payment
            customer_invoice_split_customer_contract_updates {
              id
              customer_invoice_id
              percentage
              description
              customer_invoice {
                id
                credit_note
                customer_id
                intro
                invoice_date
                invoice_due_date
                invoice_no
                invoice_paid
                outro
                version
                customer_invoice_line_items {
                  comments
                  customer_invoice_id
                  description
                  id
                  percentage_vat
                  show_only_total
                  units
                  unit_price
                  tax_code {
                    id
                    label
                    rate
                    description
                  }
                }
                customer_invoice_payments {
                  amount
                  comments
                  customer_invoice_id
                  id
                  payment_date
                }
              }
            }
            customer_invoice {
              id
              invoice_date
              invoice_due_date
              invoice_no
              invoice_paid
              sent
            }
          }
          invoice_customers {
            id
            percentage
            lead {
              id
              first_name1
              first_name2
              last_name1
              last_name2
              address1
              city1
              zip_code1
            }
          }
          lead {
            id
            first_name1
            first_name2
            last_name1
            last_name2
            address1
            city1
            zip_code1
          }
        }
        customer_invoice_legacy {
          invoice_no
          invoice_paid
          invoice_date
          id
        }
      }
      customer_contract_updates {
        id
        status
        document_no
        summary
        customer_contract_update_line_items {
          percentage_vat
          description
          unit_price
          units
          tax_code {
            id
            rate
            label
            description
          }
        }
      }
      customer_invoices {
        id
        credit_note
        customer_id
        intro
        invoice_date
        invoice_due_date
        invoice_no
        invoice_paid
        outro
        version
        customer_invoice_line_items {
          comments
          customer_invoice_id
          description
          id
          percentage_vat
          show_only_total
          units
          unit_price
          tax_code {
            id
            label
            rate
            description
          }
        }
        customer_invoice_payments {
          amount
          comments
          customer_invoice_id
          id
          payment_date
        }
      }
      properties(where: $whereProps) {
        name
        value
        remote_category
        suggested_value {
          id
          suggested_value
          suggested_value_category_id
          suggested_value_code
        }
        purchase_orders {
          id
          creation_date
          delivery_date
          remarks
          description
          delivery_to_other_supplier
          is_default_contact_person
          no_delivery_hour
          is_on_call
          pick_up
          on_call_value
          is_purchase_order
          purchase_order_no
          contact_person {
            id
            first_name
            last_name
          }
          status {
            id
            label
          }
          supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
            email
            contact
            contact_persons {
              id
              first_name
              last_name
              email
              notes
              mobile
              phone
            }
          }
          deliver_to_supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
          }
          construction_site {
            id
            name
            address
            city
            zip_code
            comments
            project_manager {
              id
              first_name
              last_name
              mobile
            }
            site_manager {
              id
              first_name
              last_name
              mobile
            }
          }
          line_items {
            id
            name
            description
            sku
            unit_price
            comment
            quantity
            weight
            measuring_unit {
              id
              name
            }
          }
          property {
            id
          }
          property_id
        }
        children {
          name
          value
          remote_category
          type {
            name
            label
          }
          suggested_value {
            id
            suggested_value
            suggested_value_category_id
            suggested_value_code
          }
        }
      }
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
    }
  }
`;

export const GET_CONSTRUCTION_SITE_BY_ID_SIMPLE = gql`
  query getConstructionSiteByIdDetails($where: ConstructionSiteWhereInput!) {
    findOneConstructionSite(where: $where) {
      id
      status {
        id
        label
      }
      name
      execution_list_internal_remarks
      address
      archive_no
      city
      zip_code
      comments
      contract_amount
      degree_of_completion
      wings_code
      wings_account_code
      type_of_home
      style_of_home
      billboard_comments
      billboard_required
      billboard_status
      k_level
      e_level
      airtightness
      date_of_contract
      date_sold
      date_completed
      sounding_required
      sounding_status
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      sales_rep {
        id
        first_name
        last_name
      }
    }
  }
`;

export const GET_CONSTRUCTION_SITE_BY_ID_EDIT = gql`
  query getConstructionSiteByIdEdit(
    $where: ConstructionSiteWhereInput!
    $whereProps: PropertyWhereInput
  ) {
    findOneConstructionSite(where: $where) {
      id
      status {
        id
        label
      }
      execution_list_internal_remarks
      name
      address
      archive_no
      city
      zip_code
      wings_code
      wings_account_code
      contract_amount
      epb_reporter_id
      epb_reporter {
        id
        first_name
        last_name
      }
      safety_coordinator_id
      safety_coordinator {
        id
        first_name
        last_name
      }
      comments
      architect_id
      architect {
        id
        first_name
        last_name
      }
      lead_id
      lead {
        address1
        city1
        email1
        email2
        first_name1
        first_name2
        id
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        sales_rep {
          id
          first_name
          last_name
        }
        zip_code1
      }
      manager_id
      site_manager {
        id
        first_name
        last_name
      }
      project_manager_id
      project_manager {
        id
        first_name
        last_name
        mobile
      }
      sales_rep_id
      sales_rep {
        id
        first_name
        last_name
      }
      creation_date
      implementation_list_approve_date
      water_tap_points_approve_date
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
      properties(orderBy: $orderByProps) {
        id
        name
        remote_category
        value
        order_date
        is_ordered
        is_not_applicable
        send_alert
        due_date
        execution_date
        weight_for_construction_sheet
        weight_for_execution_list
        needs_water_points
        marked
        sales_rep {
          id
          first_name
          last_name
        }
        employee {
          id
          first_name
          last_name
        }
        suppliers {
          id
          supplier {
            id
            name
          }
        }
        parent {
          id
        }
        type {
          id
          name
        }
        nested_type {
          id
          name
        }
        list_types {
          id
          list_type {
            id
            name
          }
        }
        document {
          id
          name
          src
          extension
          is_external
          blob_name
        }
        type_children {
          id
          name
          value
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        children {
          id
          name
          value
          needs_water_points
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
            suggested_value_category {
              id
              category_description
            }
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          type_children {
            name
          }
          children {
            id
            name
            value
            parent {
              id
            }
            weight_for_construction_sheet
            weight_for_execution_list
            remote_category
            type {
              id
              name
            }
            suggested_value {
              id
              suggested_value
              suggested_value_category {
                id
                category_description
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        suggested_value {
          id
          suggested_value
          suggested_value_category {
            id
            category_description
          }
        }
        reporting_item {
          id
          document {
            id
          }
          reporting_label
          template_template_items {
            template {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_CONSTRUCTION_SITE_BY_ID = gql`
  query getConstructionSiteById(
    $where: ConstructionSiteWhereInput!
    $orderByProps: PropertyOrderByInput
  ) {
    findOneConstructionSite(where: $where) {
      id
      status {
        id
        label
      }
      name
      address
      archive_no
      city
      zip_code
      wings_code
      wings_account_code
      contract_amount
      epb_reporter_id
      epb_reporter {
        id
        first_name
        last_name
      }
      execution_list_internal_remarks
      safety_coordinator_id
      safety_coordinator {
        id
        first_name
        last_name
      }
      comments
      architect_id
      architect {
        id
        first_name
        last_name
      }
      lead_id
      lead {
        address1
        city1
        email1
        email2
        first_name1
        first_name2
        id
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        sales_rep {
          id
          first_name
          last_name
        }
        zip_code1
      }
      manager_id
      site_manager {
        id
        first_name
        last_name
      }
      project_manager_id
      project_manager {
        id
        first_name
        last_name
        mobile
      }
      sales_rep_id
      sales_rep {
        id
        first_name
        last_name
      }
      creation_date
      implementation_list_approve_date
      water_tap_points_approve_date
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
      properties(orderBy: $orderByProps) {
        id
        name
        remote_category
        value
        order_date
        is_ordered
        is_not_applicable
        send_alert
        due_date
        execution_date
        weight_for_construction_sheet
        weight_for_execution_list
        needs_water_points
        marked
        sales_rep {
          id
          first_name
          last_name
        }
        purchase_orders {
          id
          creation_date
          delivery_date
          remarks
          description
          delivery_to_other_supplier
          is_default_contact_person
          no_delivery_hour
          is_on_call
          pick_up
          on_call_value
          is_purchase_order
          purchase_order_no
          contact_person {
            id
            first_name
            last_name
          }
          status {
            id
            label
          }
          supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
            email
            contact
            contact_persons {
              id
              first_name
              last_name
              email
              notes
              mobile
              phone
            }
          }
          deliver_to_supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
          }
          construction_site {
            id
            name
            address
            city
            zip_code
            comments
            project_manager {
              id
              first_name
              last_name
              mobile
            }
            site_manager {
              id
              first_name
              last_name
              mobile
            }
          }
          line_items {
            id
            name
            description
            sku
            unit_price
            comment
            quantity
            weight
            measuring_unit {
              id
              name
            }
          }
          property {
            id
          }
          property_id
        }
        employee {
          id
          first_name
          last_name
        }
        suppliers {
          id
          supplier {
            id
            name
          }
        }
        parent {
          id
        }
        type {
          id
          name
        }
        nested_type {
          id
          name
        }
        list_types {
          id
          list_type {
            id
            name
          }
        }
        document {
          id
          name
          src
          extension
          is_external
          blob_name
        }
        type_children {
          id
          name
          value
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        children {
          id
          name
          value
          needs_water_points
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
            suggested_value_category {
              id
              category_description
            }
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          type_children {
            name
          }
          children {
            id
            name
            value
            parent {
              id
            }
            weight_for_construction_sheet
            weight_for_execution_list
            remote_category
            type {
              id
              name
            }
            suggested_value {
              id
              suggested_value
              suggested_value_category {
                id
                category_description
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        suggested_value {
          id
          suggested_value
          suggested_value_category {
            id
            category_description
          }
        }
        reporting_item {
          id
          document {
            id
          }
          reporting_label
          template_template_items {
            template {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_CONSTRUCTION_SITE_BY_ID_ANALYSIS = gql`
  query getConstructionSiteById($where: ConstructionSiteWhereInput!) {
    findOneConstructionSite(where: $where) {
      id
      status {
        id
        label
      }
      execution_list_internal_remarks
      name
      address
      archive_no
      city
      zip_code
      wings_code
      wings_account_code
      contract_amount
      epb_reporter_id
      epb_reporter {
        id
        first_name
        last_name
      }
      lead_id
      lead {
        address1
        city1
        email1
        email2
        first_name1
        first_name2
        id
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        zip_code1
      }
      creation_date
      implementation_list_approve_date
      water_tap_points_approve_date
      customer_contract_updates {
        status
        intro
        document_no
        document_date
        summary
        customer_contract_update_line_items {
          percentage_vat
          description
          unit_price
          units
          tax_code {
            id
            rate
            label
            description
          }
        }
      }
      customer_invoices {
        invoice_paid
        invoice_no
        invoice_date
        invoice_due_date
        lead {
          id
        }
        customer {
          id
        }
        customer_invoice_line_items {
          percentage_vat
          description
          unit_price
          units
          tax_code {
            id
            label
            rate
            description
          }
        }
        customer_invoice_payments {
          amount
          comments
          payment_date
        }
      }
    }
  }
`;

export const ADD_CONSTRUCTION_SITE = gql`
  mutation AddConstructionSite($data: ConstructionSiteCreateInput!) {
    createConstructionSite(data: $data) {
      id
      status {
        id
        label
      }
      name
      address
      archive_no
      city
      zip_code
      comments
      contract_amount
      degree_of_completion
      type_of_home
      style_of_home
      billboard_comments
      billboard_required
      billboard_status
      k_level
      e_level
      airtightness
      date_of_contract
      date_sold
      date_completed
      sounding_required
      sounding_status
      lead {
        id
        first_name1
        last_name1
        first_name2
        last_name2
      }
      architect {
        id
        first_name
        last_name
      }
      sales_rep {
        id
        first_name
        last_name
      }
      epb_reporter {
        id
        first_name
        last_name
      }
      safety_coordinator {
        id
        first_name
        last_name
      }
      site_manager {
        id
        first_name
        last_name
      }
      project_manager {
        id
        first_name
        last_name
      }
      payment_transactions {
        description
        payment_date
        id
        percentage
        customer_invoice_legacy {
          id
          invoice_no
          invoice_paid
          invoice_date
        }
      }
      customer_contract_updates {
        id
        status
        document_no
        summary
        customer_contract_update_line_items {
          percentage_vat
          description
          unit_price
          units
          tax_code {
            id
            rate
            label
            description
          }
        }
      }
    }
  }
`;

export const UPDATE_CONSTRUCTION_SITE = gql`
  mutation UpdateConstructionSite(
    $id: Int!
    $data: ConstructionSiteUpdateInput!
    $orderByProps: PropertyOrderByInput
  ) {
    updateConstructionSite(id: $id, data: $data) {
      id
      status {
        id
        label
      }
      execution_list_internal_remarks
      name
      address
      archive_no
      city
      zip_code
      contract_amount
      epb_reporter_id
      epb_reporter {
        id
        first_name
        last_name
      }
      safety_coordinator_id
      safety_coordinator {
        id
        first_name
        last_name
      }
      comments
      architect_id
      architect {
        id
        first_name
        last_name
      }
      lead_id
      lead {
        address1
        city1
        email1
        email2
        first_name1
        first_name2
        id
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        sales_rep {
          id
          first_name
          last_name
        }
        zip_code1
      }
      manager_id
      site_manager {
        id
        first_name
        last_name
      }
      project_manager_id
      project_manager {
        id
        first_name
        last_name
        mobile
      }
      sales_rep_id
      sales_rep {
        id
        first_name
        last_name
      }
      creation_date
      implementation_list_approve_date
      water_tap_points_approve_date
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
      properties(orderBy: $orderByProps) {
        id
        name
        remote_category
        value
        order_date
        is_ordered
        is_not_applicable
        send_alert
        due_date
        execution_date
        weight_for_construction_sheet
        weight_for_execution_list
        needs_water_points
        marked
        purchase_orders {
          id
          creation_date
          delivery_date
          remarks
          description
          delivery_to_other_supplier
          is_default_contact_person
          no_delivery_hour
          is_on_call
          pick_up
          on_call_value
          is_purchase_order
          purchase_order_no
          contact_person {
            id
            first_name
            last_name
          }
          status {
            id
            label
          }
          supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
            email
            contact
            contact_persons {
              id
              first_name
              last_name
              email
              notes
              mobile
              phone
            }
          }
          deliver_to_supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
          }
          construction_site {
            id
            name
            address
            city
            zip_code
            comments
            project_manager {
              id
              first_name
              last_name
              mobile
            }
            site_manager {
              id
              first_name
              last_name
              mobile
            }
          }
          line_items {
            id
            name
            description
            sku
            unit_price
            comment
            quantity
            weight
            measuring_unit {
              id
              name
            }
          }
          property {
            id
          }
          property_id
        }
        sales_rep {
          id
          first_name
          last_name
        }
        employee {
          id
          first_name
          last_name
        }
        suppliers {
          id
          supplier {
            id
            name
          }
        }
        parent {
          id
        }
        type {
          id
          name
        }
        nested_type {
          id
          name
        }
        list_types {
          id
          list_type {
            id
            name
          }
        }
        document {
          id
          name
          src
          extension
          is_external
          blob_name
        }
        type_children {
          id
          name
          value
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        children {
          id
          name
          value
          needs_water_points
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
            suggested_value_category {
              id
              category_description
            }
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          type_children {
            name
          }
          children {
            id
            name
            value
            parent {
              id
            }
            weight_for_construction_sheet
            weight_for_execution_list
            remote_category
            type {
              id
              name
            }
            suggested_value {
              id
              suggested_value
              suggested_value_category {
                id
                category_description
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        suggested_value {
          id
          suggested_value
          suggested_value_category {
            id
            category_description
          }
        }
        reporting_item {
          id
          document {
            id
          }
          reporting_label
          template_template_items {
            template {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CONSTRUCTION_SITE_WITH_PROPERTIES = gql`
  mutation UpdateConstructionSite(
    $id: Int!
    $data: ConstructionSiteUpdateInput!
    $orderByProps: PropertyOrderByInput
  ) {
    updateConstructionSite(id: $id, data: $data) {
      id
      execution_list_internal_remarks
      name
      status {
        id
        label
      }
      address
      archive_no
      city
      zip_code
      contract_amount
      epb_reporter_id
      epb_reporter {
        id
        first_name
        last_name
      }
      safety_coordinator_id
      safety_coordinator {
        id
        first_name
        last_name
      }
      comments
      architect_id
      architect {
        id
        first_name
        last_name
      }
      lead_id
      lead {
        address1
        city1
        email1
        email2
        first_name1
        first_name2
        id
        last_name1
        last_name2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        phone1
        phone1_v2
        phone2
        phone2_v2
        sales_rep {
          id
          first_name
          last_name
        }
        zip_code1
      }
      manager_id
      site_manager {
        id
        first_name
        last_name
      }
      project_manager_id
      project_manager {
        id
        first_name
        last_name
        mobile
      }
      sales_rep_id
      sales_rep {
        id
        first_name
        last_name
      }
      creation_date
      implementation_list_approve_date
      water_tap_points_approve_date
      invoice_customers {
        id
        construction_site_id
        lead_id
        lead {
          first_name1
          first_name2
          last_name1
          last_name2
          email1
          email2
        }
        percentage
      }
      properties(orderBy: $orderByProps) {
        id
        name
        remote_category
        value
        purchase_orders {
          id
          creation_date
          delivery_date
          remarks
          description
          delivery_to_other_supplier
          is_default_contact_person
          no_delivery_hour
          is_on_call
          pick_up
          on_call_value
          is_purchase_order
          purchase_order_no
          contact_person {
            id
            first_name
            last_name
          }
          status {
            id
            label
          }
          supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
            email
            contact
            contact_persons {
              id
              first_name
              last_name
              email
              notes
              mobile
              phone
            }
          }
          deliver_to_supplier {
            id
            name
            address
            phone
            fax
            city
            zip_code
          }
          construction_site {
            id
            name
            address
            city
            zip_code
            comments
            project_manager {
              id
              first_name
              last_name
              mobile
            }
            site_manager {
              id
              first_name
              last_name
              mobile
            }
          }
          line_items {
            id
            name
            description
            sku
            unit_price
            comment
            quantity
            weight
            measuring_unit {
              id
              name
            }
          }
        }
        order_date
        is_ordered
        is_not_applicable
        send_alert
        due_date
        execution_date
        weight_for_construction_sheet
        weight_for_execution_list
        needs_water_points
        marked
        sales_rep {
          id
          first_name
          last_name
        }
        employee {
          id
          first_name
          last_name
        }
        suppliers {
          id
          supplier {
            id
            name
          }
        }
        parent {
          id
        }
        type {
          id
          name
        }
        nested_type {
          id
          name
        }
        list_types {
          id
          list_type {
            id
            name
          }
        }
        document {
          id
          name
          src
          extension
          is_external
          blob_name
        }
        type_children {
          id
          name
          value
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        children {
          id
          name
          value
          needs_water_points
          parent {
            id
          }
          weight_for_construction_sheet
          weight_for_execution_list
          remote_category
          type {
            id
            name
          }
          suggested_value {
            id
            suggested_value
            suggested_value_code
            suggested_value_category {
              id
              category_description
            }
          }
          document {
            id
            name
            src
            extension
            is_external
            blob_name
          }
          type_children {
            name
          }
          children {
            id
            name
            value
            parent {
              id
            }
            weight_for_construction_sheet
            weight_for_execution_list
            remote_category
            type {
              id
              name
            }
            suggested_value {
              id
              suggested_value
              suggested_value_category {
                id
                category_description
              }
            }
            document {
              id
              name
              src
              extension
              is_external
              blob_name
            }
            reporting_item {
              id
              document {
                id
              }
              reporting_label
              template_template_items {
                template {
                  id
                  name
                }
              }
            }
          }
          reporting_item {
            id
            document {
              id
            }
            reporting_label
            template_template_items {
              template {
                id
                name
              }
            }
          }
        }
        suggested_value {
          id
          suggested_value
          suggested_value_category {
            id
            category_description
          }
        }
        reporting_item {
          id
          document {
            id
          }
          reporting_label
          template_template_items {
            template {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CONSTRUCTION_SITE = gql`
  mutation DeleteConstructionSite($where: ConstructionSiteWhereInput) {
    deleteConstructionSite(where: $where) {
      id
    }
  }
`;

export interface IConstructionSite {
  id?: number;
  execution_list_internal_remarks?: string;
  name: string;
  address?: string;
  archive_no?: string;
  city?: string;
  zip_code?: number;
  epb_reporter_id?: number;
  safety_coordinator_id?: number;
  comments?: string;
  architect_id?: number;
  lead_id: number;
  lead: ILead;
  manager_id: number;
  project_manager_id?: number;
  sales_rep_id?: number;
  creation_date?: string;
  implementation_list_approve_date?: string;
  water_tap_points_approve_date?: string;
  properties: IProperty[];
  template_id?: number;
  contract_amount: number;

  degree_of_completion: string;
  type_of_home: string;
  style_of_home: string;
  billboard_comments: string;
  billboard_required: string;
  billboard_status: boolean;
  k_level: string;
  e_level: string;
  airtightness: string;
  date_of_contract: Date;
  date_sold: Date;
  date_completed: Date;
  sounding_required: boolean;
  sounding_status: boolean;

  architect: IArchitect;
  epb_reporter?: IEmployee;
  site_manager?: IEmployee;
  project_manager?: IEmployee;
  safety_coordinator?: IEmployee;
  sales_rep?: ISalesRep;
  invoice_customers?: IInvoiceCustomer[];
  customer_invoices?: ICustomerInvoice[];
  customer_contract_updates?: ICustomerContractUpdate[];
  payment_transactions?: ICustomerPaymentTransaction[];
  status: { label: string };

  update?: boolean;

  prisma_total?: number;
}

export interface IConstructionSiteCreateUpdate {
  id?: number;
  name?: string;
  address?: string;
  archive_no?: string;
  city?: string;
  zip_code?: number;
  epb_reporter_id?: number;
  safety_coordinator_id?: number;
  comments?: string;
  architect_id?: number;
  lead_id?: number;
  lead?: ILead;
  manager_id?: number;
  project_manager_id?: number;
  sales_rep_id?: number;
  creation_date?: string;
  implementation_list_approve_date?: string;
  water_tap_points_approve_date?: string;
  properties?: IProperty[];
  template_id?: number;
  contract_amount?: number | string;
  degree_of_completion?: string;
  type_of_home?: string;
  style_of_home?: string;
  billboard_comments?: string;
  billboard_required?: string;
  billboard_status?: boolean;
  k_level?: string;
  e_level?: string;
  airtightness?: string;
  date_of_contract?: Date;
  date_sold?: Date;
  date_completed?: Date;
  sounding_required?: boolean;
  sounding_status?: boolean;
  architect?: IArchitect;
  epb_reporter?: IEmployee;
  site_manager?: IEmployee;
  project_manager?: IEmployee;
  safety_coordinator?: IEmployee;
  sales_rep?: ISalesRep;
  status?: IConstructionSiteStatus;
  invoice_customers?: IInvoiceCustomer[];
  customer_contract_updates?: ICustomerContractUpdate[];
  payment_transactions?: ICustomerPaymentTransaction[];
  updated?: boolean;
}

export interface IConstructionSiteQueryResult {
  findManyConstructionSites: IConstructionSite[];
}

export const convertConstructionSitesToComboBoxOptions = (
  constructionSites: IConstructionSite[],
) => {
  const result: IComboBoxOption[] = constructionSites.map(
    (constructionSite: IConstructionSite): any => {
      if (constructionSite.id && constructionSite.id) {
        return {
          key: constructionSite.id,
          text: constructionSite.name,
        };
      }
    },
  );
  return result.length > 0 ? result : [];
};

export const convertConstructionSitesToTimesheetComboBoxOptions = (
  constructionSites: IConstructionSite[],
) => {
  const result: IComboBoxOption[] = constructionSites.map(
    (constructionSite: IConstructionSite): any => {
      if (constructionSite.id && constructionSite.id) {
        return {
          key: `constructionsite_${constructionSite.id}`,
          text: constructionSite.name,
        };
      }
    },
  );
  return result.length > 0 ? result : [];
};
