import { useCallback } from 'react';

type Props = {
    color: 'green' | 'blue' | 'orange' | 'red' | 'gray' | 'black' | 'white';
    style?: React.CSSProperties;
}

const Label: React.FC<Props> = ({
 children, style, color = 'green', ...props
}) => {
    const getBackgroundColor = useCallback(() => {
        let value = '#7ab51d';
        switch (color) {
            case 'green':
                value = '#7ab51d';
                break;
            case 'blue':
                value = '#1d9eb5';
                break;
            case 'orange':
                value = '#b5611d';
                break;
            case 'red':
                value = '#b5401d';
                break;
            case 'gray':
                value = '#e5e5e5';
                break;
            case 'black':
                value = '#000000';
                break;
            case 'white':
                value = '#ffffff';
                break;
            default:
                break;
        }
        return value;
    }, [color]);
    return (
      <span
        style={{
 backgroundColor: getBackgroundColor(), padding: '4px', borderRadius: '3px', ...style,
}}
        {...props}
      >
        {children}
      </span>);
};

export default Label;
