import React, {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  TextField,
} from '@fluentui/react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  DELETE_PURCHASE_ORDER,
  dialogModelProps,
  GET_CONSTRUCTION_SITES,
  GET_MEASURING_UNITS,
  GET_PURCHASE_ORDER_BY_ID,
  GET_PURCHASE_ORDER_STATUSES,
  GET_PURCHASE_ORDER_TEMPLATES_ALL_IN,
  IPurchaseOrder,
  returnFormattedDate,
} from '../../../utils';
import {
  columnStyles,
  dialogRemovePurchaseOrderConfirmationProps,
  iconLastProps,
  iconProps,
  stackTokens15,
  textFieldStyles300,
} from '../../../utils/Styles';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import Label from '../../../components/parts/Label';
import useDebounce from '../../../components/hooks/useDebounce';
import { GET_SUPPLIERS_WITH_CONTACT_PERSONS } from '../../../utils/Supplier';
import { GET_CONSTRUCTION_SITE_BY_ID_SIMPLE } from '../../../utils/ConstructionSite';
import { toastError, toastSuccess } from '../../../utils/toast';
import PurchaseOrders from '../PurchaseOrders';
import purchaseOrderDownload from './PurchaseOrderDownload';
import PurchaseOrderDetail, { sortLineItems } from './PurchaseOrderDetail';

const PurchaseOrdersOverview = ({
  orders,
  refetch,
  setPurchaseOrders,
  showBack = false,
  constructionSite,
  redirectTarget,
  searchField,
  setSearchField,
}: any) => {
  const [items, setItems] = useState(orders);
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IPurchaseOrder | undefined
  >();

  const search = { useSearchParams };
  const [searchParams, setSearchParams] = useSearchParams();
  const addNew = searchParams.get('addNew');
  const purchaseOrderId = searchParams.get('id');
  const constructionSiteId = searchParams.get('constructionSite');

  const [getConstructionSite] = useLazyQuery(
    GET_CONSTRUCTION_SITE_BY_ID_SIMPLE,
  );

  const [getPurchaseOrderByID] = useLazyQuery(GET_PURCHASE_ORDER_BY_ID, {
    onCompleted: (x: any) => {
      if (x && x.findOnePurchaseOrder) {
        let purchaseOrder: IPurchaseOrder = x.findOnePurchaseOrder;
        purchaseOrder = sortLineItems(purchaseOrder);
        // setPurchaseOrder(purchaseOrder);
      }
    },
  });

  const downloadPDF = () => {
    if (selectionDetails && selectionDetails.id) {
      getPurchaseOrderByID({
        variables: {
          where: {
            id: selectionDetails.id,
          },
        },
      }).then((x: any) => {
        if (x && x.data && x.data.findOnePurchaseOrder) {
          let purchaseOrder: IPurchaseOrder = x.data.findOnePurchaseOrder;
          purchaseOrder = sortLineItems(purchaseOrder);
          // setPurchaseOrder(purchaseOrder);
          purchaseOrderDownload(purchaseOrder);
        }
      });
    }
  };

  useEffect(() => {
    if (addNew && addNew === 'true') {
      setIsPanelOpen(true);
      if (constructionSiteId) {
        getConstructionSite({
          variables: {
            where: {
              id: parseInt(constructionSiteId, 10),
            },
          },
        }).then(x => {
          setSelectionDetails({
            construction_site: x.data.findOneConstructionSite,
          });
        });
      } else {
        setSelectionDetails(undefined);
      }
    } else if (purchaseOrderId) {
      setSelectionDetails({ id: parseInt(purchaseOrderId, 10) });
      setIsPanelOpen(true);
    }
  }, [addNew, purchaseOrderId]);

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    if (!isConfirmationHidden) setSelectedId(0);
  };

  const [supplierSearch, setSupplierSearch] = useState<string | undefined>();
  const [constructionSiteSearch, setConstructionSiteSearch] = useState<
    string | undefined
  >();

  const debouncedSearchTermConstructionSite = useDebounce(
    constructionSiteSearch,
    500,
  );
  const debouncedSearchTerm = useDebounce(supplierSearch, 500);

  const supplierQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTerm
        ? {
            OR: [
              { name: { contains: debouncedSearchTerm } },
              { city: { contains: debouncedSearchTerm } },
              { email: { contains: debouncedSearchTerm } },
              { phone_v2: { contains: debouncedSearchTerm } },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTerm]);

  const constructionSiteQuery = useCallback(() => {
    const query: any = {
      orderBy: {
        name: 'asc',
      },
      filter: debouncedSearchTermConstructionSite
        ? {
            OR: [
              { name: { contains: debouncedSearchTermConstructionSite } },
              { city: { contains: debouncedSearchTermConstructionSite } },
              { address: { contains: debouncedSearchTermConstructionSite } },
              // { email: { contains: debouncedSearchTermConstructionSite } },
              // { phone: { contains: debouncedSearchTermConstructionSite } },
              {
                zip_code: Number.isNaN(
                  parseInt(debouncedSearchTermConstructionSite, 10),
                )
                  ? undefined
                  : {
                      equals: parseInt(debouncedSearchTermConstructionSite, 10),
                    },
              },
            ],
          }
        : undefined,
      take: 15,
    };

    return query;
  }, [debouncedSearchTermConstructionSite]);

  const [deletePurchaseOrder] = useMutation(DELETE_PURCHASE_ORDER);

  const deleteOrder = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deletePurchaseOrder({
            variables: {
              where: {
                id: selectedId,
              },
            },
            onCompleted: x => {
              resolve(x);
            },
            onError: x => {
              reject(x);
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      refetch().then((x: any) => {
        setPurchaseOrders(x.data.findManyPurchaseOrders);
        toggleConfirmationDialog();
        selection.setAllSelected(false);
      });
      toastSuccess('Bestelbon verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const {
    loading: loadingTemplates,
    error: errorTemplates,
    data: dataTemplates,
  } = useQuery(GET_PURCHASE_ORDER_TEMPLATES_ALL_IN, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    skip: !selectionDetails && !isPanelOpen,
  });

  const {
    loading: loadingMeasuringUnit,
    error: errorMeasuringUnit,
    data: dataMeasuringUnits,
  } = useQuery(GET_MEASURING_UNITS, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
  });

  const {
    loading: loadingSuppliers,
    error: errorSuppliers,
    data: dataSuppliers,
  } = useQuery(GET_SUPPLIERS_WITH_CONTACT_PERSONS, {
    variables: {
      ...supplierQuery(),
    },
    skip: !selectionDetails && !isPanelOpen,
  });

  /*
  const { error: errorContactPersons, data: dataContactPersons } = useQuery(
    GET_CONTACT_PERSONS,
    {
      variables: {
        orderBy: {
          name: 'asc',
        },
      },
      //skip: order && order.id && order.id > 0,
    },
  );
  */

  const {
    error: errorConstructionSites,
    loading: loadingConstructionSites,
    data: dataConstructionSites,
  } = useQuery(GET_CONSTRUCTION_SITES, {
    variables: {
      ...constructionSiteQuery(),
    },
    skip: !selectionDetails && !isPanelOpen,
  });

  const { data: dataStatuses, error: errorStatuses } = useQuery(
    GET_PURCHASE_ORDER_STATUSES,
    {
      skip: !selectionDetails && !isPanelOpen,
    },
  );

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    setSearchField(newValue || '');
  };

  const openPurchaseOrderDetail = (newPurchaseOrder?: boolean) => {
    if (selection && !newPurchaseOrder) {
      // getLogItem({ variables: { where: { id: selectionDetails.id } } });
    } else {
      setSelectionDetails(undefined);
    }
    setIsPanelOpen(true);
  };

  const openCopyPurchaseOrderDetail = () => {
    if (selection && selectionDetails) {
      // getLogItem({ variables: { where: { id: selectionDetails.id } } });
      const { id, ...newObject } = selectionDetails;

      const obj = newObject;
      if (obj.line_items) {
        obj.line_items = obj.line_items.map(lineItem => ({
          ...lineItem, // Spread operator to copy existing properties
          isNew: true,
          isDirty: true, // Assuming you want to set isDirty to true as well, though it wasn't fully specified in your request
        }));
      }
      setSelectionDetails(obj);
    }
    setIsPanelOpen(true);
  };

  const removePurchaseOrder = () => {
    if (selectionDetails && selectionDetails.id) {
      setSelectedId(selectionDetails.id);
    }
    toggleConfirmationDialog();
  };

  const columns = [
    {
      key: 'column1',
      name: 'Nr',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item && item.purchase_order_no ? item.purchase_order_no : ''}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Beschrijving',
      minWidth: 190,
      maxWidth: 190,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>{item && item.description ? item.description : ''}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Leverancier',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>{item && item.supplier && item.supplier.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Werf',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>{item.construction_site && item.construction_site.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Status',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <Label
          color={
            item.status
              ? item.status.id === 2
                ? 'green'
                : item.status.id === 1
                ? 'blue'
                : 'gray'
              : 'gray'
          }
        >
          {item.status && item.status.label}
        </Label>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Aangemaakt',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.creation_date &&
            moment(item.creation_date).format('DD/MM/YYYY')}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Leverdatum',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          {item.delivery_date
            ? moment(item.delivery_date).format('DD/MM/YYYY')
            : null}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    /* {
      key: 'column8',
      name: '',
      className: columnStyles.centerContents,
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: IPurchaseOrder) => (
        <span>
          <Icon
            iconName='Edit'
            styles={iconProps}
            onClick={() => editPurchaseOrder(item.id)}
          />
          <Icon
            iconName='Download'
            styles={iconProps}
            onClick={() => purchaseOrderDownload(item)}
          />
          <Icon
            iconName='Delete'
            styles={iconLastProps}
            onClick={() => removePurchaseOrder(item.id)}
          />
        </span>
      ),
      data: 'string',
      isPadded: false,
    }, */
  ];

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${orders.length} / ${
        orders.length > 0 ? orders[0].prisma_total : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openPurchaseOrderDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openPurchaseOrderDetail(),
      disabled: !(selectionDetails && selectionDetails.id),
      theme: commandBarTheme,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'Download' },
      onClick: () => downloadPDF(),
      disabled: !(selectionDetails && selectionDetails.id),
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => removePurchaseOrder(),
      disabled: !(selectionDetails && selectionDetails.id),
      theme: commandBarTheme,
    },
    {
      key: 'copy',
      text: 'Kopiëren',
      iconProps: { iconName: 'copy' },
      onClick: () => openCopyPurchaseOrderDetail(),
      disabled: !(selectionDetails && selectionDetails.id),
      theme: commandBarTheme,
    },
  ];

  const commandBarFarItems: ICommandBarItemProps[] = [
    {
      key: 'back',
      text: 'Terug',
      iconProps: { iconName: 'back' },
      href: `/construction-sites/${constructionSite}/${
        redirectTarget === 'execution-list'
          ? 'implementation-list'
          : 'site-overview'
      }`,
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    (() => {
      setItems(orders);
    })();
  }, [orders]);

  document.title = '3bouw | Bestelbonnen';

  return (
    <Stack tokens={stackTokens15}>
      <TextField
        label='Zoek op werf, leverancier of bestelbonnummer'
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <CommandBarSticky
        items={commandBaritems}
        farItems={showBack ? commandBarFarItems : []}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        width='1188px'
        maxWidth='1188px'
      />

      {/* <CommandBar
                items={commandBaritems}
                ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
                theme={commandBarTheme} /> */}
      <DetailsList
        items={items}
        compact
        columns={columns}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        getKey={undefined}
        setKey='none'
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        onItemInvoked={() => openPurchaseOrderDetail()}
      />

      <PurchaseOrderDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        purchaseOrderSource={selectionDetails}
        constructionSites={
          dataConstructionSites
            ? dataConstructionSites.findManyConstructionSites
            : []
        }
        suppliers={dataSuppliers ? dataSuppliers.findManySuppliers : []}
        templates={
          dataTemplates ? dataTemplates.findManyPurchaseOrderTemplates : []
        }
        statuses={
          dataStatuses ? dataStatuses.findManyPurchaseOrderStatuses : undefined
        }
        measuringUnits={
          dataMeasuringUnits ? dataMeasuringUnits.findManyMeasuringUnits : []
        }
        supplierSearch={supplierSearch}
        setSupplierSearch={setSupplierSearch}
        constructionSiteSearch={constructionSiteSearch}
        setConstructionSiteSearch={setConstructionSiteSearch}
        downloadPDF={downloadPDF}
        refetch={refetch}
        setSelectionDetails={setSelectionDetails}
      />

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemovePurchaseOrderConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteOrder} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default PurchaseOrdersOverview;
