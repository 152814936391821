/* eslint-disable no-underscore-dangle */
import React, { FormEvent, useState } from 'react';
import moment from 'moment';
import {
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  IDragDropEvents,
  IDragDropContext,
  DetailsRow,
  Selection,
} from '@fluentui/react/lib/DetailsList';
import { ShimmeredDetailsList } from '@fluentui/react/lib/ShimmeredDetailsList';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';
import {
  IConstructionSite,
  IProperty,
  overviewControlStyles,
  property_types,
  stackTokens5,
} from '../../../utils';
import DocumentLink from './DocumentLink';
import MultiValueSummary from './MultiValueSummary';

const theme = getTheme();

const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.red,
  borderTop: 'red 1px solid',
});

interface IExecutionListOverview {
  callBackProperties: any;
  dragAndDropEnabled: boolean;
  properties: IProperty[];
  setSelection: React.Dispatch<IProperty | undefined>;
  setSelectedPropertyID: any;
  editItem: any;
  setFilter: React.Dispatch<string>;
  filter?: string;
  moveItem: (a: IProperty, b: IProperty) => void;
  constructionSite: any;
  setConstructionSite: React.Dispatch<
    React.SetStateAction<IConstructionSite | undefined>
  >;
}

const ExecutionListOverview = ({
  properties,
  setSelectedPropertyID,
  setSelection,
  editItem,
  setFilter,
  filter,
  moveItem = (a: IProperty, b: IProperty) => {},
  constructionSite,
  setConstructionSite,
}: IExecutionListOverview) => {
  const [draggedItem, setDraggedItem] = useState<{
    property?: IProperty;
    index: number;
  }>({ property: undefined, index: -1 });

  const onSelectionChanged = () => {
    const selection: any = _selection.getSelection();
    if (selection && selection.length > 0) {
      if (selection[0].id) setSelectedPropertyID(selection[0].id);
    } else {
      setSelectedPropertyID(undefined);
    }
  };

  const [_selection] = useState(
    new Selection({
      onSelectionChanged,
    }),
  );

  const getKey = (item: any) => {
    if (item) return item.id;
    return 0;
  };

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue || '';

    setFilter(filterString);
  };

  const onRemarksValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    setConstructionSite({
      ...constructionSite,
      execution_list_internal_remarks: newValue,
    });
  };

  const renderDescription = (property: IProperty) => (
    <span
      style={
        property.delete
          ? { opacity: 0.5, textDecoration: 'line-through' }
          : undefined
      }
    >
      <strong>
        {property.name}
        {property.isDirty ? '*' : null}
      </strong>
    </span>
  );

  const renderRemarks = (property: IProperty) => {
    if (property.is_not_applicable) {
      return (
        <span
          style={
            property.delete
              ? { opacity: 0.5, textDecoration: 'line-through' }
              : undefined
          }
        >
          n.v.t.
        </span>
      );
    }
    switch (property.type.name) {
      case property_types.NOTE:
        return (
          <span
            style={
              property.delete
                ? { opacity: 0.5, textDecoration: 'line-through' }
                : undefined
            }
          >
            {property.value}
          </span>
        );
      case property_types.STRING:
        return (
          <span
            style={
              property.delete
                ? { opacity: 0.5, textDecoration: 'line-through' }
                : undefined
            }
          >
            {property.value}
          </span>
        );
      case property_types.DATE:
        return (
          <span
            style={
              property.delete
                ? { opacity: 0.5, textDecoration: 'line-through' }
                : undefined
            }
          >
            {moment(property.value).format('DD-MM-YYYY')}
          </span>
        );
      case property_types.DOCUMENT:
        return (
          <span
            style={
              property.delete
                ? { opacity: 0.5, textDecoration: 'line-through' }
                : undefined
            }
          >
            <DocumentLink property={property} />
          </span>
        );
      case property_types.SUGGESTED_VALUE:
        return (
          <span
            style={
              property.delete
                ? { opacity: 0.5, textDecoration: 'line-through' }
                : undefined
            }
          >
            <span>
              <strong>
                {property.suggested_value &&
                  property.suggested_value.suggested_value_category && (
                    <>
                      {
                        property.suggested_value.suggested_value_category
                          .category_description
                      }
                      :{' '}
                    </>
                  )}
              </strong>
            </span>
            {property.suggested_value
              ? property.suggested_value.suggested_value
              : ''}
          </span>
        );
      case property_types.PARENT:
        if (property.children) return null;
        break;
      case property_types.MULTI_VALUE:
        // console.log(property);
        if (property.children) {
          return (
            <MultiValueSummary
              property={property}
              openDetail={item => {
                setSelection(item);
                editItem();
              }}
            />
          );
        }
        break;
      default:
        return <span>Error</span>;
    }
  };

  const columns: IColumn[] = [
    // {
    //   key: 'column0',
    //   name: 'Type',
    //   fieldName: 'type',
    //   minWidth: 240,
    //   maxWidth: 240,
    //   isRowHeader: true,
    //   isResizable: false,
    //   onColumnClick: () => {},
    //   onRender: (property: IProperty) => property.nested_type?.name,
    //   data: 'string',
    //   isPadded: true,
    // },
    {
      key: 'column1',
      name: 'Omschrijving',
      fieldName: 'name',
      minWidth: 240,
      maxWidth: 240,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: renderDescription,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Materiaalkeuze - Opmerkingen',
      fieldName: 'value',
      minWidth: 340,
      maxWidth: 640,
      isMultiline: true,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: renderRemarks,
      data: 'string',
      isPadded: true,
    },
  ];

  const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = { backgroundColor: theme.palette.themeLighterAlt };
      }

      if (props.item.marked) {
        customStyles.root = { backgroundColor: theme.palette.themeLight };
      }

      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };

  const _insertBeforeItem = (item: IProperty) => {
    const draggedItems = _selection.isIndexSelected(draggedItem.index)
      ? (_selection.getSelection() as IProperty[])
      : [draggedItem.property];

    if (draggedItems && draggedItems[0]) {
      moveItem(draggedItems[0], item);
      _selection.setAllSelected(false);
    }
  };

  const _getDragDropEvents = (): IDragDropEvents => ({
    canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) =>
      true,
    canDrag: (item?: any) => true,
    onDragOver: (item?: any, event?: DragEvent) =>
      // return string is the css classes that will be added to the entering element.
      dragEnterClass,
    onDragLeave: (item?: any, event?: DragEvent) => {},
    onDrop: (item?: any, event?: DragEvent) => {
      if (draggedItem) {
        _insertBeforeItem(item);
      }
    },
    onDragStart: (
      item?: any,
      itemIndex?: number,
      selectedItems?: any[],
      event?: MouseEvent,
    ) => {
      setDraggedItem({
        property: item,
        index: itemIndex!,
      });
    },
    onDragEnd: (item?: any, event?: DragEvent) => {
      setDraggedItem({
        property: undefined,
        index: -1,
      });
    },
  });

  const _dragDropEvents = _getDragDropEvents();

  return (
    <Stack tokens={stackTokens5}>
      <TextField
        label='Filter op omschrijving:'
        onChange={onSearchValueChange}
        value={filter}
        styles={overviewControlStyles}
      />
      <ShimmeredDetailsList
        items={properties}
        compact
        columns={columns}
        selection={_selection}
        selectionMode={SelectionMode.single}
        getKey={getKey}
        onItemInvoked={editItem}
        setKey='none'
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        // dragAndDropEnabled={dragAndDropEnabled}
        // dragAndDropCallBack={onDrop}
        onRenderRow={_onRenderRow}
        dragDropEvents={_dragDropEvents}
      />
      {/* <TextField
        label='Interne opmerkingen'
        multiline
        value={constructionSite?.execution_list_internal_remarks ? constructionSite?.execution_list_internal_remarks : ''}
        onChange={onRemarksValueChange}
      /> */}
    </Stack>
  );
};

export default ExecutionListOverview;
