import {
 DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import { useState } from 'react';
import { IPlan } from '../../../utils/Plan';
import { DetailsListDefault } from '../../../components/parts';

interface props {
  items: IPlan[];
  selection?: ISelection<IObjectWithKey> | undefined;
  onItemInvoked?: () => void;
  enableShimmer?: boolean;
  isSortedAsc?: boolean;
  sortedField: string;
  setSorting: (isDesc: boolean, field: string) => void;
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
}

const PlanDetailsList = ({
  items,
  selection,
  onItemInvoked,
  enableShimmer = false,
  isSortedAsc = false,
  sortedField,
  setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
  loadMore,
  lazyLoading,
  loading,
  loadMoreCallback,
}: props) => {
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;
          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);
      setSorting(!sortDescending, fieldName);
    }
  };
  const columnsList = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'title',
      minWidth: 250,
      maxWidth: 500,
      isRowHeader: true,
      isSorted: true,
      onColumnClick,
      onRender: (plan: IPlan) => <span>{plan.title}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Actief',
      fieldName: 'active',
      minWidth: 200,
      maxWidth: 500,
      isRowHeader: true,
      onColumnClick,
      onRender: (plan: IPlan) => <span>{plan.active ? 'Ja' : 'Nee'}</span>,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Interne Beschrijving',
      fieldName: 'internal_description',
      minWidth: 200,
      maxWidth: 500,
      isRowHeader: true,
      onColumnClick,
      onRender: (plan: IPlan) => <span>{plan.internal_description}</span>,
      data: 'string',
      isPadded: true,
    },
    // {
    //   key: 'column3',
    //   name: 'Wings Code',
    //   fieldName: 'code',
    //   minWidth: 250,
    //   maxWidth: 500,
    //   isRowHeader: true,
    //   onColumnClick,
    //   onRender: (plan: IPlan) => <span>{plan.code}</span>,
    //   data: 'string',
    //   isPadded: true,
    // },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  return (
    <DetailsListDefault
      items={items}
      columns={columns}
      layoutMode={DetailsListLayoutMode.justified}
      selection={selection}
      selectionMode={SelectionMode.single}
      selectionPreservedOnEmptyClick
      onItemInvoked={onItemInvoked}
      enableShimmer={enableShimmer}
      loadMore={loadMore}
      lazyLoading={lazyLoading}
      loadMoreCallback={loadMoreCallback}
      loading={loading}
    />
  );
};

export default PlanDetailsList;
