import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import moment from 'moment';

import Logo from '../../../3Bouw_logo.png';

import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { IPurchaseOrderLine, IPurchaseOrder } from '../../../utils';
import { ILead } from '../../../utils/Lead';
import { ISuggestedValue } from '../../../utils/SuggestedValue';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

function displayText(text: string | undefined, noNewLine?: boolean) {
  if (text) {
    if (text === 'Invalid date') {
      return '\n';
    }

    return text;
  }
  if (!noNewLine) return '\n';
  return '';
}

function getSuggestedValue(
  suggestedValues: ISuggestedValue[] | undefined,
  code: string | undefined,
) {
  if (!code) return '\n';
  if (!suggestedValues) return code;

  for (let i = 0; i < suggestedValues.length; i++) {
    if (suggestedValues[i].suggested_value_code === code) {
      return suggestedValues[i].suggested_value;
    }
  }

  return code;
}

const LeadDownloadTemplate = ({
  lead,
  dataSuggestedValues,
}: {
  lead: ILead;
  dataSuggestedValues: ISuggestedValue[];
}) => (
  <Document>
    <Page size='A4' style={styles.page} wrap>
      {/* Header repeated on every page */}
      <PageHeader lead={lead} />

      {/* PurchaseOrder */}

      <View
        style={{
          flexDirection: 'row',
          border: '1px solid black',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePage}>PROSPECT</Text>

          <View style={styles.block}>
            <Text style={styles.label}>Naam</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.title1, true)}{' '}
              {displayText(lead.last_name1, true)}{' '}
              {displayText(lead.first_name1, true)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>E-mailadres</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.email1)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Adres</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.address1)}
            </Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.zip_code1 as any, true)}{' '}
              {displayText(lead.city1, true)}
            </Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.country, true)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>GSM</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                lead && !lead.mobile1_v2 && lead.mobile1
                  ? lead.mobile1
                  : lead.mobile1_v2,
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Telefoon</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                lead && !lead.phone1_v2 && lead.phone1
                  ? lead.phone1
                  : lead.phone1_v2,
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>BTW-nummer</Text>
            <Text style={styles.pageHeaderName}>{displayText(lead.vat)}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePage}>PARTNER</Text>
          <View style={styles.block}>
            <Text style={styles.label}>Naam</Text>
            <Text style={styles.pageHeaderName}>
              {lead.first_name2 || lead.last_name2
                ? `${displayText(lead.title2, true)} ${displayText(
                    lead.last_name2,
                    true,
                  )} ${displayText(lead.first_name2, true)}`
                : ''}
            </Text>

            <View style={styles.block}>
              <Text style={styles.label}>E-mailadres</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(lead.email2)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>GSM</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  lead && !lead.mobile2_v2 && lead.mobile2
                    ? lead.mobile2
                    : lead.mobile2_v2,
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Telefoon</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  lead && !lead.phone2_v2 && lead.phone2
                    ? lead.phone2
                    : lead.phone2_v2,
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          border: '1px solid black',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <View style={{ flexDirection: 'column', width: '100%' }}>
          <Text style={styles.label}>Algemene Opmerkingen</Text>
          <Text style={styles.pageHeaderName}>
            {displayText(lead.comments)}
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          border: '1px solid black',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePageMid}>STATUS</Text>

          <View style={styles.block}>
            <Text style={styles.label}>Datum ingave</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                moment(lead.date_created ? lead.date_created : '').format(
                  'DD/MM/YYYY',
                ),
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Ingegeven door</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                (lead as any).created_by
                  ? (lead as any).created_by.first_name
                    ? (lead as any).created_by.first_name
                    : ''
                  : '',
              )}{' '}
              {displayText(
                (lead as any).created_by
                  ? (lead as any).created_by.last_name
                    ? (lead as any).created_by.last_name
                    : ''
                  : '',
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Datum laatste update</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                moment(
                  lead.date_last_updated ? lead.date_last_updated : '',
                ).format('DD/MM/YYYY'),
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Laatste update door</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                (lead as any).last_updated_by
                  ? (lead as any).last_updated_by.first_name
                    ? (lead as any).last_updated_by.first_name
                    : ''
                  : '',
              )}{' '}
              {displayText(
                (lead as any).last_updated_by
                  ? (lead as any).last_updated_by.last_name
                    ? (lead as any).last_updated_by.last_name
                    : ''
                  : '',
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Status</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.status)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Reden passief / nihil</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.reason_declined)}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePageMid}>BOUWGROND INFO</Text>
          <View style={styles.block}>
            <Text style={styles.pageHeaderName}>
              {lead.construction_site_available
                ? 'Bouwgrond beschikbaar'
                : 'Bouwgrond niet beschikbaar'}
            </Text>

            <View style={styles.block}>
              <Text style={styles.label}>Adres werf</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(lead.construction_site_address)}
              </Text>
              <Text style={styles.pageHeaderName}>
                {displayText(lead.construction_site_zip_code as any)}{' '}
                {displayText(lead.construction_site_city)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Zoekt bouwgrond te</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(lead.construction_site_wanted_location)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Extra info werf</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(lead.construction_site_comments)}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          border: '1px solid black',
          padding: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
        wrap={false}
      >
        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePageMid}>STATUS</Text>

          <View style={styles.block}>
            <Text style={styles.label}>Datum ingave</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                moment(lead.date_created ? lead.date_created : '').format(
                  'DD/MM/YYYY',
                ),
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Budget (min)</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.budget_estimate_low as any)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Budget (max)</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(lead.budget_estimate_high as any)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Graad van afwerking</Text>
            <Text style={styles.pageHeaderName}>
              {getSuggestedValue(
                dataSuggestedValues,
                lead.degree_of_completion,
              )}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Type woning</Text>
            <Text style={styles.pageHeaderName}>
              {getSuggestedValue(dataSuggestedValues, lead.type_of_home)}
            </Text>
          </View>

          <View style={styles.block}>
            <Text style={styles.label}>Gepland bouwjaar</Text>
            <Text style={styles.pageHeaderName}>
              {displayText(
                moment(
                  lead.year_to_start_construction
                    ? lead.year_to_start_construction
                    : '',
                ).format('YYYY'),
              )}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: 'column', width: '50%' }}>
          <Text style={styles.pageHeaderTitlePageMid}>MARKETING</Text>
          <View style={styles.block}>
            <View style={styles.block}>
              <Text style={styles.label}>Verkoper</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  lead.sales_rep_employee.first_name ??
                    lead.sales_rep_employee.first_name,
                )}{' '}
                {displayText(lead.sales_rep_employee.last_name)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Naambekendheid via</Text>
              <Text style={styles.pageHeaderName}>
                {getSuggestedValue(dataSuggestedValues, lead.known_by)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Contact opgenomen via</Text>
              <Text style={styles.pageHeaderName}>
                {getSuggestedValue(dataSuggestedValues, lead.contact_method)}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Datum documentatie verstuurd</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  moment(
                    lead.date_documentation_sent
                      ? lead.date_documentation_sent
                      : '',
                  ).format('DD/MM/YYYY'),
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Datum laatste bezoek kijkwoning</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  moment(
                    lead.date_visit_exhibition_house
                      ? lead.date_visit_exhibition_house
                      : '',
                  ).format('DD/MM/YYYY'),
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>
                Datum laatste bezoek opendeur/werf
              </Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  moment(
                    lead.date_visit_exposition
                      ? lead.date_visit_exposition
                      : '',
                  ).format('DD/MM/YYYY'),
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Datum laatste bezoek info-avond</Text>
              <Text style={styles.pageHeaderName}>
                {displayText(
                  moment(
                    lead.date_last_info_visit ? lead.date_last_info_visit : '',
                  ).format('DD/MM/YYYY'),
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Documentatie versturen via</Text>
              <Text style={styles.pageHeaderName}>
                {getSuggestedValue(
                  dataSuggestedValues,
                  lead.preferred_way_of_contact,
                )}
              </Text>
            </View>

            <View style={styles.block}>
              <Text style={styles.label}>Concurrentie</Text>
              <Text style={styles.pageHeaderName}>
                {getSuggestedValue(dataSuggestedValues, lead.competitors)}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          border: '1px solid black',
          paddingTop: '10px',
          marginTop: '10px',
          paddingBottom: '-1px',
          marginBottom: '0px',
        }}
        wrap
        break
      >
        <View
          style={{
            flexDirection: 'column',
            width: '100%',
          }}
          wrap
        >
          <Text style={styles.pageHeaderTitlePageMid2}>LOG ITEMS</Text>
          <PageLeadLines lead={lead} suggestedValues={dataSuggestedValues} />
        </View>
      </View>

      {/* Footer repeated on every page */}
      {/* Legal information */}
      <Text style={styles.now} fixed>
        {moment(new Date()).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>
  </Document>
);

const PageHeader = ({ lead }: { lead: ILead }) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      <Image src={Logo} style={styles.image} />
      <View>
        <Text style={styles.pageHeaderTitle}>
          {'Prospect: '}
          {lead.last_name1 && lead.last_name2
            ? `${lead.last_name1} - ${lead.last_name2}`
            : `${lead.last_name1} ${lead.first_name1}`}
        </Text>
        <Text style={styles.pageHeaderName}>
          {lead && lead.sales_rep_employee && (
            <>
              Verkoper:{' '}
              {lead.sales_rep_employee.first_name ??
                lead.sales_rep_employee.first_name}{' '}
              {lead.sales_rep_employee.last_name ??
                lead.sales_rep_employee.last_name}
            </>
          )}
        </Text>
      </View>
    </View>
  </View>
);
const PageLegalInfo = () => (
  <View style={[styles.legalInfo]} fixed wrap={false}>
    {/* <Text>Maatschappelijke zetel:</Text> */}
    <View style={styles.legalInfoRow}>
      <Text>
        <Text style={{ fontStyle: 'bold' }}>3Bouw BV</Text> Zone Reme 1, 2260
        Westerlo <Text style={{ fontStyle: 'bold' }}>T</Text> 014/70 47 47{' '}
        <Text style={{ fontStyle: 'bold' }}>E</Text> info@3bouw.be{' '}
        <Text style={{ fontStyle: 'bold' }}>BTW</Text> BE 0884.733.931
      </Text>
    </View>
    <View style={styles.legalInfoRow}>
      <Text>
        RPR Antwerpen, afd. Turnhout &bull; Erkend aannemer klasse 5 onder
        erkenningsnr.: 32.454 <Text style={{ fontStyle: 'bold' }}>KBC</Text>{' '}
        iban BE16 7310 2542 3974
      </Text>
    </View>
  </View>
);

const PagePurchaseOrderDeliveryDetails = ({ lead }: { lead: ILead }) => (
  <View style={styles.purchaseOrderDetails} fixed wrap={false}>
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flexDirection: 'column' }} />
      <Text style={styles.pageHeaderTitle}>
        {'Prospect: '}
        {lead.last_name1 && lead.last_name2
          ? `${lead.last_name1} - ${lead.last_name2}`
          : `${lead.first_name1} ${lead.last_name1}`}
      </Text>
    </View>
  </View>
);

const PagePurchaseOrderLines = ({
  purchaseOrder,
}: {
  purchaseOrder: IPurchaseOrder;
}) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColQuantity}>Aantal</Text>
      <Text style={styles.tableColUnit}>Eenheid</Text>
      <Text style={styles.tableColPrice}>Prijs*</Text>
    </View>

    {/* Lines */}
    {purchaseOrder.line_items && purchaseOrder.line_items.length > 0
      ? purchaseOrder.line_items
          .slice()
          .sort((a, b) => (a.weight || 1) - (b.weight || 1))
          .map((line: IPurchaseOrderLine) => (
            <View style={styles.tableRow}>
              <Text style={styles.tableColDescription}>{line.name}</Text>
              <Text style={styles.tableColQuantity}>
                {line.quantity ? line.quantity : null}
              </Text>
              <Text style={styles.tableColUnit}>
                {line.measuring_unit && line.measuring_unit.id !== 9
                  ? line.measuring_unit.name
                  : null}
              </Text>
              <Text style={styles.tableColPrice}>
                {line.unit_price ? `€ ${line.unit_price}` : null}
              </Text>
              {line.comment && line.comment.length > 0 ? (
                <Text style={styles.tableColRemarks}>
                  Opmerking: {line.comment}
                </Text>
              ) : null}
            </View>
          ))
      : null}
  </View>
);

const PageLeadLines = ({
  lead,
  suggestedValues,
}: {
  lead: any;
  suggestedValues: ISuggestedValue[] | undefined;
}) => (
  <View style={styles.table} wrap>
    {/* Table header repeated on every page */}
    {lead ? (
      <View style={styles.tableRowHeader} fixed>
        <Text style={styles.tableColDescription}>Omschrijving</Text>
        <Text style={styles.tableColBTW}>Opvolging nodig</Text>
        <Text style={styles.tableColBTW}>Verkoper/Verantwo...</Text>
        <Text style={styles.tableColPrice}>Opvolgen voor</Text>
      </View>
    ) : null}

    {/* Lines */}
    {lead && lead.log_items.length > 0
      ? lead.log_items.map((line: any, i: number) => (
        <>
          <View key={`line-${i}`} style={styles.tableRow}>
            <Text style={styles.tableColDescription}>
              {getSuggestedValue(suggestedValues, line.title)}
            </Text>
            <Text style={styles.tableColBTW}>
              {line.action_required ? 'Ja' : 'Nee'}
            </Text>
            <Text style={styles.tableColBTW}>
              {line.sales_rep
                  ? line.sales_rep.first_name
                    ? line.sales_rep.first_name
                    : ''
                  : ''}{' '}
              {line.sales_rep
                  ? line.sales_rep.last_name
                    ? line.sales_rep.last_name
                    : ''
                  : ''}
            </Text>
            <Text style={styles.tableColPrice}>
              {line.action_required_by
                  ? moment(line.action_required_by).format('DD/MM/YYYY')
                  : ''}
            </Text>
          </View>
          <View key={`line-${i}`} style={styles.tableRow2}>
            <Text style={styles.pageHeaderName}>
              {displayText(line.text)}
            </Text>
          </View>
        </>
        ))
      : null}
  </View>
);

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  bold: {
    fontStyle: 'bold',
  },
  borderTop: {
    borderTopWidth: 2,
    borderColor: '#76B729',
  },
  exBTW: {
    fontSize: 11,
    marginBottom: 5,
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoBold: {
    fontStyle: 'bold',
  },
  legalInfoLeft: {
    textAlign: 'left',
    width: '33%',
  },
  legalInfoMiddle: {
    alignItems: 'baseline',
    textAlign: 'center',
    width: '34%',
  },
  legalInfoRight: {
    textAlign: 'right',
    width: '33%',
  },
  legalInfoRow: {
    flexDirection: 'row',
  },

  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 45,
    right: 40,
    // color: 'grey',
  },
  paddingTop10: {
    paddingTop: 10,
  },
  paddingTop5: {
    paddingTop: 3,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 140,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 45,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
  },
  pageHeaderTitlePageMid: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: '10px',
  },
  pageHeaderTitlePageMid2: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: '10px',
    paddingLeft: '10px',
  },
  pageHeaderTitlePage: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: '10px',
  },
  label: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
    fontStyle: 'bold',
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 30,
    left: 0,
    right: 40,
    textAlign: 'right',
    // color: 'grey',
  },

  purchaseOrderDetails: {
    paddingTop: '20px',
    position: 'absolute',
    bottom: 70,
    left: 40,
    right: 40,
    paddingBottom: 4,
  },
  purchaseOrderDetailsCell: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
  },
  purchaseOrderDetailsCellBold: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
    fontStyle: 'bold',
    paddingBottom: 0,
    paddingTop: '3px',
    paddingRight: '5px',
  },
  purchaseOrderDetailsFull: {
    width: '98.5%',
    // flexDirection: 'row',
    marginLeft: 4,
    marginTop: 15,
  },
  purchaseOrderDetailsLeft: {
    width: '60%',
    // flexDirection: 'row',
  },
  purchaseOrderDetailsRight: {
    width: '40%',
    flexDirection: 'row',
  },
  purchaseOrderDetailsRow: {
    flexDirection: 'row',
  },

  supplierDetailsFont: {
    fontFamily: 'Segoe UI',
    fontSize: 11,
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '15%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '25%',
  },
  supplierDetailsRight: {
    width: '50%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
  },
  supplierDetailsValue: {
    width: '32%',
  },
  supplierDetailsValueAlt: {
    width: '75%',
  },

  block: {
    paddingTop: '5px',
    paddingBottom: '5px',
  },

  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '30%',
  },
  tableColBTW: {
    width: '20%',
    textAlign: 'center',
  },
  tableColQuantity: {
    width: '30%',
  },
  tableColUnit: {
    width: '10%',
  },
  tableColPrice: {
    width: '10%',
  },
  tableColRemarks: {
    marginTop: '10px',
    width: '65%',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '7px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '7px',
  },
  tableRow2: {
    borderBottomWidth: 1,
    borderColor: '#000000',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '10px',
    paddingLeft: '5px',
    paddingTop: '10px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  manager: {
    textAlign: 'center',
    fontSize: 11,
    fontStyle: 'bold',
    fontFamily: 'Segoe UI',
  },
});

export default LeadDownloadTemplate;
