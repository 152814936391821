import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useBoolean } from '@fluentui/react-hooks';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  ADD_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  IEmployee,
  displayNameEmployee,
  GET_EMPLOYEES,
} from '../../../utils/Employee';
import { CREATE_USER, ISalesRep } from '../../../utils';
import EmployeeDetail from '../../employees/components/EmployeeDetail';
import AppUserModal from '../../employees/components/AppUserModal';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import EmployeeDetailsList from '../../employees/components/EmployeesDetailsList';
import SalesRepsDetailsList from './SalesRepsDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  salesReps: ISalesRep[],
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void,
  isSortedAsc?: boolean,
  sortedField?: string,
  showLoadMore?: boolean,
}

const SalesRepsOverview = ({
  salesReps,
  // filter,
  loading,
  // filterChange = (filter: string) => {},
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: Props) => {
  const dispatch = useAppDispatch();
  const [salesRep, setSalesRep] = useState<IEmployee | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addSalesRep] = useMutation(ADD_EMPLOYEE, {
    onError: error => {
      dispatch(dismissNotification());

      dispatch(
        throwError({
          module: 'executionList.saveSalesRep',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // refetchQueries: [{ query: GET_EMPLOYEES }],
  });

  const [modifySalesRep] = useMutation(UPDATE_EMPLOYEE, {
    onError: error => {
      dispatch(dismissNotification());
      dispatch(
        throwError({
          module: 'executionList.saveSalesRep',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    IEmployee | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const { refetch: refetchSalesRep } = useQuery(GET_EMPLOYEE_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setSalesRep(data.findOneEmployee);
    },
  });

  const openSalesRepDetail = (newSalesRep?: boolean) => {
    if (newSalesRep) {
      setSalesRep(undefined);
    }
    setIsPanelOpen(true);
  };

  // App user modal
  const [modalUser, setModalUser] = useState<IEmployee | undefined>(undefined);
  const [hideModal, { toggle: toggleHideModal }] = useBoolean(true);

  const [createUser, { data: newUserData, loading: newUserLoading }] =
    useMutation(CREATE_USER, {
      onCompleted() {
        refetchSalesRep();
      },
    });

  const openNewUserModal = (salesRep: IEmployee) => {
    setModalUser(salesRep);
    toggleHideModal();
  };

  // Save salesRep
  const saveSalesRep = () => {
    if (salesRep) {
      const allInput = {
        first_name: salesRep.first_name,
        last_name: salesRep.last_name,
        address: salesRep.address,
        city: salesRep.city,
        zip_code: salesRep.zip_code,
        email: salesRep.email,
        active: salesRep.id ? salesRep.active : true,
        function: salesRep.function,
        mobile_v2: salesRep.mobile_v2,
        private_mobile_v2: salesRep.private_mobile_v2,
        phone_v2: salesRep.phone_v2,
        birthday: salesRep.birthday,
        iban: salesRep.iban,
        comments: salesRep.comments,
        diploma: salesRep.diploma,
        start_date: salesRep.start_date,
        end_date: salesRep.end_date,
        hourly_rate: salesRep.hourly_rate,
        hourly_rate_for_calculation: salesRep.hourly_rate_for_calculation
          ? +salesRep.hourly_rate_for_calculation
          : 0,
        monthly_rate: salesRep.monthly_rate || 0,
        driver: salesRep.driver,
        customer_manager: salesRep.customer_manager,
        construction_site_manager: salesRep.construction_site_manager,
        passport_no: salesRep.passport_no,
        sis_no: salesRep.sis_no,
        employment_method: salesRep.employment_method,
        category: salesRep.category,
        name_partner: salesRep.name_partner,
        mobile_partner: salesRep.mobile_partner,
        birthday_partner: salesRep.birthday_partner,
        children: salesRep.children ? +salesRep.children : null,
        jacket_size: salesRep.jacket_size,
        pants_size: salesRep.pants_size,
        shirt_size: salesRep.shirt_size,
        shoe_size: salesRep.shoe_size,
        sweater_size: salesRep.sweater_size,
        sales_rep: salesRep.sales_rep,
        epb_reporter: salesRep.epb_reporter,
        safety_coordinator: salesRep.safety_coordinator,
      };

      if (salesRep.id) {
        modifySalesRep({
          variables: {
            id: salesRep.id,
            data: allInput,
          },
        }).then(() => {
          setIsPanelOpen(false);
        });
      } else {
        addSalesRep({
          variables: {
            data: allInput,
          },
        }).then((x: any) => {
          if (x && x.data && x.data.createEmployee) setSalesRep(x.data.createEmployee);
        });
      }
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${salesReps.length} / ${salesReps.length > 0 ? salesReps[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openSalesRepDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openSalesRepDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  useEffect(() => {
    if (salesRep && isPanelOpen) {
      document.title = `3bouw | Verkoper - ${displayNameEmployee(salesRep)}`;
    } else {
      document.title = '3bouw | Verkopers';
    }
  }, [salesRep, isPanelOpen]);

  return (
    salesReps && (
      <>
        <AppUserModal
          hideModal={hideModal}
          toggleHideModal={toggleHideModal}
          modalUser={modalUser}
          newUserLoading={newUserLoading}
          newUserData={newUserData}
          createUser={createUser}
        />

        <CommandBarSticky
          items={commandBaritems}
          theme={commandBarTheme}
          width='1200px'
          maxWidth='1200px'
        />

        <EmployeeDetail
          isOpen={isPanelOpen}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          employee={salesRep || {}}
          saveEmployee={saveSalesRep}
          setEmployee={setSalesRep}
          refetchEmployee={refetchSalesRep}
          openNewUserModal={openNewUserModal}
        />

        <SalesRepsDetailsList
          items={salesReps}
          selection={selection}
          enableShimmer={!loading}
          isSortedAsc={isSortedAsc}
          sortedField={sortedField}
          setSorting={setSorting}
          loadMore={loadMore}
          lazyLoading={lazyLoading}
          loadMoreCallback={loadMoreCallback}
          loading={loading}
          onItemInvoked={() => openSalesRepDetail(false)}
        />

        {!loading && !showLoadMore && salesReps.length === 0 && (
          <div
            style={{
              textAlign: 'center',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            Geen resultaten
          </div>
        )}
        {showLoadMore && (
          <Stack
            style={{
              marginTop: '15px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            horizontal
            horizontalAlign='center'
          >
            <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
          </Stack>
        )}
        <Stack style={{ minHeight: '50px' }} />
      </>
    )
  );
};

export default SalesRepsOverview;
