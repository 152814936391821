import React, {
 FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Dropdown,
  IDropdownOption,
  Stack,
  TextField,
  Toggle,
} from '@fluentui/react';
import moment from 'moment';
import {
  default_page_size,
  normalizeString,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_LEADS_WITHOUT_REFERENCES, ILead } from '../../utils/Lead';
import useDebounce from '../../components/hooks/useDebounce';
import { useAppDispatch } from '../../redux/hooks';
import { sendNotification } from '../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { FilterState } from '../../components/parts/FilterPanel';
import LeadsOverview from './components/LeadsOverview';

interface IPagedLead {
  leads: ILead[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const validateEmail = (mail: string) => {
  if (mail.includes('@')) {
    return true;
  }
  return false;
};

const isNumeric = (value: string) => {
  // remove "+" sign from value
  let newValue = value;
  if (newValue.startsWith('+')) {
    newValue = newValue.substring(1);
  }

  console.log(!Number.isNaN(parseInt(newValue, 10)));
  return !Number.isNaN(parseInt(newValue, 10));
};

const Leads = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedLead>({
    leads: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'last_name1',
  });

  const [parsedFilter, setParsedFilter] = useState<any>();
  const [filter, setFilter] = useState('');
  const [leadFilter, setLeadFilter] = useState<FilterState | undefined>();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [salesRepFilter, setSalesRepFilter] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();
  const [dateFilter, setDateFilter] = useState({
    start_date: moment().subtract(1, 'year').toISOString(),
    end_date: '',
  });

  const debouncedValue = useDebounce(filter.replace('-', ''), 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, leads: [], skip: 0 }));
  }, [dateFilter, filter, salesRepFilter, status, leadFilter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const debouncedDate = useDebounce(dateFilter, 500);

  const getFilters = useCallback(
    (
      filterString: string,
      dateFilter: { end_date: string; start_date: string },
      leadFilter: FilterState | undefined,
    ) => {
      const filterArray = filterString.split(' ');

      const filters: any = {
        AND: [],
        /* NOT: {
          only_invoice: true,
        }, */
      };

      if (leadFilter && leadFilter.schema.length > 0) {
        for (let i = 0; i < leadFilter.schema.length; i++) {
          for (let t = 0; t < leadFilter.schema[i].fields.length; t++) {
            if (
              leadFilter.schema[i].fields[t].value &&
              leadFilter.schema[i].fields[t].parsedValue &&
              leadFilter.schema[i].fields[t].value !== ''
            ) {
              filters.AND.push(leadFilter.schema[i].fields[t].parsedValue);
            }
          }
        }
      }

      if (filterString.length > 0 && filterString) {
        for (let i = 0; i < filterArray.length; i++) {
          const filterValue: any = {
            OR: [],
          };

          const emailValid = validateEmail(filterArray[i]);
          const isPhone =
            isNumeric(filterArray[i]) && filterArray[i].length > 4;
          if (emailValid) {
            filterValue.OR = [
              { email1: { contains: filterArray[i] } },
              { email2: { contains: filterArray[i] } },
            ];
          } else if (isPhone) {
            filterValue.OR = [
              { phone1: { contains: filterArray[i] } },
              { phone1_v2: { contains: filterArray[i] } },
              { phone1search: { contains: filterArray[i] } },
              { phone2: { contains: filterArray[i] } },
              { phone2_v2: { contains: filterArray[i] } },
              { phone2search: { contains: filterArray[i] } },
              { mobile1: { contains: filterArray[i] } },
              { mobile1_v2: { contains: filterArray[i] } },
              { mobile1search: { contains: filterArray[i] } },
              { mobile2: { contains: filterArray[i] } },
              { mobile2_v2: { contains: filterArray[i] } },
              { mobile2search: { contains: filterArray[i] } },
            ];
          } else if (isNumeric(filterArray[i])) {
            const numericValue = parseInt(filterArray[i], 10);
            filterValue.OR = [
              { zip_code1: numericValue },
              { address1: { contains: filterArray[i].toString() } },
            ];
          } else {
            filterValue.OR = [
              { first_name1: { contains: filterArray[i] } },
              { first_name2: { contains: filterArray[i] } },
              { last_name1: { contains: filterArray[i] } },
              { last_name2: { contains: filterArray[i] } },
              { normalized_first_name1: { contains: filterArray[i] } },
              { normalized_first_name2: { contains: filterArray[i] } },
              { normalized_last_name1: { contains: filterArray[i] } },
              { normalized_last_name2: { contains: filterArray[i] } },

              { normalized_first_name1: { contains: normalizeString(filterArray[i]) } },
              { normalized_first_name2: { contains: normalizeString(filterArray[i]) } },
              { normalized_last_name1: { contains: normalizeString(filterArray[i]) } },
              { normalized_last_name2: { contains: normalizeString(filterArray[i]) } },
              { address1: { contains: filterArray[i] } },
              { city1: { contains: filterArray[i] } },
            ];
          }

          filters.AND.push(filterValue);
        }
      }

      if (salesRepFilter) {
        filters.AND.push({
          sales_rep_employee: {
            app_user: {
              auth0_id: {
                equals: salesRepFilter,
              },
            },
          },
        });
      }

      // if (dateFilter.start_date) {
      // filters.AND.push({
      // date_created: {
      // gte: moment(new Date(dateFilter.start_date)).toISOString(),
      // },
      // });
      // }

      // if (dateFilter.end_date) {
      // filters.AND.push({
      // date_created: {
      // lte: moment(new Date(dateFilter.end_date)).toISOString(),
      // },
      // });
      // }

      if (status && status !== 'ALL') {
        if (status === 'INVOICE_ONLY') {
          const filterValue: any = {
            OR: [
              {
                status: {
                  equals: status,
                },
              },
              {
                only_invoice: true,
              },
            ],
          };

          filters.AND.push(filterValue);
        } else {
          filters.AND.push({
            status: {
              equals: status,
            },
          });
        }
      }

      console.log(filters);
      return filters;
    },
    [salesRepFilter, status, leadFilter],
  );

  useEffect(() => {
    client.cache.reset();
    const filterObject = getFilters(debouncedValue, debouncedDate, leadFilter);
    setParsedFilter(filterObject);
  }, [salesRepFilter, status, leadFilter, debouncedDate, debouncedValue]);

  const downloadFile = useCallback(async () => {
    dispatch(
      sendNotification({
        message: 'bezig met downloaden',
        level: 0,
        module: 'template.updateTemplate',
        spinner: true,
      }),
    );
    const url = `${process.env.REACT_APP_BACKEND_URI}/files/leads/xls`;
    const token = await getAccessTokenSilently();
    const fileName = `leads_${moment().format('YYYYMMDD')}.xlsx`;
    const res = await fetch(url, {
      method: 'post',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        filter: parsedFilter,
      }),
    });

    const result = await res.blob();

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);

    dispatch(
      sendNotification({
        module: 'template.updateTemplate',
        message: 'Bestand is gedownload',
        level: 1,
        timeout: 2500,
      }),
    );
  }, [parsedFilter]);

  const variables = {
    filter: parsedFilter,
    take: pagedState.take,
    skip: pagedState.skip,
    orderBy: getOrderBy(),
  };

  const {
    data: dataLeads,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_LEADS_WITHOUT_REFERENCES, {
    // notifyOnNetworkStatusChange: true,
    variables,
    skip: !parsedFilter,
    onError: error => {
      dispatch(
        throwError({
          module: 'Lead Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const fetchMoreLeads = () => {
    // Show More guard

    fetchMore({
      variables: {
        filter: parsedFilter,
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Prospecten</h3>
      <Stack horizontal tokens={{ childrenGap: 15 }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
          placeholder='naam, adres, gemeente, email.'
        />
        <Toggle
          label='Alle prospecten / eigen prospecten'
          offText='Alle prospecten'
          onText='Eigen prospecten'
          checked={!!salesRepFilter}
          onChange={(e, checked) => {
            if (checked && user) {
              setSalesRepFilter(user.sub);
            } else {
              setSalesRepFilter(undefined);
            }
          }}
        />
        <Dropdown
          label='status'
          placeholder='Kies een status'
          selectedKey={status}
          options={[
            { key: 'ALL', text: 'Alles' },
            { key: 'ACTIVE', text: 'Actief' },
            { key: 'SOLD', text: 'Verkocht' },
            { key: 'NIHIL', text: 'Nihil' },
            { key: 'PASSIVE', text: 'Passief' },
            { key: 'PASSIVE_VISUAL_CONTACT', text: 'Passief visueel contact' },
            { key: 'INVOICE_ONLY', text: 'Factuur klant' },
          ]}
          onChange={(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption | undefined,
            // index?: number | undefined,
          ) => {
            setStatus(option ? (option.key as string) : undefined);
          }}
        />
      </Stack>

      <LeadsOverview
        leads={dataLeads ? dataLeads.findManyLeads : []}
        loading={loading && (!dataLeads || !dataLeads.findManyLeads)}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreLeads}
        downloadFile={downloadFile}
        lazyLoading={pagedState.lazyLoading}
        setLeadFilter={setLeadFilter}
        isFilterPanelOpen={isFilterPanelOpen}
        setIsFilterPanelOpen={setIsFilterPanelOpen}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Leads;
