import React from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
} from '@fluentui/react';
import {
  convertConstructionSitesToComboBoxOptions,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import { convertCostCentersToComboBoxOptions } from '../../../utils/CostCenter';
import { convertCarsToComboBoxOptions } from '../../../utils/Car';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';

const InvoiceLineDetail = ({
  isOpen,
  dismissPanel,
  saveInvoiceLine,
  invoiceLine,
  setInvoiceLine,
  costCenters,
  cars,
  setInvoiceLineFilter = (value: string) => {},
}: any) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setInvoiceLine((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        invoiceLine && invoiceLine.id
          ? 'Factuurlijn bekijken'
          : 'Factuurlijn toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {invoiceLine && invoiceLine.id && <Label>ID: {invoiceLine.id}</Label>}

          <Stack style={{ marginBottom: 10 }}>
            <Label>
              WingsID: {invoiceLine && invoiceLine.wings_lineid
                  ? invoiceLine.wings_lineid
                  : ''}
            </Label>
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={
                invoiceLine && invoiceLine.description
                  ? invoiceLine.description
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
              multiline
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='werf'
              label='Werf'
              type='text'
              value={
                invoiceLine && invoiceLine.construction_site
                  ? invoiceLine.construction_site.name
                  : ''
              }
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='post'
              label='Post'
              type='text'
              value={
                invoiceLine && invoiceLine.cost_center
                  ? invoiceLine.cost_center.name
                  : ''
              }
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='auto'
              label='Auto'
              type='text'
              value={
                invoiceLine && invoiceLine.car
                  ? invoiceLine.car.license_plate
                  : ''
              }
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='amount'
              label='Bedrag excl. BTW'
              prefix='€'
              value={
                invoiceLine && invoiceLine.amount ? invoiceLine.amount : ''
              }
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='amount_vat'
              label='BTW %'
              prefix='%'
              value={
                invoiceLine && invoiceLine.amount_vat
                  ? invoiceLine.amount_vat
                  : ''
              }
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='comments'
              label='Extra informatie'
              value={
                invoiceLine && invoiceLine.comments ? invoiceLine.comments : ''
              }
              multiline
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default InvoiceLineDetail;
