import React, { useCallback, useEffect, useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  Checkbox,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  CommandBar,
  DetailsRow,
  IDetailsFooterProps,
  DetailsRowCheck,
  IDetailsRowBaseProps,
  getDetailsRowCheckStyles,
  IComboBoxOption,
} from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  displayNameEmployee,
  IEmployee,
  modalContentStyles,
} from '../../../utils';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme } from '../../../theme';
import {
  ADD_CAR_DRIVER,
  GET_CAR_DRIVER_BY_ID,
  ICarDriver,
  UPDATE_CAR_DRIVER,
} from '../../../utils/CarDriver';
import { useAppDispatch } from '../../../redux/hooks';
import { ComboboxWithFilter } from '../../../components/parts';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastError, toastSuccess } from '../../../utils/toast';
import CarDriverDetail from './DriverDetail';

interface Props {
  isOpen: any;
  dismissPanel: any;
  saveCar: any;
  car: any;
  setCar: any;
  refetchCar: any;
  employees: IEmployee[];
}

const CarDetail = ({
  isOpen,
  dismissPanel,
  saveCar,
  car,
  setCar,
  refetchCar,
  employees,
}: Props) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [invoiceYearFilter, setInvoiceYearFilter] = useState<string>('');
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    if (car && car.incoming_invoice_line_items) {
      const tempYears = [];
      for (let i = 0; i < car.incoming_invoice_line_items.length; i++) {
        if (
          car.incoming_invoice_line_items[i].incoming_invoice &&
          car.incoming_invoice_line_items[i].incoming_invoice.invoice_date
        ) {
          tempYears.push(
            moment(
              car.incoming_invoice_line_items[i].incoming_invoice.invoice_date,
            )
              .year()
              .toString(),
          );
        }
      }

      setYears([...new Set(tempYears)]);
    }
  }, [car]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCar((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [isCarDriverPanelOpen, setIsCarDriverPanelOpen] = useState(false);
  const [carDriver, setCarDriver] = useState<ICarDriver | undefined>(undefined);

  const [addCarDriver] = useMutation(ADD_CAR_DRIVER);

  const [modifyCarDriver] = useMutation(UPDATE_CAR_DRIVER);

  // Selection
  const [selectedCarDriverID, setSelectedCarDriverID] = useState<
    number | undefined
  >();

  useEffect(() => {
    if (car.car_driver && car.car_driver.length === 1) {
      setSelectedCarDriverID(car.car_driver[0].id);
    }
  }, [car]);

  const getSelectionDetails = () => {
    const currentSelection: any = driverSelection.getSelection();

    if (currentSelection.length > 0) {
      setSelectedCarDriverID(currentSelection[0].id);
    } else if (car.car_driver && car.car_driver.length === 1) {
      setSelectedCarDriverID(car.car_driver[0].id);
    } else {
      setSelectedCarDriverID(undefined);
    }
  };

  const driverSelection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCarDriverDetail = (newCarDriver?: boolean) => {
    if (newCarDriver) {
      setCarDriver(undefined);
      setSelectedCarDriverID(undefined);
    }
    setIsCarDriverPanelOpen(true);
  };

  useQuery(GET_CAR_DRIVER_BY_ID, {
    variables: selectedCarDriverID
      ? { where: { id: selectedCarDriverID } }
      : undefined,
    skip: !selectedCarDriverID,
    onCompleted: data => {
      setCarDriver(data.findOneCarDriver);
    },
  });

  const saveCarDriver = async () => {
    try {
      if (carDriver) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              start_date: carDriver.start_date,
              end_date: carDriver.end_date,
              current_driver: !!carDriver.current_driver,
              employee: carDriver.employee && {
                connect: { id: carDriver.employee.id },
              },
              car: {
                connect: {
                  id: car.id,
                },
              },
            };

            if (carDriver.id) {
              modifyCarDriver({
                variables: {
                  id: carDriver.id,
                  data: allInput,
                },
                onError: (error: any) => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  setIsCarDriverPanelOpen(false);
                  await refetchCar();
                  resolve(x);
                  toastSuccess('Bestuurder gewijzigd');
                },
              });
            } else {
              addCarDriver({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: async (x: any) => {
                  setIsCarDriverPanelOpen(false);
                  await refetchCar();
                  resolve(x);
                  toastSuccess('Bestuurder gewijzigd');
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const isComplete = useCallback(() => {
    if (!car) return false;
    if (!car.name) return false;
    if (!car.license_plate) return false;
    return true;
  }, [car]);

  const onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (
    props,
  ): JSX.Element => (
    <DetailsRowCheck {...props} styles={getDetailsRowCheckStyles} selected />
  );

  const onRenderDetailsFooter = (
    detailsFooterProps?: IDetailsFooterProps,
  ): JSX.Element => {
    if (!detailsFooterProps) return <>nothing</>;
    const item: any = {};
    if (car && car.incoming_invoice_line_items) {
      const invoiceLineItems = _.cloneDeep(
        car.incoming_invoice_line_items.filter((lineItem: any) => {
          if (invoiceYearFilter) {
            return (
              lineItem.incoming_invoice &&
              lineItem.incoming_invoice.invoice_date &&
              moment(lineItem.incoming_invoice.invoice_date)
                .year()
                .toString() === invoiceYearFilter
            );
          }
          return true;
        }),
      );
      let totalAmount = 0;
      for (let i = 0; i < invoiceLineItems.length; i++) {
        totalAmount += invoiceLineItems[i].amount || 0;
      }
      item.amount = totalAmount.toFixed(2);
      item.description = 'Totaal';
    }
    return (
      <DetailsRow
        {...detailsFooterProps}
        columns={detailsFooterProps.columns}
        item={item}
        itemIndex={
          car.incoming_invoice_line_items
            ? car.incoming_invoice_line_items.length
            : 1
        }
        selectionMode={SelectionMode.none}
        onRenderCheck={onRenderCheckForFooterRow}
        styles={{
          root: {
            fontWeight: 'bold',
            backgroundColor: 'rgb(220 220 220)',
          },
        }}
      />
    );
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={car && car.id ? 'Auto wijzigen' : 'Auto toevoegen'}
      type={PanelType.custom}
      customWidth='900px'
    >
      {isCarDriverPanelOpen && (
        <CarDriverDetail
          isOpen={isCarDriverPanelOpen}
          dismissPanel={() => {
            setIsCarDriverPanelOpen(false);
          }}
          carDriver={carDriver}
          saveCarDriver={saveCarDriver}
          setCarDriver={setCarDriver}
          refetchCar={refetchCar}
          employees={employees}
        />
      )}
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {car && <Label>ID: {car.id}</Label>}

          <Stack
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Algemene info</h3>

              <Stack
                style={{
                  marginBottom: 15,
                }}
              >
                <TextField
                  name='name'
                  label='Naam'
                  value={car && car.name ? car.name : ''}
                  onChange={onChangeTextFieldValue}
                  required
                  errorMessage={car && !car.name ? 'Dit veld is verplicht' : ''}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                <Checkbox
                  name='active'
                  label='In gebruik?'
                  checked={car && car.active}
                  defaultChecked={car && car.active}
                  onChange={() => {
                    setCar((prevState: any) => ({
                      ...prevState,
                      active: car.active ? !car.active : true,
                    }));
                  }}
                />
              </Stack>

              <Stack style={{ marginBottom: 10 }}>
                {car && car.car_driver && (
                  <Stack style={{ marginBottom: 10 }}>
                    <Label>Bestuurder:</Label>{' '}
                    {car.car_driver.map((car_driver: any) => {
                      if (car_driver.current_driver) {
                        return (
                          <span>{`${car_driver.employee.last_name} ${car_driver.employee.first_name}`}</span>
                        );
                      }
                    })}
                  </Stack>
                )}
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='license_plate'
                  label='Nummerplaat'
                  value={car && car.license_plate ? car.license_plate : ''}
                  onChange={onChangeTextFieldValue}
                  required
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='chassis_number'
                  label='Chassisnummer'
                  value={car && car.chassis_number ? car.chassis_number : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_type'
                  label='Type'
                  value={car && car.car_type ? car.car_type : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='fuel_type'
                  label='Brandstof'
                  value={car && car.fuel_type ? car.fuel_type : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.purchase_date}
                  label='Datum aankoop'
                  field='purchase_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.lease_start_date}
                  label='Datum start lease'
                  field='lease_start_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.first_use_date}
                  label='Datum ingebruikname'
                  field='first_use_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.traffic_tax_date}
                  label='Datum BIV'
                  field='traffic_tax_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.lease_stop_date}
                  label='Datum einde lease'
                  field='lease_stop_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <DateView
                  item={car}
                  setItem={setCar}
                  date={car && car.sold_date}
                  label='Datum verkoop'
                  field='sold_date'
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_comments'
                  multiline
                  label="Nota's"
                  value={car && car.car_comments ? car.car_comments : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </StackItem>
            <StackItem style={{ width: '48%' }}>
              <h3 style={{ marginTop: 0, marginBottom: 10 }}>Tankkaart</h3>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='tank_card_number'
                  label='Nummer'
                  value={
                    car && car.tank_card_number ? car.tank_card_number : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='tank_card_number2'
                  label='Extra nummer'
                  value={
                    car && car.tank_card_number2 ? car.tank_card_number2 : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='tank_card_code'
                  label='Code'
                  value={car && car.tank_card_code ? car.tank_card_code : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='tank_card_comments'
                  multiline
                  label="Nota's"
                  value={
                    car && car.tank_card_comments ? car.tank_card_comments : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <h3 style={{ marginTop: 40, marginBottom: 10 }}>Telefoon</h3>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_phone'
                  label='Telefoon'
                  value={car && car.car_phone ? car.car_phone : ''}
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_phone_simcard_number'
                  label='SIM-kaartnummer'
                  value={
                    car && car.car_phone_simcard_number
                      ? car.car_phone_simcard_number
                      : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_phone_pin_code'
                  label='PIN Code'
                  value={
                    car && car.car_phone_pin_code ? car.car_phone_pin_code : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_phone_puk_code'
                  label='PUK Code'
                  value={
                    car && car.car_phone_puk_code ? car.car_phone_puk_code : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>

              <Stack style={{ marginBottom: 5 }}>
                <TextField
                  name='car_phone_comments'
                  multiline
                  label="Nota's"
                  value={
                    car && car.car_phone_comments ? car.car_phone_comments : ''
                  }
                  onChange={onChangeTextFieldValue}
                />
              </Stack>
            </StackItem>
          </Stack>

          <Stack style={{ marginTop: 30, marginBottom: 10 }}>
            {car && car.id ? (
              <Accordion
                selectedKey={selectedValueItem}
                defaultKey={undefined}
                toggleItem={(key: string | number) => {
                  if (selectedValueItem === key) {
                    setSelectedValueItem(undefined);
                  } else setSelectedValueItem(key);
                }}
              >
                {car &&
                  car.id && ( // only show drivers when car is created
                    <AccordionItem
                      key='accordion-car-driver'
                      id='accordion-car-driver'
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Bestuurder
                          </h3>
                        </Stack>
                      }
                    >
                      <CommandBar
                        items={[
                          {
                            key: 'new',
                            text: 'Nieuw',
                            iconProps: { iconName: 'Add' },
                            onClick: () => openCarDriverDetail(true),
                            theme: commandBarTheme,
                          },
                          {
                            key: 'modify',
                            text: 'Wijzig',
                            iconProps: { iconName: 'Edit' },
                            onClick: () => openCarDriverDetail(),
                            theme: commandBarTheme,
                            disabled: !selectedCarDriverID,
                          },
                        ]}
                        theme={commandBarTheme}
                      />
                      <DetailsList
                        items={
                          car.car_driver
                            ? _.cloneDeep(car.car_driver).sort(
                                (a: any, b: any) =>
                                  moment(b.start_date).unix() -
                                  moment(a.start_date).unix(),
                              )
                            : []
                        }
                        columns={[
                          {
                            key: 'column1',
                            name: 'Bestuurder',
                            fieldName: 'employee',
                            minWidth: 150,
                            maxWidth: 150,
                            isRowHeader: true,
                            onRender: (driver: any) => (
                              <span>
                                {displayNameEmployee(driver.employee)}
                              </span>
                            ),
                            data: 'string',
                            isPadded: true,
                          },
                          {
                            key: 'column2',
                            name: 'Startdatum',
                            fieldName: 'date',
                            minWidth: 80,
                            maxWidth: 80,
                            isRowHeader: true,
                            onRender: (driver: any) => (
                              <span>
                                {driver.start_date &&
                                  moment(new Date(driver.start_date)).format(
                                    'DD/MM/YYYY',
                                  )}
                              </span>
                            ),
                            data: 'string',
                            isPadded: true,
                          },
                          {
                            key: 'column3',
                            name: 'Einddatum',
                            fieldName: 'date',
                            minWidth: 80,
                            maxWidth: 80,
                            isRowHeader: true,
                            onRender: (driver: any) => (
                              <span>
                                {driver.end_date &&
                                  moment(new Date(driver.end_date)).format(
                                    'DD/MM/YYYY',
                                  )}
                              </span>
                            ),
                            data: 'string',
                            isPadded: true,
                          },
                          {
                            key: 'column4',
                            name: 'Huidige bestuurder',
                            fieldName: 'current_driver',
                            minWidth: 80,
                            maxWidth: 80,
                            isRowHeader: true,
                            onRender: (driver: any) => (
                              <span>
                                <Checkbox
                                  disabled
                                  checked={!!driver.current_driver}
                                />
                              </span>
                            ),
                            data: 'string',
                            isPadded: true,
                          },
                        ]}
                        // getKey={item => item.id}
                        // layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible
                        selection={driverSelection}
                        selectionMode={SelectionMode.single}
                        setKey='carDriverOverview'
                      />
                    </AccordionItem>
                  )}
                <AccordionItem
                  key='accordion-incoming-invoice-line-items'
                  id='accordion-incoming-invoice-line-items'
                  title={
                    <Stack>
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>Kosten</h3>
                    </Stack>
                  }
                >
                  <Stack style={{ maxWidth: 200, marginBottom: 20 }}>
                    <ComboboxWithFilter
                      label='Jaar'
                      options={
                        years && years.length > 0
                          ? years.map(x => ({ key: x, text: x }))
                          : []
                      }
                      value={invoiceYearFilter}
                      multiline={false}
                      callBack={(newValue: IComboBoxOption[]) => {
                        if (newValue && newValue.length > 0) {
                          setInvoiceYearFilter(newValue[0].key as string);
                        }
                      }}
                    />
                  </Stack>
                  <DetailsList
                    items={
                      car.incoming_invoice_line_items
                        ? car.incoming_invoice_line_items
                            .filter((lineItem: any) => {
                              if (invoiceYearFilter) {
                                return lineItem.incoming_invoice &&
                                  lineItem.incoming_invoice.invoice_date
                                  ? moment(
                                      lineItem.incoming_invoice.invoice_date,
                                    )
                                      .year()
                                      .toString() === invoiceYearFilter
                                  : false;
                              }
                              return true;
                            })
                            .sort(
                              (a: any, b: any) =>
                                b.incoming_invoice.invoice_date &&
                                a.incoming_invoice.invoice_date &&
                                moment(b.incoming_invoice.invoice_date).unix() -
                                  moment(
                                    a.incoming_invoice.invoice_date,
                                  ).unix(),
                            )
                        : []
                    }
                    columns={[
                      {
                        key: 'column1',
                        name: 'Wings ID',
                        fieldName: 'internal_invoice_no',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (incoming_invoice_line_item: any) => (
                          <span>
                            {incoming_invoice_line_item.incoming_invoice &&
                              incoming_invoice_line_item.incoming_invoice
                                .internal_invoice_no &&
                              incoming_invoice_line_item.incoming_invoice
                                .internal_invoice_no}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column2',
                        name: 'Datum',
                        fieldName: 'date',
                        minWidth: 70,
                        maxWidth: 70,
                        isRowHeader: true,
                        onRender: (incoming_invoice_line_item: any) => (
                          <span>
                            {incoming_invoice_line_item.incoming_invoice &&
                              incoming_invoice_line_item.incoming_invoice
                                .invoice_date &&
                              moment(
                                new Date(
                                  incoming_invoice_line_item.incoming_invoice.invoice_date,
                                ),
                              ).format('DD/MM/YYYY')}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column3',
                        name: 'Leverancier',
                        fieldName: 'supplier',
                        minWidth: 120,
                        maxWidth: 120,
                        isRowHeader: true,
                        onRender: (incoming_invoice_line_item: any) => (
                          <span>
                            {incoming_invoice_line_item.incoming_invoice &&
                              incoming_invoice_line_item.incoming_invoice
                                .supplier &&
                              incoming_invoice_line_item.incoming_invoice
                                .supplier.name}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column4',
                        name: 'Omschrijving',
                        fieldName: 'comments',
                        minWidth: 180,
                        maxWidth: 180,
                        isRowHeader: true,
                        onRender: (incoming_invoice_line_item: any) => (
                          <span>{incoming_invoice_line_item.description}</span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                      {
                        key: 'column5',
                        name: 'Bedrag excl btw',
                        fieldName: 'amount',
                        minWidth: 65,
                        maxWidth: 65,
                        isRowHeader: true,
                        onRender: (incoming_invoice_line_item: any) => (
                          <span>
                            &euro;&nbsp;
                            {incoming_invoice_line_item.amount}
                          </span>
                        ),
                        data: 'string',
                        isPadded: true,
                      },
                    ]}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible
                    selectionMode={SelectionMode.none}
                    onRenderDetailsFooter={onRenderDetailsFooter}
                  />
                </AccordionItem>
              </Accordion>
            ) : (
              <>
                <Label>
                  Na opslaan kan je de bestuurder en kosten aanpassen.
                </Label>
                <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                  <Accordion
                    selectedKey={undefined}
                    defaultKey={undefined}
                    toggleItem={() => {
                      setSelectedValueItem(undefined);
                    }}
                  >
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Bestuurder
                          </h3>
                        </Stack>
                      }
                    />
                    <AccordionItem
                      key=''
                      id=''
                      title={
                        <Stack>
                          <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                            Kosten
                          </h3>
                        </Stack>
                      }
                    />
                  </Accordion>
                </Stack>
              </>
            )}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={!isComplete()}
              save={saveCar}
              permission='write:cars'
            />
            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CarDetail;
