/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import {
  ICommandBarItemProps,
  IconButton,
  PrimaryButton,
  Selection,
  Stack,
} from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router';
import { GET_CUSTOMER_PAYMENT_TRANSACTIONS, ICustomerPaymentTransaction } from '../../../utils/CustomerPaymentTransaction';
import {
  cancelIcon,
  GET_CONSTRUCTION_SITES,
  iconButtonStyles,
} from '../../../utils';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import useDebounce from '../../../components/hooks/useDebounce';
import PaymentTransactionDetail from './CustomerPaymentTransactionDetail';
import PaymentTransactionInvoiceDetail from './PaymentTransactionInvoiceDetail';
import CustomerPaymentTransactionDownloadTemplate from './PaymentDownloadTemplate';
import CustomerPaymentTransactionsDetailsList from './CustomerPaymentTransactionsDetailsList';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  customerPaymentTransactions: ICustomerPaymentTransaction[];
  // filter,
  // filterChange?: (filter: string) => void,
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
}

const CustomerPaymentTransactionsOverview = ({
  customerPaymentTransactions,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
}: Props) => {
  const [paymentTransaction, setPaymentTransaction] = useState<
    ICustomerPaymentTransaction | undefined
  >(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isInvoicePanelOpen, setIsInvoicePanelOpen] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);
  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');
  const [showPdf, setShowPdf] = useState(false);

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { id: paymentTransactionId } = useParams();

  const {
    data: customerPaymentTransactionsPDF,
    loading: customerPaymentTransactionsPDFLoading,
  } = useQuery(GET_CUSTOMER_PAYMENT_TRANSACTIONS, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        customer_invoices: {
          none: {},
        },
      },
    },
    skip: !showPdf,
    fetchPolicy: 'no-cache',
  });

  useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    onCompleted: (x: any) => {
      setConstructionSites(x.findManyConstructionSites);
    },
  });

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setPaymentTransaction(currentSelection[0]);
    } else {
      setPaymentTransaction(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openPaymentTransactionDetail = (newPaymentTransaction?: boolean) => {
    if (newPaymentTransaction) {
      setPaymentTransaction({});
    }
    setIsPanelOpen(true);
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${customerPaymentTransactions.length} / ${customerPaymentTransactions.length > 0 ? customerPaymentTransactions[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openPaymentTransactionDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openPaymentTransactionDetail(),
      theme: commandBarTheme,
      disabled: !paymentTransaction || !paymentTransaction.id,
    },
    {
      key: 'modify',
      text: 'Factuur aanmaken',
      iconProps: { iconName: 'Pdf' },
      onClick: () => setIsInvoicePanelOpen(true),
      theme: commandBarTheme,
      disabled:
        !paymentTransaction ||
        !paymentTransaction.id ||
        (paymentTransaction.customer_invoices &&
          paymentTransaction.customer_invoices.length > 0),
    },
    {
      key: 'download',
      text: 'Openstaande betalingsschijven',
      iconProps: { iconName: 'Download' },
      onClick: () => setShowPdf(true),
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    if (paymentTransaction && isPanelOpen) {
      document.title = '3bouw | Betalingsschijf';
    } else {
      document.title = '3bouw | Betalingsschijven';
    }
  }, [paymentTransaction, isPanelOpen]);

  useEffect(() => {
    if (paymentTransactionId) {
      setIsPanelOpen(true);
    }
  }, []);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      <PaymentTransactionDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
          setPaymentTransaction(undefined);
        }}
        // <<<<<<< HEAD
        //         paymentTransaction={paymentTransaction || {}}
        //         savePaymentTransaction={savePaymentTransaction}
        //         setPaymentTransaction={setPaymentTransaction}
        //         constructionSites={constructionSites}
        //         setConstructionSiteFilter={(value: string) =>
        //           setConstructionSiteFilter(value)
        // =======
        customerPaymentTransactionSource={paymentTransaction || {}}
        paymentTransactionId={
          paymentTransactionId ? +paymentTransactionId : undefined
        }
      />

      <CustomerPaymentTransactionsDetailsList
        items={customerPaymentTransactions}
        selection={selection}
        paymentTransaction={paymentTransaction}
        enableShimmer={!loading}
        isSortedAsc={isSortedAsc}
        sortedField={sortedField}
        setSorting={setSorting}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
        onItemInvoked={() => openPaymentTransactionDetail(false)}
      />

      {paymentTransaction && isInvoicePanelOpen && (
        <PaymentTransactionInvoiceDetail
          isOpen={isInvoicePanelOpen}
          dismissPanel={() => {
            setIsInvoicePanelOpen(false);
          }}
          paymentTransaction={paymentTransaction}
          setPaymentTransaction={setPaymentTransaction}
        />
      )}

      {customerPaymentTransactions && (
        <>
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerPaymentTransactions.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showPdf && customerPaymentTransactionsPDF && customerPaymentTransactionsPDF.findManyCustomerPaymentTransactions && (
        <Stack
          styles={{
          root: {
            position: 'fixed',
            top: 60,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'white',
            marginTop: '0!important',
            display: 'flex',
            padding: '20px',
          },
        }}
        >
          <Stack
            horizontal
            horizontalAlign='space-between'
            styles={{ root: { marginBottom: 10 } }}
          >
            <Stack.Item>
              <h2 style={{ marginTop: 0 }}>PDF Bekijken</h2>
              {/* <Stack tokens={stackTokens5} horizontal>
                <Stack.Item>
                  <Stack horizontalAlign='end'> */}
              <PDFDownloadLink
                document={
                  <CustomerPaymentTransactionDownloadTemplate
                    customerPaymentTransactions={customerPaymentTransactionsPDF.findManyCustomerPaymentTransactions}
                  />
                }
                fileName='openstaande-betalingsschijven.pdf'
              >
                <PrimaryButton>Pdf downloaden</PrimaryButton>
              </PDFDownloadLink>
              {/* </Stack>
                </Stack.Item>
              </Stack> */}
            </Stack.Item>
            <Stack.Item>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel='Sluit venster'
                onClick={() => {
                  setShowPdf(false);
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <CustomerPaymentTransactionDownloadTemplate
                customerPaymentTransactions={customerPaymentTransactionsPDF.findManyCustomerPaymentTransactions}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};

export default CustomerPaymentTransactionsOverview;
