import { gql } from '@apollo/client';
import { IComboBoxOption } from '@fluentui/react';
import {
  IConstructionSite,
  IConstructionSiteCreateUpdate,
} from './ConstructionSite';
import { ICustomer } from './Customer';
import { ICustomerContractUpdate } from './CustomerContractUpdate';
import { ICustomerInvoiceLineItem } from './CustomerInvoiceLineItem';
import { ICustomerInvoicePayment } from './CustomerInvoicePayment';
import { ICustomerPaymentTransaction } from './CustomerPaymentTransaction';
import { ILead, ILeadCreateUpdate } from './Lead';
import { IWorkOrder } from './WorkOrder';

export const GET_CUSTOMER_INVOICES = gql`
  query getCustomerInvoices(
    $filter: CustomerInvoiceFilterInput
    $take: Int
    $skip: Int
    $orderBy: [CustomerInvoiceOrderByInput]
  ) {
    findManyCustomerInvoices(
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      prisma_total
      credit_note
      customer_id
      intro
      tax_description
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      sent
      outro
      version
      lead {
        id
        title1
        title2
        first_name1
        first_name2
        last_name1
        last_name2
        address1
        city1
        zip_code1
        phone1
        phone1_v2
        phone2
        phone2_v2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        email1
        vat
      }
      customer_invoice_line_items {
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
        tax_code {
          id
          label
          rate
          description
        }
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      customer_payment_transaction {
        id
        engineering_expense
      }
      construction_site {
        name
        id
        lead {
          id
          title1
          title2
          first_name1
          first_name2
          last_name1
          last_name2
          address1
          city1
          zip_code1
          phone1
          phone1_v2
          phone2
          phone2_v2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
          vat
        }
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE_BY_ID = gql`
  query getCustomerInvoiceById($where: CustomerInvoiceWhereInput) {
    findOneCustomerInvoice(where: $where) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      tax_description
      invoice_paid
      sent
      outro
      version
      lead {
        id
        title1
        title2
        first_name1
        first_name2
        last_name1
        last_name2
        address1
        city1
        zip_code1
        phone1
        phone1_v2
        phone2
        phone2_v2
        mobile1
        mobile1_v2
        mobile2
        mobile2_v2
        email1
        sales_rep_employee {
          first_name
          last_name
        }
        vat
      }
      customer_invoice_line_items {
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
        tax_code {
          id
          label
          rate
          description
        }
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      customer_payment_transaction {
        id
        engineering_expense
      }
      construction_site {
        name
        id
        project_manager {
          first_name
          last_name
        }
        lead {
          id
          title1
          first_name1
          first_name2
          title1
          last_name1
          last_name2
          address1
          city1
          zip_code1
          phone1
          phone1_v2
          phone2
          phone2_v2
          mobile1
          mobile1_v2
          mobile2
          mobile2_v2
          vat
        }
      }
      customer_invoice_contract_updates {
        id
        customer_invoice_id
        customer_contract_update_id
        contract_update {
          id
          customer_id
          customer_invoice_id
          document_date
          document_no
          intro
          outro
          status
          summary
          construction_site_id
          customer_contract_update_line_items {
            id
            comments
            customer_contract_update_id
            description
            percentage_vat
            show_only_total
            unit_price
            units
            tax_code {
              id
              label
              rate
              description
            }
          }
        }
      }
    }
  }
`;

export const ADD_CUSTOMER_INVOICE = gql`
  mutation AddCustomerInvoice($data: CustomerInvoiceCreateInput!) {
    createCustomerInvoice(data: $data) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      tax_description
      invoice_no
      invoice_paid
      outro
      version
      lead {
        id
        title1
        first_name1
        title2
        first_name2
        last_name1
        last_name2
        vat
      }
      customer_invoice_line_items {
        id
        comments
        customer_invoice_id
        description
        id
        percentage_vat
        show_only_total
        units
        unit_price
        tax_code {
          id
          label
          rate
          description
        }
        customer_contract_update_line_item {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
          tax_code {
            id
            label
            rate
            description
          }
        }
      }
      customer_invoice_payments {
        id
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      construction_site {
        name
        id
      }
      customer_contract_updates {
        id
        document_no
        document_date
        summary
      }
    }
  }
`;

export const UPDATE_CUSTOMER_INVOICE = gql`
  mutation UpdateCustomerInvoice(
    $id: Int!
    $data: CustomerInvoiceUpdateInput!
  ) {
    updateCustomerInvoice(id: $id, data: $data) {
      id
      credit_note
      customer_id
      intro
      invoice_date
      invoice_due_date
      invoice_no
      invoice_paid
      tax_description
      sent
      outro
      version
      lead {
        id
        title1
        title2
        first_name1
        first_name2
        last_name1
        last_name2
        zip_code1
        address1
        city1
        phone1
        phone2
        mobile1
        mobile2
        email1
        email2
        vat
      }
      customer_invoice_line_items {
        id
        comments
        customer_invoice_id
        description
        percentage_vat
        show_only_total
        units
        unit_price
        tax_code {
          id
          label
          rate
          description
        }
        customer_contract_update_line_item {
          id
          description
          units
          unit_price
          percentage_vat
          show_only_total
          tax_code {
            id
            label
            rate
            description
          }
        }
      }
      customer_invoice_payments {
        amount
        comments
        customer_invoice_id
        id
        payment_date
      }
      construction_site {
        name
        id
        site_manager {
          first_name
          last_name
          mobile
        }
      }
      customer_contract_updates {
        document_no
        document_date
        summary
      }
      customer_invoice_contract_updates {
        id
        customer_contract_update_id
        customer_invoice_id
        contract_update {
          id
          document_no
          document_date
          summary
          customer_contract_update_line_items {
            id
            description
            units
            unit_price
            percentage_vat
            show_only_total
            tax_code {
              id
              label
              rate
              description
            }
          }
        }
      }
    }
  }
`;

export interface ICustomerInvoiceContractUpdate {
  id?: number;
  customer_contract_update_id: number;
  contract_update?: ICustomerContractUpdate;
  customer_invoice_id?: number;
  invoice?: ICustomerInvoice;
  connect?: boolean;
  disconnect?: boolean;
}
export interface ICustomerInvoice {
  credit_note: boolean;
  customer_id: number;
  id: number;
  intro: string;
  invoice_date: Date;
  invoice_due_date: Date;
  invoice_no: string;
  invoice_paid: boolean;
  sent: boolean;
  outro: string;
  version: number;
  tax_description?: string;

  customer: ICustomer;
  customer_contract_updates: any[];
  customer_invoice_line_items: any[];
  customer_invoice_payments: any[];
  customer_payment_transactions_legacy: ICustomerPaymentTransaction[];
  customer_payment_transaction: ICustomerPaymentTransaction;
  customer_invoice_contract_updates: ICustomerInvoiceContractUpdate[];
  defect_work_orders: any[];
  construction_site: IConstructionSite;
  lead: ILead;
  prisma_total?: number;
}

export interface ICustomerInvoiceCreate {
  credit_note?: boolean;
  customer_id?: number;
  id?: number;
  intro?: string;
  invoice_date?: Date;
  invoice_due_date?: Date;
  invoice_no?: string;
  invoice_paid?: boolean;
  sent?: boolean;
  outro?: string;
  version?: number;
  tax_description?: string;

  customer?: ICustomer;
  customer_contract_updates?: ICustomerContractUpdate[];
  customer_invoice_line_items?: ICustomerInvoiceLineItem[];
  customer_invoice_payments?: ICustomerInvoicePayment[];
  customer_payment_transactions_legacy?: ICustomerPaymentTransaction[];
  customer_payment_transaction?: ICustomerPaymentTransaction;
  customer_invoice_contract_updates?: ICustomerInvoiceContractUpdate[];
  defect_work_orders?: IWorkOrder[];
  construction_site?: IConstructionSiteCreateUpdate;
  lead?: ILeadCreateUpdate;
}

export const convertCustomerInvoicesToComboBoxOptions = (
  customerInvoices: ICustomerInvoice[],
) => {
  if (customerInvoices) {
    const result: IComboBoxOption[] = customerInvoices.map(
      (customerInvoice: ICustomerInvoice): IComboBoxOption => ({
        key: customerInvoice.id,
        text: customerInvoice.invoice_no,
      }),
    );
    return result;
  }

  return [];
};
