import {
 DefaultButton, DetailsList, DetailsListLayoutMode, Icon, Panel, PanelType, SelectionMode, Stack, Toggle,
} from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { stackTokens15 } from '../../../utils';
import {
  GET_TIMESHEETS,
  GET_TIMESHEETS_BY_COST_CENTER,
  GET_TIMESHEETS_BY_CUSTOMER,
  GET_TIMESHEETS_BY_DAY,
  GET_TIMESHEETS_FOR_LEAVE,
  ITimesheetEntryWithType,
} from '../../../utils/Timesheet';
import { GET_GROUPED_TIMESHEET_ENTRIES, ITimesheetEntry } from '../../../utils/TimesheetEntry';
import NewTimesheetEmployee from './NewTimesheetEmployee';
import NewTimesheetLeave from './NewTimesheetLeave';
import NewTimesheetProject from './NewTimesheetProject';
import NewTimesheetCostCenter from './NewTimesheetCostCenter';
import NewTimesheetDay from './NewTimesheetDay';
import SuggestedViewStatus from './SuggestedViewStatus';
import DoubleEntryPanel from './DoubleEntryPanel';

function NewTimesheet() {
  const [timesheetVia, setTimesheetVia] = useState<
    'employee' | 'project' | 'cost-center' | 'day' | 'leave'
  >('employee');
  const [timesheet, setTimesheet] = useState<ITimesheetEntryWithType[]>([]);
  const [groupedTimesheetFilter, setGroupedTimesheetFilter] = useState<any>();
  const [groupedTimesheet, setGroupedTimesheet] = useState<any[]>([]);
  const [showPanelGroupedEntries, setShowPanelGroupedEntries] = useState(false);

  const [currentDate, setCurrentDate] = useState(moment().startOf('week'));
  // const now = moment().startOf('d');
  const [showWeekend, setShowWeekend] = useState(false);
  const [days, setDays] = useState<Moment[]>([]);

  const [duration, setDuration] = useState(4);

  useEffect(() => {
    const parsedDuration = duration < 4 ? duration : showWeekend ? duration + 2 : duration;
    const startMoment = parsedDuration > 0 ? moment(currentDate).startOf('week') : moment(currentDate);
    const newDays: Moment[] = [];
    for (let i = 0; i < parsedDuration + 1; i++) {
      newDays.push(moment(startMoment).add(i, 'd'));
    }
    setDays(newDays);
  }, [currentDate, duration, setDuration, showWeekend]);

  useEffect(() => {
    setGroupedTimesheet([]);
    setGroupedTimesheetFilter(undefined);
  }, [timesheetVia]);

  const [getTimesheetByEmployee, { loading }] = useLazyQuery(GET_TIMESHEETS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (x: any) => {
      setTimesheet(x.findTimesheetsByEmployee);
    },
    fetchPolicy: 'no-cache',
  });

  const [getTimesheetByCustomer] = useLazyQuery(GET_TIMESHEETS_BY_CUSTOMER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (x: any) => {
      setTimesheet(x.findTimesheetsByCustomer);
    },
    fetchPolicy: 'no-cache',
  });

  const [getTimesheetByCostCenter] = useLazyQuery(
    GET_TIMESHEETS_BY_COST_CENTER,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (x: any) => {
        setTimesheet(x.findTimesheetsByCostCenter);
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [getTimesheetByDayLeave] = useLazyQuery(
    GET_TIMESHEETS_FOR_LEAVE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (x: any) => {
        setTimesheet(x.findTimesheetsForLeave);
      },
      fetchPolicy: 'no-cache',
    },
  );

  // get comment of first "value" and set as comment on timesheet entry
  const parseTimesheets = (timesheets: ITimesheetEntryWithType[]) => {
    const newTimeSheets = _.cloneDeep(timesheets);

    for (let i = 0; i < newTimeSheets.length; i++) {
      const timesheet = newTimeSheets[i];
      if (timesheet.values && timesheet.values.length > 0) {
        timesheet.comments = timesheet.values[0].comments;
      }
    }

    return newTimeSheets;
  };

  const [getTimesheetForDay] = useLazyQuery(
    GET_TIMESHEETS_BY_DAY,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (x: any) => {
        setTimesheet(parseTimesheets(x.findTimesheetsByDay));
      },
      fetchPolicy: 'no-cache',
    },
  );

  const getTimeSheetEmployeeCount = (entries: ITimesheetEntryWithType[]) => {
    const employees: number[] = [];

    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.employee && entry.employee.id) {
        const index = employees.findIndex(item => item === entry.employee.id);
        if (index === -1) {
          employees.push(entry.employee.id);
        }
      }
    }

    return employees.length;
  };

  const { data: groupedTimesheetData } = useQuery(GET_GROUPED_TIMESHEET_ENTRIES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: groupedTimesheetFilter,
    },
    skip: !groupedTimesheetFilter,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (groupedTimesheetData) {
      setGroupedTimesheet(groupedTimesheetData.findManyGroupedTimesheetEntries);
    }
  }, [groupedTimesheetData]);

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0, paddingLeft: 10 }}>
        Werkuren ingeven via{' '}
        {timesheetVia === 'employee'
          ? 'medewerker'
          : timesheetVia === 'project'
          ? 'project'
          : timesheetVia === 'cost-center'
          ? 'post'
          : timesheetVia === 'day'
          ? 'dag' : 'afwezigheid'}
      </h3>

      <Stack horizontal horizontalAlign='space-between'>

        <Stack style={{ flexDirection: 'row', padding: 10 }}>

          <DefaultButton
            style={{ marginRight: 5 }}
            primary={timesheetVia === 'employee'}
            onClick={() => {
              setTimesheetVia('employee');
              setTimesheet([]);
              setDuration(4);
            }}
          >
            Ingeven via medewerker
          </DefaultButton>
          <DefaultButton
            primary={timesheetVia === 'project'}
            style={{ marginRight: 5 }}
            onClick={() => {
              setTimesheetVia('project');
              setTimesheet([]);
              setDuration(4);
            }}
          >
            Ingeven via project
          </DefaultButton>
          <DefaultButton
            primary={timesheetVia === 'cost-center'}
            style={{ marginRight: 5 }}
            onClick={() => {
              setTimesheetVia('cost-center');
              setTimesheet([]);
              setDuration(4);
            }}
          >
            Ingeven via post
          </DefaultButton>
          <DefaultButton
            primary={timesheetVia === 'day'}
            style={{ marginRight: 5 }}
            onClick={() => {
              setTimesheetVia('day');
              setTimesheet([]);
              setDuration(0);
            }}
          >
            Ingave per dag
          </DefaultButton>

          <DefaultButton
            primary={timesheetVia === 'leave'}
            style={{ marginRight: 5 }}
            onClick={() => {
              setTimesheetVia('leave');
              setTimesheet([]);
              setDuration(4);
            }}
          >
            Ingave afwezigheid
          </DefaultButton>
          {groupedTimesheet && groupedTimesheet.length > 0 && (
          <DefaultButton styles={{ root: { borderColor: 'red', color: 'red' } }} onClick={() => { setShowPanelGroupedEntries(true); }}>
            Dubbele ingaves {`(${groupedTimesheet.length})`}
          </DefaultButton>
        )}
        </Stack>
        <Stack>
          {/*
          <Toggle
            label="Toon weekend"
            onText="Aan"
            offText="Uit"
            checked={showWeekend}
            onChange={(e, checked) => {
              setShowWeekend(checked || false);
      }}
          />
    */}
        </Stack>
      </Stack>
      {timesheetVia === 'employee' ? (
        <NewTimesheetEmployee
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByEmployee}
          setGroupedTimesheetFilter={setGroupedTimesheetFilter}
          groupedFilter={groupedTimesheetFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          days={days}
          setDays={setDays}
          duration={duration < 4 ? duration : showWeekend ? duration + 2 : duration}
        />
      ) : timesheetVia === 'project' ? (
        <NewTimesheetProject
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByCustomer}
          setGroupedTimesheetFilter={setGroupedTimesheetFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          days={days}
          setDays={setDays}
          duration={duration < 4 ? duration : showWeekend ? duration + 2 : duration}
          employees={getTimeSheetEmployeeCount(timesheet)}
        />
      ) : timesheetVia === 'cost-center' ? (
        <NewTimesheetCostCenter
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByCostCenter}
          setGroupedTimesheetFilter={setGroupedTimesheetFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          days={days}
          setDays={setDays}
          duration={duration < 4 ? duration : showWeekend ? duration + 2 : duration}
          employees={getTimeSheetEmployeeCount(timesheet)}
        />
      ) : timesheetVia === 'day' ? (
        <NewTimesheetDay
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetForDay}
          setGroupedTimesheetFilter={setGroupedTimesheetFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          days={days}
          setDays={setDays}
          duration={duration < 4 ? duration : showWeekend ? duration + 2 : duration}
          employees={getTimeSheetEmployeeCount(timesheet)}
        />
      ) : timesheetVia === 'leave' ? (
        <NewTimesheetLeave
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          getTimesheet={getTimesheetByDayLeave}
          setGroupedTimesheetFilter={setGroupedTimesheetFilter}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          days={days}
          setDays={setDays}
          duration={duration < 4 ? duration : showWeekend ? duration + 2 : duration}
          employees={getTimeSheetEmployeeCount(timesheet)}
        />
      ) : null}
      {showPanelGroupedEntries && groupedTimesheet && groupedTimesheet.length > 0 && (
        <DoubleEntryPanel
          setShowPanel={setShowPanelGroupedEntries}
          groupedTimesheet={groupedTimesheet}
          filter={groupedTimesheetFilter}
        />
      )}

    </Stack>
  );
}

export default NewTimesheet;
