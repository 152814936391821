/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  Checkbox,
  TextField,
} from '@fluentui/react';
import _ from 'lodash';
import { modalContentStyles } from '../../../utils';
import { ICustomerInvoiceLineItem } from '../../../utils/CustomerInvoiceLineItem';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import {
  ITaxCode,
  convertTaxCodesToComboBoxOptions,
} from '../../../utils/TaxCode';
import Combobox from '../../../components/parts/ComboBox';

type Props = {
  isOpen: boolean;
  dismissPanel: () => void;
  saveInvoiceLineItem: () => void;
  invoiceLine: ICustomerInvoiceLineItem;
  setInvoiceLine: React.Dispatch<
    React.SetStateAction<ICustomerInvoiceLineItem | undefined>
  >;
  taxCodes: ITaxCode[];
};

const InvoiceLineDetail = ({
  isOpen,
  dismissPanel,
  saveInvoiceLine,
  invoiceLine,
  setInvoiceLine,
  taxCodes,
}: any) => {
  useEffect(() => {
    if (invoiceLine?.id === undefined) {
      setInvoiceLine((prevState: any) => ({
        ...prevState,
        isDirty: true,
        show_only_total: true,
      }));
    }
  }, [invoiceLine?.id, isOpen]);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setInvoiceLine((prevState: any) => ({
      ...prevState,
      isDirty: true,
      [(event.target as HTMLTextAreaElement).name]: newValue,
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        invoiceLine && invoiceLine.id
          ? 'Factuurlijn wijzigen'
          : 'Factuurlijn toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {invoiceLine && invoiceLine.id && <Label>ID: {invoiceLine.id}</Label>}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='description'
              label='Omschrijving'
              value={
                invoiceLine && invoiceLine.description
                  ? invoiceLine.description
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='units'
              label='Aantal'
              value={invoiceLine && invoiceLine.units ? invoiceLine.units : ''}
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='unit_price'
              label='Eenheidsprijs'
              value={
                invoiceLine && invoiceLine.unit_price
                  ? invoiceLine.unit_price
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          {invoiceLine && invoiceLine.unit_price && invoiceLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal excl BTW</Label>&euro;{' '}
              {(invoiceLine.unit_price * invoiceLine.units).toFixed(2)}
            </Stack>
          )}

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <TextField
              name='percentage_vat'
              label='BTW %'
              placeholder='21'
              value={
                invoiceLine &&
                (invoiceLine.percentage_vat || invoiceLine.percentage_vat === 0)
                  ? invoiceLine.percentage_vat.toString()
                  : ''
              }
              onChange={onChangeTextFieldValue}
              required
            />
          </Stack>

          <Stack
            style={{
              marginBottom: 10,
            }}
          >
            <Combobox
              label='BTW Code'
              options={convertTaxCodesToComboBoxOptions(taxCodes)}
              selectedKey={
                invoiceLine && invoiceLine.tax_code
                  ? invoiceLine.tax_code.id
                  : undefined
              }
              onSelect={key => {
                if (key && !Array.isArray(key)) {
                  if (taxCodes) {
                    const filterValues = taxCodes.filter((item: ITaxCode) => {
                      if (item && item.id && item.id === key) {
                        return true;
                      }
                      return false;
                    });

                    if (filterValues && filterValues[0]) {
                      const typeValue = filterValues[0];
                      const result = _.cloneDeep(invoiceLine);
                      result.tax_code = typeValue;
                      result.percentage_vat = typeValue.rate;
                      result.isDirty = true;

                      setInvoiceLine(result);
                    }
                  }
                }
              }}
              required
            />
          </Stack>

          {invoiceLine && invoiceLine.unit_price && invoiceLine.units && (
            <Stack
              style={{
                marginBottom: 10,
              }}
            >
              <Label>Totaal incl BTW</Label>&euro;{' '}
              {(
                (parseFloat(invoiceLine.unit_price) || 0) *
                (parseFloat(invoiceLine.units) || 1) *
                (1 +
                  ((invoiceLine.tax_code?.rate !== undefined
                    ? parseFloat(invoiceLine.tax_code.rate)
                    : parseFloat(invoiceLine.percentage_vat) || 0) < 1
                    ? invoiceLine.tax_code?.rate !== undefined
                      ? parseFloat(invoiceLine.tax_code.rate)
                      : parseFloat(invoiceLine.percentage_vat) || 0
                    : (invoiceLine.tax_code?.rate !== undefined
                        ? parseFloat(invoiceLine.tax_code.rate)
                        : parseFloat(invoiceLine.percentage_vat) || 0) / 100))
              ).toFixed(2)}
            </Stack>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <Label>Toon enkel totaal</Label>
            <Checkbox
              name='show_only_total'
              label='Toon enkel totaal?'
              checked={!!(invoiceLine && invoiceLine.show_only_total)}
              onChange={() => {
                setInvoiceLine((prevState: any) => ({
                  ...prevState,
                  isDirty: true,
                  show_only_total:
                    invoiceLine && invoiceLine.show_only_total
                      ? !invoiceLine.show_only_total
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !invoiceLine ||
                  (invoiceLine &&
                    (!invoiceLine.units ||
                      !invoiceLine.unit_price ||
                      (!invoiceLine.percentage_vat &&
                        invoiceLine.percentage_vat !== 0) ||
                      !invoiceLine.description))
                }
                save={saveInvoiceLine}
                permission='write:customerInvoiceLineItems'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default InvoiceLineDetail;
