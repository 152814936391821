import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  Stack,
  Label,
  StackItem,
  TextField,
  IComboBoxOption,
  Checkbox,
  Selection,
  ShimmeredDetailsList,
  DetailsListLayoutMode,
  CommandBar,
  SelectionMode,
} from '@fluentui/react';
import _ from 'lodash';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import {
  convertSuppliersToComboBoxOptions,
  GET_CONSTRUCTION_SITES,
  modalContentStyles,
} from '../../../utils';
import { ComboboxWithFilter } from '../../../components/parts';
import DateView from '../../../components/parts/DateView';
import { Accordion, AccordionItem } from '../../../components/parts/Accordion';
import { commandBarTheme } from '../../../theme';
import {
  ADD_INCOMING_INVOICE_LINE_ITEM,
  GET_INCOMING_INVOICE_LINE_ITEM_BY_ID,
  IIncomingInvoiceLineItem,
  UPDATE_INCOMING_INVOICE_LINE_ITEM,
} from '../../../utils/IncomingInvoiceLineItem';
import useDebounce from '../../../components/hooks/useDebounce';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import {
  GET_INCOMING_INVOICE_BY_ID,
  IIncomingInvoice,
} from '../../../utils/IncomingInvoice';
import InvoiceLineDetail from './InvoiceLineDetail';

const IncomingInvoiceDetail = ({
  isOpen,
  dismissPanel,
  incomingInvoiceId,
  taxCodes,
}: any) => {
  const [isInvoiceLinePanelOpen, setIsInvoiceLinePanelOpen] = useState(false);
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [invoiceLine, setInvoiceLine] = useState<
    IIncomingInvoiceLineItem | undefined
  >();

  // Selection line items
  const [selectionDetails, setSelectionDetails] = useState<
    IIncomingInvoiceLineItem | undefined
  >(undefined);

  const [incomingInvoice, setIncomingInvoice] = useState<
    IIncomingInvoice | undefined
  >();

  useQuery(GET_INCOMING_INVOICE_BY_ID, {
    variables: { where: { id: parseInt(incomingInvoiceId, 10) } },
    onCompleted: data => {
      if (data && data.findOneIncomingInvoice) {
        setIncomingInvoice(data.findOneIncomingInvoice);
        console.log(data.findOneIncomingInvoice);
      }
    },
  });

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
      setInvoiceLine(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openLineItemDetail = (newLineItem?: boolean) => {
    if (selectionDetails && newLineItem) {
      // setInvoiceLine(undefined);
    }
    setIsInvoiceLinePanelOpen(true);
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        incomingInvoice && incomingInvoice.id
          ? 'Inkomende factuur bekijken'
          : 'Inkomende factuur toevoegen'
      }
      type={PanelType.custom}
      customWidth='1050px'
    >
      {incomingInvoice && (
        <>
          {isInvoiceLinePanelOpen && invoiceLine && (
            <InvoiceLineDetail
              isOpen={isInvoiceLinePanelOpen}
              dismissPanel={() => {
                setIsInvoiceLinePanelOpen(false);
              }}
              selection={selection}
              invoiceLine={invoiceLine}
              setInvoiceLine={setInvoiceLine}
              tax_codes={taxCodes}
            />
          )}
          <div>
            <div className={modalContentStyles.header} />
            <div className={modalContentStyles.body}>
              {incomingInvoice && incomingInvoice.id && (
                <Label>ID: {incomingInvoice.id}</Label>
              )}

              <Stack
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <StackItem style={{ width: '48%' }}>
                  {incomingInvoice && incomingInvoice.id && (
                    <Stack style={{ marginBottom: 10 }}>
                      <Label>
                        WingsID: {incomingInvoice?.internal_invoice_no}
                      </Label>
                    </Stack>
                  )}

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='invoice_no'
                      label='Factuurnummer'
                      value={
                        incomingInvoice && incomingInvoice.invoice_no
                          ? incomingInvoice.invoice_no
                          : ''
                      }
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='supplier'
                      label='Leverancier'
                      value={
                        incomingInvoice && incomingInvoice.supplier
                          ? incomingInvoice.supplier.name
                          : ''
                      }
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='total_excl_vat'
                      label='Totaal excl. BTW'
                      prefix='€'
                      value={
                        incomingInvoice && incomingInvoice.total_excl_vat
                          ? incomingInvoice.total_excl_vat.toFixed(2)
                          : ''
                      }
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='total_incl_vat'
                      label='Totaal incl. BTW'
                      prefix='€'
                      value={
                        incomingInvoice && incomingInvoice.total_incl_vat
                          ? incomingInvoice.total_incl_vat.toFixed(2)
                          : ''
                      }
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='payment_terms'
                      label='Betaaltermijn (dagen)'
                      value={
                        incomingInvoice && incomingInvoice.payment_terms
                          ? incomingInvoice.payment_terms.toString()
                          : ''
                      }
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='comments'
                      label='Opmerkingen'
                      multiline
                      value={
                        incomingInvoice && incomingInvoice.comments
                          ? incomingInvoice.comments
                          : ''
                      }
                    />
                  </Stack>
                </StackItem>
                <StackItem style={{ width: '48%' }}>
                  {incomingInvoice && incomingInvoice.id && (
                    <Stack style={{ marginBottom: 10 }}>
                      <Label>
                        Datum syncronisatie:{' '}
                        {moment(new Date(incomingInvoice.date_created)).format(
                          'DD/MM/YYYY',
                        )}
                      </Label>
                    </Stack>
                  )}
                  <Stack style={{ marginBottom: 22, marginTop: 39 }}>
                    <Checkbox
                      name='blocked'
                      label='Geblokkeerd?'
                      checked={
                        !!(incomingInvoice && incomingInvoice.blocked === true)
                      }
                      defaultChecked={
                        !!(incomingInvoice && incomingInvoice.blocked === true)
                      }
                      disabled
                    />
                  </Stack>

                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='paid'
                      label='Betaald?'
                      checked={
                        !!(incomingInvoice && incomingInvoice.paid === true)
                      }
                      defaultChecked={
                        !!(incomingInvoice && incomingInvoice.paid === true)
                      }
                      disabled
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='wings_invoice'
                      label='Factuur geïmporteerd uit Wings?'
                      checked={
                        !!(
                          incomingInvoice &&
                          incomingInvoice.wings_invoice === true
                        )
                      }
                      defaultChecked={
                        !!(
                          incomingInvoice &&
                          incomingInvoice.wings_invoice === true
                        )
                      }
                      disabled
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10, marginTop: 20 }}>
                    <Checkbox
                      name='paid_in_wings'
                      label='Betaald Wings?'
                      checked={
                        !!(
                          incomingInvoice &&
                          incomingInvoice.paid_in_wings === true
                        )
                      }
                      defaultChecked={
                        !!(
                          incomingInvoice &&
                          incomingInvoice.paid_in_wings === true
                        )
                      }
                      disabled
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='invoice_date'
                      label='Factuurdatum'
                      type='text'
                      value={
                        incomingInvoice && incomingInvoice.invoice_date
                          ? moment(
                              new Date(incomingInvoice.invoice_date),
                            ).format('DD/MM/YYYY')
                          : ''
                      }
                    />
                  </Stack>
                  <Stack style={{ marginBottom: 10 }}>
                    <TextField
                      name='due_date'
                      label='Vervaldatum'
                      type='text'
                      value={
                        incomingInvoice && incomingInvoice.due_date
                          ? moment(new Date(incomingInvoice.due_date)).format(
                              'DD/MM/YYYY',
                            )
                          : ''
                      }
                    />
                  </Stack>
                </StackItem>
              </Stack>

              <Stack style={{ marginBottom: 10, marginTop: 30 }}>
                {incomingInvoice && incomingInvoice.id && (
                  <Accordion
                    selectedKey={selectedValueItem}
                    defaultKey={undefined}
                    toggleItem={(key: string | number) => {
                      if (selectedValueItem === key) {
                        setSelectedValueItem(undefined);
                      } else setSelectedValueItem(key);
                    }}
                  >
                    {incomingInvoice && (
                      <AccordionItem
                        key='accordion-invoice-line-items'
                        id='accordion-invoice-line-items'
                        title={
                          <Stack>
                            <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                              Detail
                            </h3>
                          </Stack>
                        }
                      >
                        <CommandBar
                          items={[
                            {
                              key: 'modify',
                              text: 'Bekijk',
                              iconProps: { iconName: 'Edit' },
                              onClick: () => openLineItemDetail(),
                              theme: commandBarTheme,
                              disabled: !selectionDetails,
                            },
                          ]}
                          theme={commandBarTheme}
                        />
                        <ShimmeredDetailsList
                          items={
                            (incomingInvoice as any)
                              .incoming_invoice_line_items || []
                          }
                          columns={[
                            {
                              key: 'column1',
                              name: 'Omschrijving',
                              fieldName: 'description',
                              minWidth: 175,
                              maxWidth: 175,
                              isRowHeader: true,
                              onRender: (line_item: any) => (
                                <span>{line_item.description}</span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column2',
                              name: 'Werf / auto',
                              fieldName: 'construction_site',
                              minWidth: 175,
                              maxWidth: 175,
                              isRowHeader: true,
                              onRender: (line_item: any) => (
                                <span>
                                  {line_item.construction_site ?
                                    line_item.construction_site.name : line_item.car ? line_item.car.license_plate : ''}
                                </span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column3',
                              name: 'Post',
                              fieldName: 'post',
                              minWidth: 175,
                              maxWidth: 175,
                              isRowHeader: true,
                              onRender: (line_item: any) => (
                                <span>
                                  {line_item.cost_center &&
                                    line_item.cost_center.name}
                                </span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                            {
                              key: 'column4',
                              name: 'Bedrag excl. BTW',
                              fieldName: 'amount',
                              minWidth: 40,
                              maxWidth: 40,
                              isRowHeader: true,
                              onRender: (line_item: any) => (
                                <span>
                                  &euro; {line_item.amount.toFixed(2)}
                                </span>
                              ),
                              data: 'string',
                              isPadded: true,
                            },
                          ]}
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible
                          selection={selection}
                          selectionMode={SelectionMode.single}
                          selectionPreservedOnEmptyClick
                          onItemInvoked={() => openLineItemDetail()}
                        />
                      </AccordionItem>
                    )}
                  </Accordion>
                )}
              </Stack>
            </div>
            <div className={modalContentStyles.footer}>
              <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
                <StackItem>
                  <DefaultButton onClick={dismissPanel}>
                    Annuleren
                  </DefaultButton>
                </StackItem>
              </Stack>
            </div>
          </div>
        </>
      )}
    </Panel>
  );
};

export default IncomingInvoiceDetail;
