import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  CommandBar,
  DefaultButton,
  DetailsList,
  Label,
  Panel,
  PanelType,
  SelectionMode,
  Selection,
  Stack,
  StackItem,
  TextField,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
} from '@fluentui/react';
import _ from 'lodash';
// import SuggestedSectorView from './SuggestedSectorView';
import { useMutation, useQuery } from '@apollo/client';
import { useBoolean } from '@fluentui/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  modalContentStyles,
} from '../../../utils';
import Accordion, { AccordionItem } from '../../../components/parts/Accordion';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { commandBarTheme } from '../../../theme';
import {
  ADD_COST_CENTER_WINGS,
  DELETE_COST_CENTER_WINGS,
  GET_COST_CENTER_WINGS_BY_ID,
  ICostCenterWings,
  UPDATE_COST_CENTER_WINGS,
} from '../../../utils/ConstCenterWings';
import { ICostCenter } from '../../../utils/CostCenter';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { toastSuccess, toastError } from '../../../utils/toast';
import CostCenterWingsDetail from './CostCenterWingsDetail';

interface Props {
  isOpen: any;
  dismissPanel: any;
  saveCostCenter: any;
  costCenter: ICostCenter | undefined;
  setCostCenter: any;
  refetch: any;
}

const CostCenterDetail = ({
  isOpen,
  dismissPanel,
  saveCostCenter,
  costCenter,
  setCostCenter,
  refetch,
}: Props) => {
  const [selectedValueItem, setSelectedValueItem] = useState<
    number | string | undefined
  >();
  const [deleteDialogOpen, { toggle: toggleDelete }] = useBoolean(false);

  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setCostCenter((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  const [isCostCenterWingsPanelOpen, setIsCostCenterWingsPanelOpen] =
    useState(false);
  const [costCenterWings, setCostCenterWings] = useState<
    ICostCenterWings | undefined
  >(undefined);

  const [addCostCenter] = useMutation(ADD_COST_CENTER_WINGS);

  const [modifyCostCenter] = useMutation(UPDATE_COST_CENTER_WINGS);

  const [deleteCostCenterWings] = useMutation(DELETE_COST_CENTER_WINGS);

  const [selectedCostCenterWingsID, setSelectedCostCenterWingsID] = useState<
    number | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = costCenterWingsSelection.getSelection();
    if (currentSelection.length > 0) {
      setSelectedCostCenterWingsID(currentSelection[0].id);
    } else if (
      costCenter &&
      costCenter.cost_center_wings &&
      costCenter.cost_center_wings.length === 1
    ) {
      setSelectedCostCenterWingsID(costCenter.cost_center_wings[0].id);
    } else {
      setSelectedCostCenterWingsID(undefined);
    }
  };

  const costCenterWingsSelection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCostCenterWingsDetail = (newCarDriver?: boolean) => {
    if (newCarDriver) {
      setCostCenterWings(undefined);
      setSelectedCostCenterWingsID(undefined);
    }
    setIsCostCenterWingsPanelOpen(true);
  };

  const deleteSelection = useCallback(async () => {
    try {
      if (!selectedCostCenterWingsID) {
        toggleDelete();
        return;
      }
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteCostCenterWings({
            variables: {
              where: {
                id: selectedCostCenterWingsID,
              },
            },
            onError: error => {
              toggleDelete();
              reject(error);
            },
            onCompleted: async () => {
              await refetch();
              toggleDelete();
              resolve('success');
            },
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Nummer verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
    // delete cars after confirmation
  }, [selectedCostCenterWingsID]);

  useQuery(GET_COST_CENTER_WINGS_BY_ID, {
    variables: selectedCostCenterWingsID
      ? { where: { id: selectedCostCenterWingsID } }
      : undefined,
    skip: !selectedCostCenterWingsID,
    onCompleted: data => {
      setCostCenterWings(data.findOneCostCenterWings);
    },
  });

  const saveCostCenterWings = async () => {
    try {
      if (costCenterWings && costCenter) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              wings_number: costCenterWings.wings_number,
            };

            if (costCenterWings.id) {
              modifyCostCenter({
                variables: {
                  id: costCenterWings.id,
                  data: allInput,
                },
                onError: error => {
                  setIsCostCenterWingsPanelOpen(false);
                  reject(error);
                },
                onCompleted: async (x) => {
                  await refetch();
                  setIsCostCenterWingsPanelOpen(false);
                  resolve(x);
                },
              });
            } else {
              addCostCenter({
                variables: {
                  data: {
                    ...allInput,
                    cost_center: {
                      connect: {
                        id: costCenter.id,
                      },
                    },
                  },
                },
                onError: error => {
                  setIsCostCenterWingsPanelOpen(false);
                  reject(error);
                },
                onCompleted: async (x) => {
                  await refetch();
                  setIsCostCenterWingsPanelOpen(false);
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Nummer opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        costCenter && costCenter.id
          ? 'Boekhoudpost wijzigen'
          : 'Boekhoudpost toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      {isCostCenterWingsPanelOpen && (
        <CostCenterWingsDetail
          isOpen={isCostCenterWingsPanelOpen}
          dismissPanel={() => {
            setIsCostCenterWingsPanelOpen(false);
          }}
          costCenterWings={costCenterWings}
          saveCostCenterWings={saveCostCenterWings}
          setCostCenterWings={setCostCenterWings}
          refetchCostCenter={refetch}
        />
      )}
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <p>ID: {costCenter?.id}</p>
          <Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='name'
                label='Naam'
                value={costCenter && costCenter.name ? costCenter.name : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  costCenter && !costCenter.name ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>

            <Stack style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                name='active'
                label='Actief?'
                checked={costCenter && costCenter.active}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    active:
                      costCenter && costCenter.active
                        ? !costCenter.active
                        : true,
                  }));
                }}
              />
            </Stack>

            <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                name='customer_specific'
                label='Werfpost?'
                checked={costCenter && costCenter.customer_specific}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    customer_specific:
                      costCenter && costCenter.customer_specific
                        ? !costCenter.customer_specific
                        : true,
                  }));
                }}
              />
            </Stack>
            <Stack style={{ marginTop: 30, marginBottom: 10 }}>
              {costCenter && costCenter.id ? (
                <Accordion
                  selectedKey={selectedValueItem}
                  defaultKey={undefined}
                  toggleItem={(key: string | number) => {
                    if (selectedValueItem === key) {
                      setSelectedValueItem(undefined);
                    } else setSelectedValueItem(key);
                  }}
                >
                  <AccordionItem
                    key='accordion-cost-center-wings'
                    id='accordion-cost-center-wings'
                    title={
                      <Stack>
                        <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                          Nummers
                        </h3>
                      </Stack>
                    }
                  >
                    <CommandBar
                      items={[
                        {
                          key: 'new',
                          text: 'Nieuw',
                          iconProps: { iconName: 'Add' },
                          onClick: () => openCostCenterWingsDetail(true),
                          theme: commandBarTheme,
                        },
                        {
                          key: 'modify',
                          text: 'Wijzig',
                          iconProps: { iconName: 'Edit' },
                          onClick: () => openCostCenterWingsDetail(),
                          theme: commandBarTheme,
                          disabled: !selectedCostCenterWingsID,
                        },
                        {
                          key: 'delete',
                          text: 'Verwijderen',
                          iconProps: { iconName: 'Trash' },
                          onClick: () => toggleDelete(),
                          theme: commandBarTheme,
                          disabled: !selectedCostCenterWingsID,
                        },
                      ]}
                      theme={commandBarTheme}
                    />
                    {deleteDialogOpen && selectedCostCenterWingsID && (
                      <Dialog
                        hidden={!deleteDialogOpen}
                        onDismiss={toggleDelete}
                        dialogContentProps={{
                          type: DialogType.normal,
                          title: 'Wagens verwijderen',
                          closeButtonAriaLabel: 'Close',
                        }}
                      >
                        <p>
                          <strong>
                            Ben je zeker dat je deze nummer wil verwijderen?
                          </strong>
                        </p>
                        <p>Deze actie kan niet ongedaan gemaakt worden.</p>
                        <DialogFooter>
                          <PrimaryButton
                            text='Verwijderen'
                            onClick={deleteSelection}
                          />
                          <DefaultButton
                            text='Annuleren'
                            onClick={toggleDelete}
                          />
                        </DialogFooter>
                      </Dialog>
                    )}
                    <DetailsList
                      items={
                        costCenter.cost_center_wings
                          ? _.cloneDeep(costCenter.cost_center_wings)
                          : []
                      }
                      columns={[
                        {
                          key: 'column1',
                          name: 'Nummer',
                          fieldName: 'wings_number',
                          minWidth: 150,
                          maxWidth: 150,
                          isRowHeader: true,
                          onRender: (cost_center_wings: ICostCenterWings) => (
                            <span>{cost_center_wings.wings_number}</span>
                          ),
                          data: 'string',
                          isPadded: true,
                        },
                      ]}
                      // getKey={item => item.id}
                      // layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible
                      selection={costCenterWingsSelection}
                      selectionMode={SelectionMode.single}
                      setKey='costCenterWingsOverview'
                    />
                  </AccordionItem>
                </Accordion>
              ) : (
                <>
                  <Label>
                    Na opslaan kan je de cost center nummers toevoegen of
                    bewerken.
                  </Label>
                  <Stack style={{ opacity: 0.3, pointerEvents: 'none' }}>
                    <Accordion
                      selectedKey={undefined}
                      defaultKey={undefined}
                      toggleItem={() => {
                        setSelectedValueItem(undefined);
                      }}
                    >
                      <AccordionItem
                        key=''
                        id=''
                        title={
                          <Stack>
                            <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                              Cost center nummers
                            </h3>
                          </Stack>
                        }
                      />
                    </Accordion>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={!costCenter || (costCenter && !costCenter.name)}
              save={saveCostCenter}
              permission='write:costCenters'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default CostCenterDetail;
