import { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
} from '@fluentui/react';
import useDebounce from '../../components/hooks/useDebounce';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_WORK_ORDERS, IWorkOrder } from '../../utils/WorkOrder';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import WorkOrdersOverview from './components/WorkOrdersOverview';

interface IPagedWorkOrder {
  workOrders: IWorkOrder[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

function WorkOrders() {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedWorkOrder>({
    workOrders: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    isFiltered: false,
    initialLoad: true,
    lazyLoading: true,
    isSortedAsc: true,
    sortedField: 'document_date',
  });

  const [filter, setFilter] = useState('');
  const [completed, setCompleted] = useState(false);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, workOrders: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, completed: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ status: { contains: completed ? 'COMPLETED' : 'NEW' } }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { description: { contains: filterArray[i] } },
          { document_no: { contains: filterArray[i] } },
          {
            construction_site: {
              OR: [
                { name: { contains: filterArray[i] } },
                { address: { contains: filterArray[i] } },
              ],
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const variables = {
    filter: getFilters(debouncedValue, completed),
    take: pagedState.take,
    skip: pagedState.skip,
    orderBy: getOrderBy(),
  };

  const {
    data: dataWorkOrders,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_WORK_ORDERS, {
    // notifyOnNetworkStatusChange: true,
    variables,
    onError: error => {
      dispatch(
        throwError({
          module: 'car Overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // fetchPolicy: 'cache-and-network', comment, we want to use default fetchPolicy
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearWorkOrders();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearWorkOrders = () => {
    setPagedState(prevState => ({
      ...prevState,
      workOrders: [],
      skip: 0,
    }));
  };

  const fetchMoreWorkOrders = () => {
    // Load more guard

    fetchMore({
      variables: {
        ...variables,
        skip: pagedState.skip + pagedState.take,
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  const onChangeFilterCompleted = () => {
    setCompleted(!completed);
    clearWorkOrders();
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Overzicht werkbonnen</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <StackItem style={{ marginLeft: 70 }}>
          <Toggle
            label='Afgesloten?'
            defaultChecked={completed}
            onText='Afgesloten'
            offText='Nieuw'
            onChange={onChangeFilterCompleted}
          />
        </StackItem>
      </Stack>

      <WorkOrdersOverview
        workOrders={
          dataWorkOrders
            ? dataWorkOrders.findManyDefectWorkOrders
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        initialLoad={pagedState.initialLoad}
        loadMore
        showLoadMore={pagedState.showLoadMore}
        loadMoreCallback={fetchMoreWorkOrders}
        lazyLoading={pagedState.lazyLoading}
      />
    </Stack>
  );
}

export default WorkOrders;
