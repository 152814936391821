import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField, Toggle } from '@fluentui/react';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_CARS, ICar } from '../../utils/Car';
import useDebounce from '../../components/hooks/useDebounce';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { toastError } from '../../utils/toast';
import CarsOverview from './components/CarsOverview';

interface IPagedCar {
  cars: ICar[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const Cars = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedCar>({
    cars: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');
  const [inUse, setInUse] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({ ...prevState, skip: 0, cars: [] }));
  }, [filter, inUse]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ active }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { name: { contains: filterArray[i] } },
          { car_type: { contains: filterArray[i] } },
          { license_plate: { contains: filterArray[i] } },
          {
            car_driver: {
              some: {
                employee: {
                  OR: [
                    { first_name: { contains: filterArray[i] } },
                    { last_name: { contains: filterArray[i] } },
                  ],
                },
              },
            },
          },
        ],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const variables = {
    filter: getFilters(debouncedValue, inUse), // inUse == active?
    take: pagedState.take,
    skip: 0,
    orderBy: getOrderBy(),
  };

  const {
    data: dataCars,
    loading,
    error,
    fetchMore,
    refetch,
    client,
  } = useQuery(GET_CARS, {
    // notifyOnNetworkStatusChange: true,
    variables,
    onError: error => {
      toastError(error.message);
    },
    // fetchPolicy: 'cache-and-network', comment, we want to use default fetchPolicy
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterInUse = () => {
    setInUse(!inUse);
    clearCars();
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearCars();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearCars = () => {
    setPagedState(prevState => ({
      ...prevState,
      cars: [],
      skip: 0,
    }));
  };

  const fetchMoreCars = () => {
    if (((pagedState.take * 2) + pagedState.skip) > pagedState.cars.length) {
      setPagedState(prevState => ({
        ...prevState,
        showLoadMore: false,
      }));
    }

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, inUse), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Wagenpark</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <Stack style={{ marginLeft: 70 }}>
          <Toggle
            label='In gebruik?'
            defaultChecked={inUse}
            onText='In gebruik'
            offText='Niet in gebruik'
            onChange={onChangeFilterInUse}
          />
        </Stack>
      </Stack>

      <CarsOverview
        cars={
          dataCars
            ? dataCars.findManyCars
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCars}
        lazyLoading={pagedState.lazyLoading}
        refetch={refetch}
      />
    </Stack>
  );
};

export default Cars;
