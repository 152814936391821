import React, { FormEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Stack, TextField } from '@fluentui/react';
import { toast } from 'react-toastify';
import useDebounce from '../../components/hooks/useDebounce';
import {
  DEFAULT_ERROR_MESSAGE,
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_COST_CENTERS, ICostCenter } from '../../utils/CostCenter';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import { toastError } from '../../utils/toast';
import CostCentersOverview from './components/CostCentersOverview';

interface IPagedCostCenters {
  costCenters: ICostCenter[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const CostCenters = () => {
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedCostCenters>({
    costCenters: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'name',
  });

  const [filter, setFilter] = useState('');
  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    setPagedState(prevState => ({ ...prevState, costCenters: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [{ name: { contains: filterArray[i] } }],
      };

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataCostCenters,
    loading,
    error,
    fetchMore,
  } = useQuery(GET_COST_CENTERS, {
    // SnotifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: error => {
      toastError(error.message || DEFAULT_ERROR_MESSAGE);
    },
    // fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    clearCostCenters();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearCostCenters = () => {
    setPagedState(prevState => ({
      ...prevState,
      costCenters: [],
      skip: 0,
    }));
  };

  const fetchMoreCostCenters = () => {
    // Load more callback

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue),
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Boekhoudkundige posten</h3>

      <TextField
        label='Zoeken...'
        value={filter}
        styles={textFieldStyles300}
        onChange={onSearchValueChange}
      />

      <CostCentersOverview
        costCenters={
          dataCostCenters
            ? dataCostCenters.findManyCostCenters
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreCostCenters}
        lazyLoading={pagedState.lazyLoading}
      />
    </Stack>
  );
};

export default CostCenters;
