import {
    Checkbox, DetailsListLayoutMode, IColumn, IObjectWithKey, ISelection, SelectionMode,
} from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { DetailsListDefault } from '../../../components/parts';
import { ICar } from '../../../utils/Car';
import { IIncomingInvoice } from '../../../utils/IncomingInvoice';

interface props {
    items: IIncomingInvoice[],
    selection?: ISelection<IObjectWithKey> | undefined,
    onItemInvoked?: () => void;
    enableShimmer?: boolean;
    isSortedAsc?: boolean;
    sortedField: string;
    setSorting: (isDesc: boolean, field: string) => void;
    loadMore?: boolean;
    lazyLoading?: boolean;
    loading?: boolean;
    loadMoreCallback?: () => void;
}

const IncomingInvoicesDetailsList = ({
    items,
    selection,
    onItemInvoked,
    enableShimmer = false,
    isSortedAsc = false,
    sortedField,
    setSorting, // = (isSortedAsc: boolean, sortedField: string) => {},
    loadMore,
    lazyLoading,
    loading,
    loadMoreCallback,
  }: props) => {
    // On Column Click
    const onColumnClick = (event: any, column: any) => {
        const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
        let fieldName = '';
        let sortDescending = false;

        if (sortedColumn) {
          const newColumns = columns.map((col: IColumn) => {
            if (col.fieldName === column.fieldName) {
              col.isSorted = true;

              col.isSortedDescending =
                column.fieldName !== sortedColumn[0].fieldName
                  ? false
                  : !col.isSortedDescending;

              fieldName = column.fieldName;
              sortDescending = col.isSortedDescending;
            } else {
              col.isSorted = false;
            }
            return col;
          });
          setColumns(newColumns);

          setSorting(!sortDescending, fieldName);
        }
      };

    // Column List
    const columnsList = [
    {
      key: 'column1',
      name: 'Intern nr',
      fieldName: 'internal_invoice_no',
      minWidth: 65,
      maxWidth: 65,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.internal_invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Leverancier',
      fieldName: 'supplier',
      minWidth: 160,
      maxWidth: 160,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.supplier.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Factuurnr',
      fieldName: 'invoice_no',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>{incomingInvoice.invoice_no}</span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Factuurdatum',
      fieldName: 'invoice_date',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {incomingInvoice.invoice_date &&
            moment(new Date(incomingInvoice.invoice_date)).format('DD/MM/YYYY')}
        </span>
      ),
      onColumnClick,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Wings betaald',
      fieldName: 'paid_in_wings',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          <Checkbox disabled defaultChecked={incomingInvoice.paid_in_wings} />
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column9',
      name: 'Excl. BTW',
      fieldName: 'total_excl_vat',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {/* &euro;{' '}
          {incomingInvoice.incoming_invoice_line_items
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue.unit_price;
            }, 0)
            .toFixed(2)} */}
          &euro; {incomingInvoice.total_excl_vat.toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column10',
      name: 'Incl. BTW',
      fieldName: 'total_incl_vat',
      minWidth: 70,
      maxWidth: 70,
      isRowHeader: true,
      onRender: (incomingInvoice: IIncomingInvoice) => (
        <span>
          {/* &euro;{' '}
          {incomingInvoice.incoming_invoice_line_items
            .reduce((previousValue, currentValue) => {
              return previousValue + currentValue.unit_price;
            }, 0)
            .toFixed(2)} */}
          &euro; {incomingInvoice.total_incl_vat.toFixed(2)}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

    const initColumns = (sortedField: string, isSortedAsc: boolean) => columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

    const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

    return (
      <DetailsListDefault
        items={items}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        selection={selection}
        selectionMode={SelectionMode.single}
        selectionPreservedOnEmptyClick
        onItemInvoked={onItemInvoked}
        enableShimmer={enableShimmer}
        loadMore={loadMore}
        lazyLoading={lazyLoading}
        loadMoreCallback={loadMoreCallback}
        loading={loading}
      />
    );
  };

  export default IncomingInvoicesDetailsList;
