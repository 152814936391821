import React, { useState } from 'react';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  Label,
  StackItem,
  Checkbox,
  Dialog,
  DialogFooter,
  IComboBoxOption,
} from '@fluentui/react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  dialogModelProps,
  displayNameEmployee,
  modalContentStyles,
} from '../../../utils';
import { dialogRemoveConfirmationPropsGeneral } from '../../../utils/Styles';
import DateView from '../../../components/parts/DateView';
import { ComboboxWithFilter } from '../../../components/parts';
import { DELETE_CIVIL_CLASS_STATUS } from '../../../utils/CivilClassStatuses';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { toastSuccess, toastError } from '../../../utils/toast';

const CivilClassDetail = ({
  isOpen,
  dismissPanel,
  saveCivilClassStatus,
  civilClassStatus,
  setCivilClassStatus,
  refetchEmployee,
}: any) => {
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);

  const [deleteCivilClass] = useMutation(DELETE_CIVIL_CLASS_STATUS);
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
  };

  const deleteCivilClassFn = async () => {
    try {
      const res = await toast.promise(
        new Promise((resolve, reject) => {
          deleteCivilClass({
            variables: {
              where: {
                id: civilClassStatus.id,
              },
            },
            onCompleted: x => {
              resolve(x);
            },
            onError: err => {
              reject(err);
            },
          }).then(() => {
            toggleConfirmationDialog();
            // clearCivilClass();
            refetchEmployee();
            dismissPanel();
          });
        }),
        {
          pending: {
            position: DEFAULT_TOAST_POSITION,
            render() {
              return DEFAULT_LOADING_MESSAGE;
            },
          },
        },
        {
          autoClose: DEFAULT_TOAST_DURATION,
        },
      );

      await res;
      toastSuccess('Burgerlijke stand verwijderd');
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        civilClassStatus && civilClassStatus.id
          ? 'Burgerlijke stand wijzigen'
          : 'Burgerlijke stand toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          {civilClassStatus && civilClassStatus.id && (
            <>
              <Label>ID: {civilClassStatus.id}</Label>

              <Stack style={{ marginBottom: 10 }}>
                <Label>
                  Medewerker:{' '}
                  {civilClassStatus.employee &&
                    displayNameEmployee(civilClassStatus.employee)}
                </Label>
              </Stack>
            </>
          )}

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={civilClassStatus}
              setItem={setCivilClassStatus}
              date={civilClassStatus && civilClassStatus.start_date}
              label='Vanaf'
              field='start_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <DateView
              item={civilClassStatus}
              setItem={setCivilClassStatus}
              date={civilClassStatus && civilClassStatus.end_date}
              label='Tot'
              field='end_date'
            />
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <ComboboxWithFilter
              label='Burgerlijke stand'
              options={[
                {
                  key: 'ongehuwd',
                  text: 'Ongehuwd',
                },
                { key: 'gehuwd', text: 'Gehuwd' },
                { key: 'weduwe-weduwnaar', text: 'Weduwe/Weduwnaar' },
                { key: 'gescheiden', text: 'Gescheiden' },
                { key: 'samenwonend-wettelijk', text: 'Wettelijk samenwonend' },
                { key: 'samenwonend-feitelijk', text: 'Feitelijk samenwonend' },
              ]}
              value={
                civilClassStatus && civilClassStatus.civil_class
                  ? civilClassStatus.civil_class
                  : ''
              }
              multiline={false}
              callBack={(newValue: IComboBoxOption[]) => {
                if (newValue && newValue.length > 0) {
                  const result = { ...civilClassStatus };
                  result.civil_class = newValue[0].key;
                  setCivilClassStatus(result);
                }
              }}
            />

            {civilClassStatus &&
              civilClassStatus.civil_class !== 'ongehuwd' &&
              civilClassStatus.civil_class !== 'gehuwd' &&
              civilClassStatus.civil_class !== 'weduwe-weduwnaar' &&
              civilClassStatus.civil_class !== 'gescheiden' &&
              civilClassStatus.civil_class !== 'samenwonend-wettelijk' &&
              civilClassStatus.civil_class !== 'samenwonend-feitelijk' && (
                <Stack
                  style={{
                    marginTop: 5,
                  }}
                >
                  Burgerlijke stand: {civilClassStatus.civil_class}
                </Stack>
              )}
          </Stack>

          <Stack style={{ marginBottom: 10 }}>
            <Label>Huidige status</Label>
            <Checkbox
              name='current_status'
              label='Huidige status?'
              checked={civilClassStatus && civilClassStatus.current_status}
              onChange={() => {
                setCivilClassStatus((prevState: any) => ({
                  ...prevState,
                  current_status:
                    civilClassStatus && civilClassStatus.current_status
                      ? !civilClassStatus.current_status
                      : true,
                }));
              }}
            />
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack
            style={{
              flexDirection: 'row',
              marginTop: 10,
              justifyContent: 'space-between',
            }}
          >
            <Stack style={{ flexDirection: 'row' }}>
              <SaveButtonWithPermissions
                disabled={
                  !civilClassStatus ||
                  (civilClassStatus && !civilClassStatus.civil_class)
                }
                save={saveCivilClassStatus}
                permission='write:civilClassStatuses'
              />

              <StackItem>
                <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
              </StackItem>
            </Stack>

            <Stack>
              <StackItem>
                <DefaultButton onClick={toggleConfirmationDialog}>
                  Verwijderen
                </DefaultButton>
              </StackItem>
            </Stack>
          </Stack>
        </div>
      </div>

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationPropsGeneral}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteCivilClassFn} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};

export default CivilClassDetail;
