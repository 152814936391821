import {
 IconButton, PanelType, PrimaryButton, Stack, Panel,
} from '@fluentui/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { cancelIcon, iconButtonStyles, stackTokens5 } from '../../../utils';
import CustomerInvoiceDownloadTemplate from '../../customer-invoice/components/CustomerInvoiceDownloadTemplate';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import {
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  ICustomerContractUpdate,
} from '../../../utils/CustomerContractUpdate';
import { useAppDispatch } from '../../../redux/hooks';
import CustomerContractUpdateDownloadTemplate from './CustomerContractUpdateDownloadTemplate';

const CustomerContractUpdateDynamicPdf = ({
  customerContractUpdateSource,
  setShowPdf,
}: any) => {
  const dispatch = useAppDispatch();
  const [customerContractUpdate, setCustomerContractUpdate] = useState<
    ICustomerContractUpdate | undefined
  >();

  const {
    data: customerInvoiceDate,
    loading,
    error,
  } = useQuery(GET_CUSTOMER_CONTRACT_UPDATE_BY_ID, {
    variables: {
      where: {
        id: customerContractUpdateSource.id,
      },
    },
    fetchPolicy: 'no-cache',
    onError: (error: { message: any }) => {
      dispatch(
        throwError({
          module: 'customerInvoicePdf',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    onCompleted: (data: any) => {
        console.log(data);
      setCustomerContractUpdate(data.findOneCustomerContractUpdate);
    },
  });

  return (
    <Panel
      isOpen
      onDismiss={() => setShowPdf(false)}
      closeButtonAriaLabel='Close'
      headerText="PDF Bekijken"
      type={PanelType.custom}
      customWidth='100%'
    >
      {!loading && !error && customerContractUpdate && (
        <Stack
          styles={{
          root: {
            position: 'fixed',
            top: 60,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'white',
            marginTop: '0!important',
            display: 'flex',
            padding: '20px',
          },
        }}
        >
          <Stack.Item styles={{ root: { flex: 1 } }}>
            <PDFViewer width='100%' height='100%'>
              <CustomerContractUpdateDownloadTemplate
                customerContractUpdate={customerContractUpdate}
              />
            </PDFViewer>
          </Stack.Item>
        </Stack>
      )}
    </Panel>
  );
};

export default CustomerContractUpdateDynamicPdf;
