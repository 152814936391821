import {
  Panel,
  PanelType,
  Stack,
  TextField,
  StackItem,
  DefaultButton,
  Checkbox,
} from '@fluentui/react';
import SaveButtonWithPermissions from '../../../components/parts/SaveButtonWithPermissions';
import { modalContentStyles } from '../../../utils';
import { IPlan } from '../../../utils/Plan';
import SuggestedView from '../../../components/parts/SuggestedView';
import UploadImages from './UploadImage';

interface Props {
  isOpen: any;
  dismissPanel: any;
  savePlan: any;
  plan: IPlan | undefined;
  setPlan: any;
  refetch: any;
}

const PlanDetail = ({
  isOpen,
  dismissPanel,
  savePlan,
  plan,
  setPlan,
  refetch,
}: Props) => {
  const onChangeTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ) => {
    setPlan((prevState: any) => ({
      ...prevState,
      [(event.target as HTMLTextAreaElement).name]: newValue || '',
    }));
  };

  return (
    <Panel
      isLightDismiss
      isOpen={isOpen}
      onDismiss={dismissPanel}
      closeButtonAriaLabel='Close'
      headerText={
        plan && plan.id
          ? 'Voorbeeld project wijzigen'
          : 'Voorbeeld project toevoegen'
      }
      type={PanelType.custom}
      customWidth='500px'
    >
      <div>
        <div className={modalContentStyles.header} />
        <div className={modalContentStyles.body}>
          <p>ID: {plan?.id}</p>
          <Stack>
            <Stack style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                name='active'
                label='Actief?'
                checked={plan && plan.active}
                onChange={() => {
                  setPlan((prevState: any) => ({
                    ...prevState,
                    active: plan && plan.active ? !plan.active : true,
                  }));
                }}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='title'
                label='Titel'
                value={plan && plan.title ? plan.title : ''}
                onChange={onChangeTextFieldValue}
                required
                errorMessage={
                  plan && !plan.title ? 'Dit veld is verplicht' : ''
                }
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='subtitle'
                label='Onderschrift'
                value={plan && plan.subtitle ? plan.subtitle : ''}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='description'
                label='Beschrijving'
                value={plan && plan.description ? plan.description : ''}
                onChange={onChangeTextFieldValue}
                multiline
                rows={7}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='internal_description'
                label='Interne Beschrijving'
                value={
                  plan && plan.internal_description
                    ? plan.internal_description
                    : ''
                }
                onChange={onChangeTextFieldValue}
                multiline
                rows={7}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <SuggestedView
                item={plan}
                setItem={setPlan}
                prefix='voorbeeld_type-woning_'
                code={
                  plan &&
                  plan.type_of_home &&
                  plan.type_of_home.substr(plan.type_of_home.length - 4)
                }
                label='Type woning'
                field='type_of_home'
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <SuggestedView
                item={plan}
                setItem={setPlan}
                prefix='voorbeeld_stijl-woning_'
                code={
                  plan &&
                  plan.style_of_home &&
                  plan.style_of_home.substr(plan.style_of_home.length - 4)
                }
                label='Stijl woning'
                field='style_of_home'
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <SuggestedView
                item={plan}
                setItem={setPlan}
                prefix='voorbeeld_type-dak_'
                code={
                  plan &&
                  plan.type_of_roof &&
                  plan.type_of_roof.substr(plan.type_of_roof.length - 4)
                }
                label='Type Dak'
                field='type_of_roof'
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='house_area'
                label='Bewoonbare oppervlakte'
                type='number'
                onWheel={event => event.preventDefault()}
                required
                errorMessage={
                  plan && !plan.house_area ? 'Dit veld is verplicht' : ''
                }
                value={plan?.house_area as any}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            {/* <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='plot_area'
                label='Perceels oppervlakte'
                type='number'
                required
                errorMessage={
                  plan && !plan.plot_area ? 'Dit veld is verplicht' : ''
                }
                value={plan?.plot_area as any}
                onChange={onChangeTextFieldValue}
              />
            </Stack> */}
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='facade_width'
                label='Voorgevelbreedte'
                type='number'
                onWheel={event => event.preventDefault()}
                required
                errorMessage={
                  plan && !plan.facade_width ? 'Dit veld is verplicht' : ''
                }
                value={plan?.facade_width as any}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <TextField
                name='rooms'
                label='Aantal kamers'
                type='number'
                onWheel={event => event.preventDefault()}
                required
                errorMessage={
                  plan && !plan.title ? 'Dit veld is verplicht' : ''
                }
                value={plan?.rooms as any}
                onChange={onChangeTextFieldValue}
              />
            </Stack>
            <Stack style={{ marginBottom: 10 }}>
              <UploadImages plan={plan} setPlan={setPlan} />
            </Stack>

            {/* <Stack style={{ marginBottom: 10 }}>
              <Checkbox
                name='customer_specific'
                label='Werfpost?'
                checked={taxCode && taxCode.customer_specific}
                onChange={() => {
                  setCostCenter((prevState: any) => ({
                    ...prevState,
                    customer_specific:
                      costCenter && costCenter.customer_specific
                        ? !costCenter.customer_specific
                        : true,
                  }));
                }}
              />
            </Stack> */}
          </Stack>
        </div>
        <div className={modalContentStyles.footer}>
          <Stack style={{ flexDirection: 'row', marginTop: 10 }}>
            <SaveButtonWithPermissions
              disabled={
                !plan ||
                (plan && !plan.title) ||
                (plan && !plan.style_of_home) ||
                (plan && !plan.type_of_home) ||
                (plan && !plan.type_of_roof) ||
                (plan && !plan.house_area) ||
                (plan && !plan.facade_width) ||
                (plan && !plan.rooms)
              }
              save={savePlan}
              permission='write:plans'
            />

            <StackItem>
              <DefaultButton onClick={dismissPanel}>Annuleren</DefaultButton>
            </StackItem>
          </Stack>
        </div>
      </div>
    </Panel>
  );
};

export default PlanDetail;
