/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DefaultButton,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useParams } from 'react-router';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { ICustomerInvoice } from '../../../utils/CustomerInvoice';
import { cancelIcon, iconButtonStyles, stackTokens5 } from '../../../utils';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { displayNameLead } from '../../../utils/Lead';
import { useAppDispatch } from '../../../redux/hooks';
import CustomerContractUpdatesDetailsList from '../../customer-contract-update/components/CustomerContractUpdatesDetailsList';
import { GET_TAX_CODES } from '../../../utils/TaxCode';
import CustomerInvoiceDetail from './CustomerInvoiceDetail';
import CustomerInvoiceDownloadTemplate from './CustomerInvoiceDownloadTemplate';
import CustomerInvoicesDetailsList from './CustomerInvoicesDetailsList';
import CustomerInvoicesDynamicPdf from './CustomerInvoiceDynamicPdf';

interface Props {
  customerInvoices: ICustomerInvoice[],
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  setSorting: (isSortedAsc: boolean, sortedField: string) => void,
  isSortedAsc?: boolean,
  sortedField?: string,
  showLoadMore?: boolean,
  downloadFile: () => Promise<void>,
  refetch: (variables?: Partial<{
    filter: any;
    take: number;
    skip: number;
    orderBy: any;
}> | undefined) => Promise<ApolloQueryResult<any>>
}

const CustomerInvoicesOverview = ({
  customerInvoices,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  downloadFile,
  refetch,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [customerInvoice, setCustomerInvoice] = useState<
    ICustomerInvoice | undefined
  >();
  const [taxCodes, setTaxCodes] = useState([]);

  const [showPdf, setShowPdf] = useState(false);

  const { id: customerInvoiceId } = useParams();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setCustomerInvoice(currentSelection[0]);
    } else {
      setCustomerInvoice(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCustomerInvoiceDetail = (newCustomerInvoice?: boolean) => {
    if (newCustomerInvoice) {
      setCustomerInvoice(undefined);
    }
    setIsPanelOpen(true);
  };

  useQuery(GET_TAX_CODES, {
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setTaxCodes(x.findManyTaxCodes);
    },
  });

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${customerInvoices.length} / ${customerInvoices.length > 0 ? customerInvoices[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerInvoiceDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerInvoiceDetail(),
      theme: commandBarTheme,
      disabled: !customerInvoice || !customerInvoice.id,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'PDF' },
      onClick: () => setShowPdf(true),
      theme: commandBarTheme,
      disabled: !customerInvoice || !customerInvoice.id,
    },
    {
      key: 'downloadExcel',
      text: 'Excel',
      iconProps: { iconName: 'ExcelDocument' },
      onClick: () => downloadFile(),
      theme: commandBarTheme,
      // disabled: !customerInvoice || !customerInvoice.id,
    },
  ];

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (customerInvoiceId) {
      setIsPanelOpen(true);
    }
  }, []);

  useEffect(() => {
    if (customerInvoice && isPanelOpen) {
      document.title = `3bouw | Factuur - ${displayNameLead(
        customerInvoice.lead,
      )}`;
    } else {
      document.title = '3bouw | Uitgaande facturen';
    }
  }, [customerInvoice, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      {isPanelOpen && (
        <CustomerInvoiceDetail
          isOpen={isPanelOpen}
          customerInvoiceId={customerInvoiceId ? +customerInvoiceId : undefined}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          customerInvoiceSource={customerInvoice || {}}
          tax_codes={taxCodes}
        />
      )}

      {customerInvoices && (
        <>
          <CustomerInvoicesDetailsList
            items={customerInvoices}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openCustomerInvoiceDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMoreCallback()} />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerInvoices.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}

      {showPdf && customerInvoice && (
        <CustomerInvoicesDynamicPdf customerInvoiceSource={customerInvoice} setShowPdf={setShowPdf} />
      )}
    </>
  );
};

export default CustomerInvoicesOverview;
