import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { toast } from 'react-toastify';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  IContactPerson,
  ISuggestedValue,
} from '../../../utils';
import {
  ISuggestedValueCategory,
  ADD_SUGGESTED_VALUE_CATEGORY,
  UPDATE_SUGGESTED_VALUE_CATEGORY,
  GET_SUGGESTED_VALUE_CATEGORY_BY_ID,
} from '../../../utils/SuggestedValueCategory';
import { toastError, toastSuccess } from '../../../utils/toast';
import SuggestedValueCategoryDetail from './SuggestedValueCategoryDetail';

interface ISuggestedValueCategoryOverview {
  suggestedValueCategories: ISuggestedValueCategory[];
  loading: boolean;
  loadMore: () => void;
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc: boolean;
  sortedField: any;
  showLoadMore: boolean;
  refetch: () => void;
}

const SuggestedValueCategoryOverview: React.FC<
  ISuggestedValueCategoryOverview
> = ({
  suggestedValueCategories,
  loading,
  loadMore,
  setSorting,
  isSortedAsc = false,
  sortedField,
  showLoadMore,
  refetch,
}) => {
  const [suggestedValueCategory, setSuggestedValueCategory] = useState<
    ISuggestedValueCategory | undefined
  >(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [addSuggestedValueCategory] = useMutation(ADD_SUGGESTED_VALUE_CATEGORY);
  const [modifySuggestedValueCategory] = useMutation(
    UPDATE_SUGGESTED_VALUE_CATEGORY,
  );

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ISuggestedValueCategory | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const sortSuggestedValues = (input: ISuggestedValueCategory) => {
    const updatedObject = _.cloneDeep(input);

    if (updatedObject.suggested_values) {
      updatedObject.suggested_values.sort((a, b) => {
        if ((a.suggested_value || 0) < (b.suggested_value || 0)) {
          return -1;
        }
        if ((a.suggested_value || 0) > (b.suggested_value || 0)) {
          return 1;
        }
        return 0;
      });
    }

    return updatedObject;
  };

  useQuery(GET_SUGGESTED_VALUE_CATEGORY_BY_ID, {
    // notifyOnNetworkStatusChange: true,
    variables: selectionDetails
      ? { where: { id: selectionDetails.id } }
      : undefined,
    skip: !selectionDetails,
    onCompleted: data => {
      setSuggestedValueCategory(
        sortSuggestedValues(data.findOneSuggestedValueCategory),
      );
    },
  });

  const openSuggestedValueCategoryDetail = (newSuggestedValue?: boolean) => {
    if (selectionDetails && !newSuggestedValue) {
      // getSuggestedValueCategory({
      //   variables: { where: { id: selectionDetails.id } },
      // });
    } else {
      setSuggestedValueCategory(undefined);
    }
    setIsPanelOpen(true);
  };

  const saveSuggestedValueCategory = async () => {
    try {
      if (suggestedValueCategory) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const drafts =
              suggestedValueCategory.suggested_values &&
              suggestedValueCategory.suggested_values
                .filter((item: ISuggestedValue) => item.draft && item.dirty)
                .map((item: ISuggestedValue) => ({
                  active: item.active,
                  suggested_value: item.suggested_value,
                  suggested_value_code: item.suggested_value_code,
                }));

            const updates: any[] = suggestedValueCategory.suggested_values
              ? suggestedValueCategory.suggested_values
                  .filter((item: ISuggestedValue) => !item.draft && item.dirty)
                  .map((item: ISuggestedValue) => ({
                    where: { id: item.id },
                    data: {
                      active: item.active,
                      suggested_value: item.suggested_value,
                    },
                  }))
              : [];

            const deletes = suggestedValueCategory.suggested_values
              ? suggestedValueCategory.suggested_values
                  .filter((item: ISuggestedValue) => item.delete && !item.draft)
                  .map((item: ISuggestedValue) => ({
                    where: { id: item.id },
                    data: {
                      active: false,
                      // suggested_value: item.suggested_value,
                    },
                  }))
              : [];

            if (suggestedValueCategory.draft || !suggestedValueCategory.id) {
              const createInput = {
                category_code: suggestedValueCategory.category_code,
                category_description:
                  suggestedValueCategory.category_description,
                suggested_value_code_prefix:
                  suggestedValueCategory.suggested_value_code_prefix,
                suggested_value_with_code: true,
                suggested_values: {
                  create: drafts,
                },
              };

              addSuggestedValueCategory({
                variables: {
                  data: createInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  setIsPanelOpen(false);
                  await refetch();
                  resolve(data);
                },
              });
            } else {
              const updateInput = {
                category_description:
                  suggestedValueCategory.category_description,
                suggested_values: {
                  create: drafts,
                  update: updates.concat(deletes),
                },
              };

              modifySuggestedValueCategory({
                variables: {
                  id: suggestedValueCategory.id,
                  data: updateInput,
                },
                onError: error => {
                  setIsPanelOpen(false);
                  reject(error);
                },
                onCompleted: async data => {
                  setIsPanelOpen(false);
                  await refetch();
                  resolve(data);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Voorgestelde waarde opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Sorting
  const onColumnClick = (event: any, column: any) => {
    const sortedColumn = columns.filter((col: IColumn) => col.isSorted);
    let fieldName = '';
    let sortDescending = false;

    if (sortedColumn) {
      const newColumns = columns.map((col: IColumn) => {
        if (col.fieldName === column.fieldName) {
          col.isSorted = true;

          col.isSortedDescending =
            column.fieldName !== sortedColumn[0].fieldName
              ? false
              : !col.isSortedDescending;

          fieldName = column.fieldName;
          sortDescending = col.isSortedDescending;
        } else {
          col.isSorted = false;
        }
        return col;
      });
      setColumns(newColumns);

      setSorting(!sortDescending, fieldName);
    }
  };

  // Columns
  const columnsList: IColumn[] = [
    {
      key: 'column1',
      name: 'Omschrijving',
      fieldName: 'description',
      minWidth: 200,
      // maxWidth: 350,
      isRowHeader: true,
      isSorted: true,
      onColumnClick,
      onRender: (item: ISuggestedValueCategory) => (
        <span>{item.category_description}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Code',
      fieldName: 'code',
      minWidth: 300,
      // maxWidth: 350,
      isRowHeader: true,
      onRender: (item: ISuggestedValueCategory) => (
        <span>{item.category_code}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    // {
    //   key: 'column3',
    //   name: 'Primaire contactpersoon',
    //   fieldName: 'contact',
    //   minWidth: 150,
    //   maxWidth: 350,
    //   isRowHeader: true,
    //   onRender: (supplier: ISuggestedValueCategory) => <span>{supplier.contact}</span>,
    //   data: 'string',
    //   isPadded: true,
    // },
    {
      key: 'column4',
      name: 'Prefix',
      fieldName: 'prefix',
      minWidth: 300,
      // maxWidth: 350,
      isRowHeader: true,
      onRender: (item: ISuggestedValueCategory) => (
        <span>{item.suggested_value_code_prefix}</span>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

  const initColumns = (sortedField: string, isSortedAsc: boolean) =>
    columnsList.map((column: IColumn) => {
      if (column.fieldName === sortedField) {
        column.isSorted = true;
        column.isSortedDescending = !isSortedAsc;
        return column;
      }
      return column;
    });

  const [columns, setColumns] = useState(initColumns(sortedField, isSortedAsc));

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openSuggestedValueCategoryDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openSuggestedValueCategoryDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
  ];

  // Hooks
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadMore();
      }
    });
  });

  // Other
  const getKey = (item: any) => {
    if (item) return item.id;
    return null;
  };

  useEffect(() => {
    if (suggestedValueCategory && isPanelOpen) {
      document.title = `3bouw | Voorgestelde waarde - ${suggestedValueCategory.category_description}`;
    } else {
      document.title = '3bouw | Voorgestelde waarden';
    }
  }, [suggestedValueCategory, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1034px'
        maxWidth='1034px'
      />

      <SuggestedValueCategoryDetail
        isOpen={isPanelOpen}
        dismissPanel={() => {
          setIsPanelOpen(false);
        }}
        suggestedValueCategory={suggestedValueCategory}
        saveSuggestedValueCategory={saveSuggestedValueCategory}
        setSuggestedValueCategory={setSuggestedValueCategory}
      />

      {suggestedValueCategories && (
        <>
          <ShimmeredDetailsList
            items={suggestedValueCategories}
            columns={columns}
            getKey={getKey}
            enableShimmer={loading}
            ariaLabelForShimmer='Content is being fetched'
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
            // onItemInvoked={goToDetail}
            selection={selection}
            selectionMode={SelectionMode.single}
            selectionPreservedOnEmptyClick
            onItemInvoked={() => openSuggestedValueCategoryDetail(false)}
          />

          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton text='Toon meer' onClick={() => loadMore()} />
            </Stack>
          )}
        </>
      )}
      {!loading && !showLoadMore && suggestedValueCategories.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default SuggestedValueCategoryOverview;
