/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import {
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  ADD_CUSTOMER_CONTRACT_UPDATE,
  GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
  UPDATE_CUSTOMER_CONTRACT_UPDATE,
  ICustomerContractUpdate,
  GET_CUSTOMER_CONTRACT_UPDATES,
} from '../../../utils/CustomerContractUpdate';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import {
  cancelIcon,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  iconButtonStyles,
  stackTokens5,
} from '../../../utils';
import useDebounce from '../../../components/hooks/useDebounce';
import { useAppDispatch } from '../../../redux/hooks';
import { dismissNotification } from '../../../redux/notification/notificationSlice';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { toastSuccess, toastError } from '../../../utils/toast';
import { ITaxCode } from '../../../utils/TaxCode';
import CustomerContractUpdateDownloadTemplate from './CustomerContractUpdateDownloadTemplate';
import CustomerContractUpdateDetail from './CustomerContractUpdateDetail';
import CustomerContractUpdatesDetailsList from './CustomerContractUpdatesDetailsList';
import CustomerContractUpdateDynamicPdf from './CustomerContractUpdateDynamicPdf';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  customerContractUpdates: ICustomerContractUpdate[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  taxCodes: ITaxCode[];
}

const CustomerContractUpdatesOverview = ({
  customerContractUpdates,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  taxCodes,
  loadMoreCallback = () => ({}),
}: Props) => {
  const dispatch = useAppDispatch();

  const { id: customerContractUpdateId } = useParams();

  const [customerContractUpdate, setCustomerContractUpdate] = useState<
    ICustomerContractUpdate | undefined
  >(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [constructionSites, setConstructionSites] = useState([]);

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const [addCustomerContractUpdate] = useMutation(ADD_CUSTOMER_CONTRACT_UPDATE);
  const [modifyCustomerContractUpdate] = useMutation(
    UPDATE_CUSTOMER_CONTRACT_UPDATE,
  );

  useQuery(GET_CONSTRUCTION_SITES, {
    fetchPolicy: 'no-cache',
    variables: {
      take: 20,
      filter: {
        name: {
          contains: debouncedFilter,
        },
      },
    },
    onCompleted: (x: any) => {
      setConstructionSites(x.findManyConstructionSites);
    },
  });

  // Selection
  const [selectionDetails, setSelectionDetails] = useState<
    ICustomerContractUpdate | undefined
  >();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();

    if (currentSelection.length > 0) {
      setSelectionDetails(currentSelection[0]);
    } else {
      setSelectionDetails(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openCustomerContractUpdateDetail = (
    newCustomerContractUpdate?: boolean,
  ) => {
    if (newCustomerContractUpdate) {
      setCustomerContractUpdate({
        intro: 'Verrekeningen/ aanpassingen tov de aannemingsovereenkomst',
      });
    }
    setIsPanelOpen(true);
  };

  const { refetch: refetchCustomerContractUpdate } = useQuery(
    GET_CUSTOMER_CONTRACT_UPDATE_BY_ID,
    {
      // notifyOnNetworkStatusChange: true,
      variables: {
        where: {
          id: selectionDetails
            ? selectionDetails.id
            : parseInt(customerContractUpdateId!, 10),
        },
      },
      skip: !selectionDetails && !customerContractUpdateId,
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setCustomerContractUpdate(data.findOneCustomerContractUpdate);
      },
    },
  );

  useEffect(() => {
    if (customerContractUpdateId) {
      setIsPanelOpen(true);
    }
  }, []);

  const saveCustomerContractUpdate = async () => {
    try {
      if (customerContractUpdate) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput = {
              status: customerContractUpdate.status || 'NEW',
              summary: customerContractUpdate.summary,
              intro: customerContractUpdate.intro,
              outro: customerContractUpdate.outro,
              construction_site: {
                connect: {
                  id:
                    customerContractUpdate.construction_site &&
                    customerContractUpdate.construction_site.id,
                },
              },
              customer_invoice_split_customer_contract_updates: undefined,
            };

            if (customerContractUpdate.id) {
              modifyCustomerContractUpdate({
                variables: {
                  id: customerContractUpdate.id,
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            } else {
              addCustomerContractUpdate({
                variables: {
                  data: allInput,
                },
                onError: error => {
                  reject(error);
                },
                onCompleted: x => {
                  resolve(x);
                },
              });
            }
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        const x = await (res as any);
        if (x && x.createCustomerContractUpdate) {
          setCustomerContractUpdate(x.createCustomerContractUpdate);
        } else {
          setIsPanelOpen(false);
        }
        toastSuccess('Min-/meerwerk opgeslagen');
      }
    } catch (error: any) {
      setIsPanelOpen(false);
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${customerContractUpdates.length} / ${
        customerContractUpdates.length > 0
          ? customerContractUpdates[0].prisma_total
          : 0
      }`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      onClick: () => openCustomerContractUpdateDetail(true),
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => openCustomerContractUpdateDetail(),
      theme: commandBarTheme,
      disabled: !selectionDetails,
    },
    {
      key: 'download',
      text: 'PDF',
      iconProps: { iconName: 'Download' },
      onClick: () => setShowPdf(true),
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    if (customerContractUpdate && isPanelOpen) {
      document.title = `3bouw | Min-/meerwerk - ${customerContractUpdate.document_no}`;
    } else {
      document.title = '3bouw | Min- en meerwerken';
    }
  }, [customerContractUpdate, isPanelOpen]);

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      {isPanelOpen && (
        <CustomerContractUpdateDetail
          isOpen={isPanelOpen && !!customerContractUpdate}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          customerContractUpdateSource={customerContractUpdate || {}}
          taxCodes={taxCodes}
        />
      )}
      {customerContractUpdates && (
        <>
          <CustomerContractUpdatesDetailsList
            items={customerContractUpdates}
            selection={selection}
            enableShimmer={!loading}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openCustomerContractUpdateDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && customerContractUpdates.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
      {showPdf && customerContractUpdate && (
        <CustomerContractUpdateDynamicPdf
          customerContractUpdateSource={customerContractUpdate}
          setShowPdf={setShowPdf}
        />
      )}
    </>
  );
};

export default CustomerContractUpdatesOverview;
