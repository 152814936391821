import {
 DetailsList, DetailsListLayoutMode, Icon, Panel, PanelType, SelectionMode, Stack,
} from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import { useMutation } from '@apollo/client';
import {
 DELETE_TIMESHEET_ENTRY, GET_GROUPED_TIMESHEET_ENTRIES, IGroupedEntries, ITimesheetEntry,
} from '../../../utils/TimesheetEntry';
import SuggestedViewStatus from './SuggestedViewStatus';

type Props = {
    groupedTimesheet: IGroupedEntries[];
    setShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
    filter: any;
}

const DoubleEntryPanel: React.FC<Props> = ({ groupedTimesheet, setShowPanel, filter }) => {
  const [deleteTimesheetEntry] = useMutation(DELETE_TIMESHEET_ENTRY, {
    refetchQueries: [
        {
          query: GET_GROUPED_TIMESHEET_ENTRIES,
          variables: {
            filter,
          },
        },
      ],
  });
  const columns = [
    {
      key: 'column1',
      name: 'Medewerker',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <span>
          {item && item.employee ? `${item.employee.first_name} ${item.employee.last_name}` : ''}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Post',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <span>{item && item.cost_center && item.cost_center.name}</span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Werf/Klant',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <>
          <span>{item.construction_site && item.construction_site.name}</span>
          <span>{!item.construction_site && item.lead && `${item.lead.first_name1} ${item.lead.last_name1}`}</span>
        </>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Status',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <span>
          {item.status &&
            (
              <SuggestedViewStatus
                item={item}
                setItem={() => ({})}
                code={
                  item &&
                  item.status &&
                  item.status.substr(
                  item.status.length - 4,
                )}
                timesheetLine={0}
                disabled
              />
            )}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'Uren',
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <span>
          {item.hours}
        </span>
      ),
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column6',
      name: '',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: false,
      onColumnClick: () => {},
      onRender: (item: ITimesheetEntry) => (
        <button
          type='button'
          onClick={() => { deleteTimesheetEntry({ variables: { where: { id: item.id } } }); }}
          style={{
 background: 'transparent', border: 'none', outline: 'none', cursor: 'pointer',
}}
        >
          <Icon iconName='trash' />
        </button>
      ),
      data: 'string',
      isPadded: true,
    },
  ];

return (
  <Panel
    isLightDismiss
    isOpen
    onDismiss={() => { setShowPanel(false); }}
    closeButtonAriaLabel='Close'
    headerText='Dubbele ingaves'
    type={PanelType.large}
  >
    <Stack>
      {groupedTimesheet && groupedTimesheet.length > 0 && groupedTimesheet.map((item, index) => (
        <Stack key={`entry_${index}`}>
          <Stack styles={{
root: {
backgroundColor: 'rgb(239, 239, 239)', padding: '10px', marginBottom: '10px', marginTop: index < groupedTimesheet.length ? '10px' : undefined,
},
}}
          ><strong>{moment(item.entry_date).format('dd DD-MM-YYYY')}</strong> {item && item.employee && `${item.employee.first_name} ${item.employee.last_name}`}
          </Stack>
          <DetailsList
            items={item.entries}
            compact
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible
          />
        </Stack>
        ))}
    </Stack>
  </Panel>
    );
};

export default DoubleEntryPanel;
