import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Dialog,
  DialogFooter,
  Fabric,
  ICommandBarItemProps,
  Icon,
  PrimaryButton,
  Selection,
  SelectionMode,
  TextField,
} from '@fluentui/react';
import {
  columnStyles,
  copyAndSort,
  dialogModelProps,
  dialogRemoveConfirmationProps,
  iconLastProps,
  iconProps,
  ITemplate,
  overviewControlStyles,
} from '../../../utils';
import { DELETE_TEMPLATE } from '../../../utils/Template';
import { commandBarTheme } from '../../../theme';
import { CommandBarSticky } from '../../../components/parts';
import { toastError, toastSuccess } from '../../../utils/toast';

function TemplatesOverview({
  templates,
  updateContents,
  setUpdatedContents,
}: any) {
  const navigate = useNavigate();

  const gotoEdit = (id?: number) => {
    if (id) {
      navigate(`/template/${id}`);
    } else if (selection.length === 1) {
      navigate(`/template/${(selection[0] as ITemplate).id}`);
    }
  };

  const gotoDelete = (id?: number) => {
    if (id) {
      setSelectedId(id);
      toggleConfirmationDialog();
    } else if (selection.length === 1) {
      const tempID = (selection[0] as ITemplate).id;

      if (tempID) {
        setSelectedId(tempID);
      }

      toggleConfirmationDialog();
    }
  };

  const [items, setItems] = useState(templates);
  const [isConfirmationHidden, setIsConfirmationHidden] = useState(true);
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    onCompleted: () => {
      toastSuccess('Sjabloon verwijderd');
    },
    onError: (error) => {
      toastError(error.message);
    },
  });
  const [selectedId, setSelectedId] = useState(0);
  const [filterString, setFilterString] = useState('');

  // It is toggle and clear selected value actually :-)
  const toggleConfirmationDialog = () => {
    setIsConfirmationHidden(!isConfirmationHidden);
    if (!isConfirmationHidden) setSelectedId(0);
  };

  const removeDialog = () => {
    deleteTemplate({
      variables: {
        where: {
          id: selectedId,
        },
      },
    }).then(() => {
      updateContents().then((x: any) => {
        // setItems(x.data.findManyTemplates);
        setUpdatedContents(x.data.findManyTemplates);
        toggleConfirmationDialog();
      });
    });
  };

  const sortColumn = (event: any, column: any) => {
    // This interpretation requires just a single loop.
    // Copy or rework for others.
    let fieldName = '';
    let sortDescending = false;

    const newColumns = initialColumns.map((col: any) => {
      if (col.key === column.key) {
        col.isSortedDescending = !col.isSortedDescending;
        col.isSorted = true;

        fieldName = col.fieldName;
        sortDescending = col.isSortedDescending;
      } else {
        col.isSortedDescending = false;
        col.isSorted = false;
      }
      return col;
    });

    const newItems = copyAndSort(items, fieldName, sortDescending);
    setColumns(newColumns);
    setItems(newItems);
  };

  const initialColumns = [
    {
      key: 'column1',
      name: 'Naam',
      fieldName: 'name',
      minWidth: 800,
      maxWidth: 800,
      isRowHeader: true,
      isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van A tot Z',
      sortDescendingAriaLabel: 'Sorteer van Z tot A',
      onColumnClick: sortColumn,
      onRender: (item: ITemplate) => item.name,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Acties',
      className: columnStyles.centerContents,
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 50,
      isMultiline: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorteer van A tot Z',
      sortDescendingAriaLabel: 'Sorteer van Z tot A',
      onColumnClick: () => {},
      // eslint-disable-next-line react/no-unstable-nested-components
      onRender: (item: ITemplate) => (
        <div>
          <Icon
            iconName='Edit'
            styles={iconProps}
            onClick={() => gotoEdit(item.id)}
          />
          <Icon
            iconName='Delete'
            styles={iconLastProps}
            onClick={() => gotoDelete(item.id)}
          />
        </div>
      ),
      data: 'number',
      isPadded: true,
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const [selection, setSelection] = useState<any[]>([]);

  const onSelectionChanged = () => {
    const sel = selectionTemp.getSelection();
    setSelection(sel);
  };

  const selectionTemp = new Selection({
    onSelectionChanged,
  });

  const onTextChange = (ev: any, text: any): void => {
    setFilterString(text);
    setItems(
      text
        ? templates.filter(
            (template: ITemplate) =>
              template.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
          )
        : templates,
    );
  };

  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'new',
      text: 'Nieuw',
      iconProps: { iconName: 'Add' },
      href: '/template/add',
      theme: commandBarTheme,
    },
    {
      key: 'modify',
      text: 'Wijzig',
      iconProps: { iconName: 'Edit' },
      onClick: () => gotoEdit(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'delete' },
      onClick: () => gotoDelete(),
      disabled: selection.length < 1,
      theme: commandBarTheme,
    },
  ];

  const commandBarFaritems: ICommandBarItemProps[] = [
    {
      key: 'manage',
      text: 'Beheer items',
      iconProps: { iconName: 'BulletedList' },
      href: '/templates/manage-items',
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    (() => {
      setItems(templates);
    })();
  }, [templates]);

  document.title = '3bouw | Werf sjablonen';

  return (
    <Fabric>
      {/* <CommandBar
                items={commandBaritems}
                farItems={commandBarFaritems}
                ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
                theme={commandBarTheme} /> */}
      <TextField
        label='Filter op naam:'
        value={filterString}
        onChange={onTextChange}
        styles={overviewControlStyles}
      />

      <CommandBarSticky
        items={commandBaritems}
        farItems={commandBarFaritems}
        ariaLabel="Gebruik de pijltjes toetsen om tussen de verschillende commando's te navigeren."
        theme={commandBarTheme}
        width='986px'
        maxWidth='986px'
      />

      <DetailsList
        items={items}
        compact
        columns={columns}
        selection={selectionTemp}
        selectionMode={SelectionMode.single}
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        listProps={{
          renderedWindowsAhead: 15,
          renderedWindowsBehind: 15,
        }}
        onItemInvoked={() => gotoEdit()}
      />

      <Dialog
        hidden={isConfirmationHidden}
        onDismiss={toggleConfirmationDialog}
        dialogContentProps={dialogRemoveConfirmationProps}
        modalProps={dialogModelProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={removeDialog} text='Verwijderen' />
          <DefaultButton onClick={toggleConfirmationDialog} text='Annuleren' />
        </DialogFooter>
      </Dialog>
    </Fabric>
  );
}

export default TemplatesOverview;
