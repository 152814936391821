import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { IPurchaseOrder } from '../../../utils';
import PurchaseOrderDownloadTemplate from './PurchaseOrderDownloadTemplate';

const purchaseOrderDownload = async (purchaseOrder?: IPurchaseOrder) => {
  if (purchaseOrder) {
    const blob = await pdf(
      <PurchaseOrderDownloadTemplate purchaseOrder={purchaseOrder} />,
    ).toBlob();
    saveAs(
      blob,
      `Bestelbon ${purchaseOrder.description ? `- ${purchaseOrder.description}` : ''} - ${purchaseOrder.construction_site?.name} .pdf`,
    );
  }
};

export default purchaseOrderDownload;
