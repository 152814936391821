import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  DetailsListLayoutMode,
  IColumn,
  ICommandBarItemProps,
  PrimaryButton,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from '@fluentui/react';
import moment from 'moment';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { IIncomingInvoice } from '../../../utils/IncomingInvoice';
import { CommandBarSticky } from '../../../components/parts';
import { commandBarTheme, commandBarThemeCounter } from '../../../theme';
import { GET_SUPPLIERS } from '../../../utils';
import { SeverityLevel, throwError } from '../../../redux/error/errorSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { GET_CARS } from '../../../utils/Car';
import { GET_COST_CENTERS, convertCostCentersToComboBoxOptions } from '../../../utils/CostCenter';
import {
  Filter,
  FilterPanel,
  FilterState,
  initFilterPanelState,
} from '../../../components/parts/FilterPanel';
import IncomingInvoiceDetail from './IncomingInvoiceDetail';
import IncomingInvoicesDetailsList from './IncomingInvoicesDetailsList';
import IncomingInvoiceIssueDetail from './IncomingInvoiceIssueDetail';

interface Props {
  loadMore?: boolean;
  lazyLoading?: boolean;
  loading?: boolean;
  loadMoreCallback?: () => void;
  incomingInvoices: IIncomingInvoice[];
  setSorting: (isSortedAsc: boolean, sortedField: string) => void;
  isSortedAsc?: boolean;
  sortedField?: string;
  showLoadMore?: boolean;
  setAdvancedFilter: React.Dispatch<
    React.SetStateAction<FilterState | undefined>
  >;
  isFilterPanelOpen: boolean;
  setIsFilterPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAlertPanelOpen: boolean;
  setIsAlertPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IncomingInvoicesOverview = ({
  incomingInvoices,
  loading,
  loadMore,
  setSorting = (isSortedAsc: boolean, sortedField: string) => {},
  isSortedAsc = false,
  sortedField = '',
  showLoadMore,
  lazyLoading,
  loadMoreCallback = () => ({}),
  setAdvancedFilter,
  isFilterPanelOpen,
  setIsFilterPanelOpen,
  isAlertPanelOpen,
  setIsAlertPanelOpen,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [incomingInvoice, setIncomingInvoice] = useState<
    IIncomingInvoice | undefined
  >();
  const [costCenters, setCostCenters] = useState([]);

  useQuery(GET_COST_CENTERS, {
    variables: {
      filter: {
        customer_specific: {
          equals: true,
        },
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (x: any) => {
      setCostCenters(x.findManyCostCenters);
    },
  });

  const { id: incomingInvoiceId } = useParams();

  const getSelectionDetails = () => {
    const currentSelection: any = selection.getSelection();
    if (currentSelection.length > 0) {
      setIncomingInvoice(currentSelection[0]);
      console.log(currentSelection[0]);
    } else {
      setIncomingInvoice(undefined);
    }
  };

  const selection = new Selection({
    onSelectionChanged: getSelectionDetails,
  });

  const openIncomingInvoiceDetail = (newIncomingInvoice?: boolean) => {
    if (newIncomingInvoice) {
      setIncomingInvoice(undefined);
    }
    setIsPanelOpen(true);
  };

  const openFilter = () => {
    setIsFilterPanelOpen(true);
  };

  const openAlert = () => {
    setIsAlertPanelOpen(true);
  };

  // Command bar
  const commandBaritems: ICommandBarItemProps[] = [
    {
      key: 'totaal',
      text: `${incomingInvoices.length} / ${incomingInvoices.length > 0 ? incomingInvoices[0].prisma_total : 0}`,
      theme: commandBarThemeCounter,
    },
    {
      key: 'modify',
      text: 'Bekijk',
      iconProps: { iconName: 'Edit' },
      onClick: () => openIncomingInvoiceDetail(),
      theme: commandBarTheme,
      disabled: !incomingInvoice || !incomingInvoice.id,
    },
    {
      key: 'filter',
      text: 'Filter',
      iconProps: { iconName: 'Filter' },
      onClick: () => openFilter(),
      theme: commandBarTheme,
    },
    {
      key: 'error',
      text: 'Alerts',
      iconProps: { iconName: 'Error' },
      onClick: () => openAlert(),
      theme: commandBarTheme,
    },
  ];

  useEffect(() => {
    if (incomingInvoiceId) {
      setIsPanelOpen(true);
    }
  }, []);

  useEffect(() => {
    if (incomingInvoice && isPanelOpen) {
      document.title = `3bouw | Factuur - ${incomingInvoice.id}`;
    } else {
      document.title = '3bouw | Uitgaande facturen';
    }
  }, [incomingInvoice, isPanelOpen]);

  const filter_schema: Filter = {
    id: 'incoming_invoices_filter_schema_rev2',
    permission: 'read:leads',
    schema: [
      {
        id: 'section_0',
        label: 'Algemeen',
        width: 100,
        fields: [
          {
            id: 'section_0_0',
            name: 'Start factuurdatum',
            fieldName: 'invoice_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_1',
            name: 'Eind factuurdatum',
            fieldName: 'invoice_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_2',
            name: 'Start vervaldatum',
            fieldName: 'due_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            callback: (fieldValue: string) => ({
              gte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
          {
            id: 'section_0_3',
            name: 'Eind vervaldatum',
            fieldName: 'due_date',
            data: 'date',
            type: 'date',
            width: 49.5,
            callback: (fieldValue: string) => ({
              lte: moment(new Date(fieldValue)).toISOString(),
            }),
          },
        ],
      },
      {
        id: 'section_1',
        label: 'Modules',
        width: 100,
        fields: [
          {
            id: 'section_1_0',
            name: 'Werf',
            fieldName: 'incoming_invoice_line_items',
            data: 'projectList',
            type: 'projectList',
            width: 32.5,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  some: {
                    construction_site_id: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
          {
            id: 'section_1_1',
            name: 'Leverancier',
            fieldName: 'supplier_id',
            data: 'supplierList',
            type: 'supplierList',
            width: 32.5,
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return parseInt(fieldValue, 10);
              }
              return undefined;
            },
          },
          {
            id: 'section_1_2',
            name: 'Factuur bevat post',
            fieldName: 'incoming_invoice_line_items',
            width: 32.5,
            data: 'string',
            type: 'list',
            options: convertCostCentersToComboBoxOptions(
              costCenters,
            ),
            callback: (fieldValue: string) => {
              if (fieldValue && fieldValue !== 'ALLES') {
                return {
                  some: {
                    cost_center_id: parseInt(fieldValue, 10),
                  },
                };
              }
            },
          },
        ],
      },
    ],
  };

  const [filterState, setFilterState] = useState<FilterState>(
    initFilterPanelState(filter_schema),
  );

  return (
    <>
      <CommandBarSticky
        items={commandBaritems}
        theme={commandBarTheme}
        width='1200px'
        maxWidth='1200px'
      />

      {isPanelOpen && (
        <IncomingInvoiceDetail
          isOpen={isPanelOpen}
          incomingInvoiceId={incomingInvoiceId || (incomingInvoice?.id ? incomingInvoice.id : undefined)}
          dismissPanel={() => {
            setIsPanelOpen(false);
          }}
          taxCodes={[]}
        />
      )}

      {isAlertPanelOpen && (
        <IncomingInvoiceIssueDetail
          isOpen={isAlertPanelOpen}
          dismissPanel={() => {
            setIsAlertPanelOpen(false);
          }}
        />
      )}

      {incomingInvoices && (
        <>
          {isFilterPanelOpen && (
            <FilterPanel
              isOpen={isFilterPanelOpen}
              dismissPanel={() => {
                setIsFilterPanelOpen(false);
              }}
              filter={filter_schema}
              setFilter={setAdvancedFilter}
              filterState={filterState}
              setFilterState={setFilterState}
            />
          )}
          <IncomingInvoicesDetailsList
            items={incomingInvoices}
            enableShimmer={!loading}
            selection={selection}
            isSortedAsc={isSortedAsc}
            sortedField={sortedField}
            setSorting={setSorting}
            loadMore={loadMore}
            lazyLoading={lazyLoading}
            loadMoreCallback={loadMoreCallback}
            loading={loading}
            onItemInvoked={() => openIncomingInvoiceDetail(false)}
          />
          {showLoadMore && (
            <Stack
              style={{
                marginTop: '15px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              horizontal
              horizontalAlign='center'
            >
              <PrimaryButton
                text='Toon meer'
                onClick={() => loadMoreCallback()}
              />
            </Stack>
          )}
          <Stack style={{ minHeight: '50px' }} />
        </>
      )}
      {!loading && !showLoadMore && incomingInvoices.length === 0 && (
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Geen resultaten
        </div>
      )}
    </>
  );
};

export default IncomingInvoicesOverview;
