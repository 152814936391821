import { createTheme } from '@fluentui/react';

const theme = {
  palette: {
    themePrimary: '#7ab51d',
    themeLighterAlt: '#f9fcf4',
    themeLighter: '#e7f3d2',
    themeLight: '#d2e9ae',
    themeTertiary: '#aad368',
    themeSecondary: '#88be32',
    themeDarkAlt: '#6ea31a',
    themeDark: '#5d8a16',
    themeDarker: '#456510',
    themeBlue: '#1d9eb5',
    neutralLighterAlt: '#f3f3f3',
    neutralLighter: '#efefef',
    neutralLight: '#e5e5e5',
    neutralQuaternaryAlt: '#d6d6d6',
    neutralQuaternary: '#cccccc',
    neutralTertiaryAlt: '#c4c4c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff', // "#fafafa",
  },
};

export default theme;

export const commandBarTheme = createTheme({
  palette: {
    ...theme.palette,
    white: theme.palette.neutralLighter,
  },
});

export const commandBarThemeCounter = createTheme({
  palette: {
    themePrimary: theme.palette.white,
    themeLighterAlt: theme.palette.white,
    themeLighter: theme.palette.white,
    themeLight: theme.palette.white,
    themeTertiary: theme.palette.white,
    themeSecondary: theme.palette.white,
    themeDarkAlt: theme.palette.white,
    themeDark: theme.palette.white,
    themeDarker: theme.palette.white,
    neutralLighterAlt: theme.palette.white,
    neutralLighter: theme.palette.white,
    neutralLight: theme.palette.white,
    neutralQuaternaryAlt: theme.palette.white,
    neutralQuaternary: theme.palette.white,
    neutralTertiaryAlt: theme.palette.white,
    neutralTertiary: theme.palette.white,
    neutralSecondary: theme.palette.white,
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: theme.palette.white,
  },
});
