import React from 'react';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import Algemenevoorwaarden from '../../../Sjabloon-briefpapier-algemenevoorwaarden.jpg';
import Briefpapier from '../../../Sjabloon-briefpapier.jpg';
import fontSegoe from '../../../fonts/Segoe_UI.ttf';
import fontSegoeBold from '../../../fonts/Segoe_UI_Bold.ttf';
import { IWorkOrder, IWorkOrderLineItem } from '../../../utils/WorkOrder';
import { displayNameLead } from '../../../utils/Lead';
import { parseNumber } from '../../../utils/Numbers';
import { displayNameEmployee } from '../../../utils';

Font.register({
  family: 'Segoe UI',
  fonts: [{ src: fontSegoe }, { src: fontSegoeBold, fontStyle: 'bold' }],
});

const addZero = (number: string) => {
  if (number.includes(',')) {
    const splitNumber = number.split(',');
    if (splitNumber[1].length === 1) return `${number}0`;
    return number;
  }
  return `${number},00`;
};

const WorkOrderDownloadTemplate = ({
  workOrder,
}: {
  workOrder: IWorkOrder;
}) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
        }}
        fixed
      >
        <Image src={Briefpapier} style={{ width: '100%', height: '100%' }} />
      </View>

      {/* Header repeated on every page */}
      <PageHeader workOrder={workOrder} />

      <View style={styles.pageHeader}>
        <PageSupplierInfo workOrder={workOrder} />
      </View>

      <View style={styles.workOrderInfo}>
        <Text>
          <Text style={{ fontStyle: 'bold' }}>Betreft:</Text>{' '}
          {workOrder.description}
        </Text>
        <Text>
          <Text style={{ fontStyle: 'bold' }}>Uitvoering:</Text>{' '}
          {workOrder.employee && displayNameEmployee(workOrder.employee)}
          {workOrder.employee && workOrder.supplier && ' / '}
          {workOrder.supplier && workOrder.supplier.name}
        </Text>
      </View>

      {/* WorkOrder */}
      <PageWorkOrderLines workOrder={workOrder} />

      <View style={styles.flex}>
        <View style={{ width: '48%', flexGrow: 0 }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.flexLeft}>Aantal KM:</Text>
            <Text style={styles.flexRight} />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.flexLeft}>Datum uitvoering:</Text>
            <Text style={styles.flexRight} />
          </View>
        </View>
        <View
          style={{
            width: '48%',
            flexDirection: 'row',
            flexGrow: 0,
            position: 'relative',
          }}
        >
          <Text style={styles.flexLeft}>Totaal:</Text>
          <Text style={styles.flexRight} />
          <Text
            style={{
              position: 'absolute',
              bottom: -16,
              right: 0,
              left: 0,
              textAlign: 'right',
              fontStyle: 'bold',
            }}
          >
            Alle prijzen excl. BTW
          </Text>
        </View>
      </View>

      {/* Footer repeated on every page */}
      <Text style={styles.now} fixed>
        {moment(new Date()).format('DD/MM/YYYY')}
      </Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
        fixed
      />
    </Page>
    {/* Algemene voorwaarden */}
    {/* <Page size='A4' style={styles.page}>
      <View
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100vw',
          height: '100vh',
        }}
        fixed
      >
        <Image
          src={Algemenevoorwaarden}
          style={{ width: '100vw', height: '100vh' }}
        />
      </View>
    </Page> */}
  </Document>
);

const PageHeader = ({ workOrder }: { workOrder: IWorkOrder }) => (
  <View style={styles.pageHeaderWrapper} fixed>
    <View style={styles.pageHeader}>
      {/* <Image src={Logo} style={styles.image} /> */}
      <View>
        <Text style={styles.pageHeaderTitle}>
          Werkbon
          {workOrder.document_no && ` ${workOrder.document_no}`}
        </Text>
        <Text style={styles.pageHeaderName}>
          {workOrder && workOrder.construction_site && (
            <>Werf: {workOrder.construction_site.name}</>
          )}
        </Text>
      </View>
    </View>
  </View>
);

const PageWorkOrderLines = ({ workOrder }: { workOrder: IWorkOrder }) => (
  <View style={styles.table}>
    {/* Table header repeated on every page */}
    <View style={styles.tableRowHeader} fixed>
      <Text style={styles.tableColDescription}>Omschrijving</Text>
      <Text style={styles.tableColQuantity}>Aantal</Text>
      <Text style={styles.tableColUnitPrice}>Eenheidsprijs</Text>
      <Text style={styles.tableColVat}>BTW</Text>
      <Text style={styles.tableColPrice}>Prijs excl BTW</Text>
    </View>

    {/* Lines */}
    {workOrder.defect_work_order_line_items &&
    workOrder.defect_work_order_line_items.length > 0
      ? workOrder.defect_work_order_line_items.map(
          (line: IWorkOrderLineItem, i: number) => (
            <View key={`line-${i}`} style={styles.tableRow} wrap={false}>
              <Text style={styles.tableColDescription}>{line.description}</Text>
              <Text style={styles.tableColQuantity}>{line.units}</Text>
              <Text style={styles.tableColUnitPrice}>
                {line.unit_price
                  ? addZero(
                      `€ ${new Intl.NumberFormat('nl-BE', {
                        maximumFractionDigits: 2,
                      }).format(parseNumber(line.unit_price))}`,
                    )
                  : null}
              </Text>
              <Text style={styles.tableColVat}>
                {line.percentage_vat
                  ? parseNumber(line.percentage_vat) > 1
                    ? `${line.percentage_vat}%`
                    : `${parseNumber(line.percentage_vat) * 100}%`
                  : null}
              </Text>
              <Text style={styles.tableColPrice}>
                {line.unit_price
                  ? addZero(
                      `€ ${new Intl.NumberFormat('nl-BE', {
                        maximumFractionDigits: 2,
                      }).format(
                        parseNumber(line.unit_price) *
                          parseNumber(line.units, 1),
                      )}`,
                    )
                  : null}
              </Text>
            </View>
          ),
        )
      : null}
  </View>
);

const PageSupplierInfo = ({ workOrder }: { workOrder: IWorkOrder }) => (
  <View style={{ paddingTop: '20px' }}>
    <View style={styles.supplierDetailsRow}>
      <View
        style={{
            flexDirection: 'column',
            width: '50%',
            overflow: 'hidden',
          }}
      >
        {workOrder.construction_site && workOrder.construction_site.lead && (
        <Text>
          {displayNameLead(workOrder.construction_site.lead)}
          {'\n'}
          {workOrder.construction_site.address}
          {'\n'}
          {workOrder.construction_site.zip_code}{' '}
          {workOrder.construction_site.city}
        </Text>
          )}
      </View>

      {workOrder.construction_site && workOrder.construction_site.lead && (
      <View style={styles.supplierDetailsRight}>
        <PageSupplierInfoRow
          label='Tel.'
          value={
                workOrder.construction_site.lead &&
                (workOrder.construction_site.lead.mobile1_v2 ||
                  workOrder.construction_site.lead.mobile1 ||
                  workOrder.construction_site.lead.mobile2_v2 ||
                  workOrder.construction_site.lead.mobile2 ||
                  workOrder.construction_site.lead.phone1_v2 ||
                  workOrder.construction_site.lead.phone1 ||
                  workOrder.construction_site.lead.phone2_v2 ||
                  workOrder.construction_site.lead.phone2)
                // (workOrder.construction_site.lead.mobile1_v2 ||
                //   workOrder.construction_site.lead.mobile2_v2 ||
                //   workOrder.construction_site.lead.phone1_v2 ||
                //   workOrder.construction_site.lead.phone2_v2)
              }
        />

        <PageSupplierInfoRow
          label='E-mail'
          value={
                workOrder.construction_site.lead.email1 ||
                workOrder.construction_site.lead.email2
              }
        />
        <PageSupplierInfoRow
          label='Datum'
          value={moment(new Date(workOrder.document_date)).format(
                'DD/MM/YYYY',
              )}
        />
        {workOrder.construction_site.lead.vat && (
        <PageSupplierInfoRow
          label='BTW-nr'
          value={workOrder.construction_site.lead.vat}
        />
            )}
      </View>
        )}
    </View>
  </View>
  );

const PageSupplierInfoRow = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => (
  <View style={styles.supplierDetailsRow}>
    <Text style={styles.supplierDetailsLabelAlt}>{label}:</Text>
    <Text style={styles.supplierDetailsValueAlt}>{value}</Text>
  </View>
);

const styles = StyleSheet.create({
  image: {
    width: '100px',
    objectFit: 'scale-down',
    marginRight: 15,
  },
  invoiceDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  invoiceDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '20%',
  },
  invoiceDetailsValueAlt: {
    width: '80%',
  },
  legalInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 8,
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 140,
    paddingTop: 2,
  },
  legalInfoRow: {
    flexDirection: 'row',
  },
  now: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 55,
    right: 40,
  },
  page: {
    backgroundColor: '#ffffff',
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
    paddingTop: 95,
  },
  pageHeaderWrapper: {
    position: 'absolute',
    top: 20,
    left: 45,
    right: 45,
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  pageHeaderName: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
  },
  pageHeaderTitle: {
    fontFamily: 'Segoe UI',
    fontSize: 15,
    fontStyle: 'bold',
    paddingBottom: 5,
  },
  pageNumber: {
    position: 'absolute',
    fontFamily: 'Segoe UI',
    fontSize: 9,
    bottom: 43,
    left: 0,
    right: 40,
    textAlign: 'right',
  },
  supplierDetailsLabel: {
    fontStyle: 'bold',
    width: '11%',
  },
  supplierDetailsLabelAlt: {
    fontStyle: 'bold',
    width: '20%',
  },
  supplierDetailsRight: {
    width: '49%',
    borderLeft: '1px solid black',
    paddingLeft: 10,
  },
  supplierDetailsRow: {
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
  },
  supplierDetailsValueAlt: {
    width: '80%',
  },
  text: {
    paddingBottom: 15,
    paddingRight: 5,
    fontFamily: 'Segoe UI',
    fontSize: 10,
    lineHeight: '120%',
  },
  table: {
    display: 'flex',
    width: 'auto',
    paddingTop: '15px',
  },
  tableColDescription: {
    width: '55%',
  },
  tableColPrice: {
    width: '15%',
    textAlign: 'right',
    paddingRight: 5,
  },
  tableColVat: {
    width: '5%',
  },
  tableColUnitPrice: {
    width: '15%',
  },
  tableColQuantity: {
    width: '10%',
  },
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#D2D4D1',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    fontFamily: 'Segoe UI',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  tableRowHeader: {
    backgroundColor: '#D2D4D1',
    flexDirection: 'row',
    fontFamily: 'Segoe UI',
    justifyContent: 'space-between',
    fontSize: 10,
    paddingBottom: '3px',
    paddingLeft: '5px',
    paddingTop: '3px',
  },
  workOrderInfo: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
    marginTop: 24,
  },
  flex: {
    display: 'flex',
    fontSize: 11,
    marginTop: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    fontFamily: 'Segoe UI',
    alignItems: 'flex-end',
  },
  flexLeft: { paddingRight: 4, paddingTop: 8, fontStyle: 'bold' },
  flexRight: {
    flexGrow: 1,
    borderBottom: '1px dotted black',
  },
});

export default WorkOrderDownloadTemplate;
