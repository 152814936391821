import { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { stackTokens15, UPDATE_TEMPLATE } from '../../utils';
// import * as _ from 'lodash';
import {
  DELETE_TEMPLATE_ITEM_CHILDREN,
  DELETE_TEMPLATE_ITEM_FROM_TEMPLATE,
  ITemplateItem,
} from '../../utils/TemplateItem';
import { ITemplateTemplateItem } from '../../utils/TemplateTemplateItem';
import { GET_PROPERTY_LIST_TYPES } from '../../utils/PropertyListType';
import { GET_SUGGESTED_VALUE_CATEGORIES } from '../../utils/SuggestedValueCategory';
import {
  ADD_TEMPLATE,
  GET_TEMPLATE_BY_ID,
  ITemplate,
} from '../../utils/Template';
import { GET_PROPERTY_TYPES } from '../../utils/PropertyType';
import { IProperty } from '../../utils/Property';
import { useAppDispatch } from '../../redux/hooks';
import { throwError, SeverityLevel } from '../../redux/error/errorSlice';
import {
  dismissNotification,
  sendNotification,
} from '../../redux/notification/notificationSlice';

import { toastError, toastSuccess } from '../../utils/toast';
import TemplateOverview from './components/TemplateOverview';

export interface ITemplateState {
  template?: ITemplate;
  templateType: string; // use enum? @TP
  selectedItem?: ITemplateItem;
  newItemPositionConstruction: number;
  newItemPositionExecution: number;
  selectedItemParent?: ITemplateItem;
  selectedListType: number;
  // selectedItemAddToExecutionList: boolean;
  // selectedItemAddToConstructionList: boolean;
  selectedItemToBeDeleted?: ITemplateTemplateItem;
  inProgress: boolean;
  hasName: boolean;
  // hasNewItemName: boolean;
  modalOpen: boolean;
  modalModify: boolean;
  searchText?: string;
  confirmationHidden: boolean;
  confirmationCallback?: () => void;
  selectionIncluded: ITemplateTemplateItem[];
  // selectionExcluded: ITemplateTemplateItem[];
  error?: string;
  messageBarOpen?: boolean;
}

const Template = ({ ...props }: any) => {
  // constants
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useParams();

  const templateId = params.id && params.id !== 'add'
    ? +params.id
    : undefined;

  // Hooks
  const [state, setState] = useState<ITemplateState>({
    template: undefined,
    templateType: 'weight_for_execution_list',
    selectedItem: undefined,
    newItemPositionExecution: -1,
    newItemPositionConstruction: -1,
    selectedItemParent: undefined,
    selectedListType: 0,
    inProgress: false,
    hasName: true,
    // hasNewItemName: true,
    modalOpen: false,
    modalModify: false,
    searchText: '',
    confirmationHidden: true,
    confirmationCallback: undefined,
    selectionIncluded: [],
    // selectionExcluded: [],
  });

  const { template, inProgress } = state;

  // Queries
  const { data, loading, error } = useQuery(GET_TEMPLATE_BY_ID, {
    variables: {
      where: {
        id: templateId,
      },
    },
    skip: templateId === undefined,
    onError: (error) => {
      dispatch(
        throwError({
          module: 'template.findOneTemplate',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  useEffect(() => {
    if (data && data.findOneTemplate) {
      setState({ ...state, template: data.findOneTemplate });
    }
  }, [data]);

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery(GET_PROPERTY_TYPES, {
    variables: {
      orderBy: {
        name: 'asc',
      },
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'template.findPropertyTypes',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  // get suggested value categories
  const { error: suggestedCategorieError, data: suggestedCategorieData } = useQuery(GET_SUGGESTED_VALUE_CATEGORIES, {
    variables: {
      // filter: {},
      orderBy: {
        category_description: 'asc',
      },
    },
    onError: (error) => {
      dispatch(
        throwError({
          module: 'template.findSuggestedValueCategories',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
  });

  const { error: errorListTypes, data: dataListTypes } = useQuery(
    GET_PROPERTY_LIST_TYPES,
    {
      variables: {
        orderBy: {
          name: 'asc',
        },
      },
      onError: (error) => {
        dispatch(
          throwError({
            module: 'template.getPropertyListTypes',
            message: error.message,
            level: SeverityLevel.Critical,
          }),
        );
      },
    },
  );

  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    onError: (error) => {
      toastError(error.message);
    },
    onCompleted: (data) => {
      toastSuccess('Sjabloon opgeslagen');
    },
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onError: (error) => {
      toastError(error.message);
    },
    onCompleted: (data) => {
      toastSuccess('Sjabloon opgeslagen');
    },
  });

  // Loading data
  const [deleteTemplateItemChildren] = useMutation(
    DELETE_TEMPLATE_ITEM_CHILDREN,
    {
      onError: (error) => {
        toastError(error.message);
      },
      onCompleted: (data) => {
        toastSuccess('Sjabloon item verwijderd');
      },
    },
  );
  const [deleteTemplateItemFromTemplate] = useMutation(
    DELETE_TEMPLATE_ITEM_FROM_TEMPLATE,
    {
      onError: (error) => {
        toastError(error.message);
      },
      onCompleted: (data) => {
        toastSuccess('Sjabloon item verwijderd');
      },
    },
  );

  // Functions
  const saveNewFields = (item: ITemplateItem) => {
    const mandatoryFields = {
      is_reporting_param: item.is_reporting_param
        ? item.is_reporting_param
        : false,
      name: item.name,
      needs_water_points: item.needs_water_points
        ? item.needs_water_points
        : false,
      type: {
        connect: {
          id: item.type.id,
        },
      },
    };

    let optionalFields = {};

    if (item.nested_type && item.nested_type.id) {
      optionalFields = {
        ...optionalFields,
        nested_type: {
          connect: {
            id: item.nested_type.id,
          },
        },
      };
    }

    if (item.child_pos_construction_sheet) {
      optionalFields = {
        ...optionalFields,
        child_pos_construction_sheet: item.child_pos_construction_sheet,
      };
    }
    if (item.child_pos_execution_list) {
      optionalFields = {
        ...optionalFields,
        child_pos_execution_list: item.child_pos_execution_list,
      };
    }
    if (item.list_types) optionalFields = { ...optionalFields, list_types: item.list_types };
    if (item.reporting_label) {
      optionalFields = {
        ...optionalFields,
        reporting_label: item.reporting_label,
      };
    }
    if (item.value) optionalFields = { ...optionalFields, value: item.value };
    if (item.parent) {
      optionalFields = {
        ...optionalFields,
        parent: { connect: { id: item.parent } },
      };
    }
    if (item.suggested_value_category) {
      optionalFields = {
        ...optionalFields,
        suggested_value_category: {
          connect: {
            id: item.suggested_value_category.id,
          },
        },
      };
    }
    if (item.document) {
      optionalFields = {
        ...optionalFields,
        document: {
          create: {
            name: item.document.name,
            is_external: !!item.document.is_external,
            src: item.document.src ? item.document.src : undefined,
            extension: item.document.extension
              ? item.document.extension
              : undefined,
            blob_name: item.document.blob_name
              ? item.document.blob_name
              : undefined,
          },
        },
      };
    }

    return { ...mandatoryFields, ...optionalFields };
  };

  const saveNewItem = (item: ITemplateTemplateItem) => ({
    weight_for_construction_sheet: item.weight_for_construction_sheet
      ? item.weight_for_construction_sheet
      : 0,
    weight_for_execution_list: item.weight_for_execution_list
      ? item.weight_for_execution_list
      : 0,

    template_item: item.template_item.existing
      ? {
        connect: {
          id: item.template_item.id,
        },
      }
      : saveNewTemplateItem(item.template_item),
  });

  const saveNewPropertyItem = (item: IProperty) => {
    const createPropertyItem = {
      document: item.document
        ? {
          create: {
            name: item.document.name,
            is_external: !!item.document.is_external,
            src: item.document.src ? item.document.src : undefined,
            extension: item.document.extension
              ? item.document.extension
              : undefined,
            blob_name: item.document.blob_name
              ? item.document.blob_name
              : undefined,
          },
        }
        : undefined,
      is_not_applicable: item.is_not_applicable,
      is_ordered: item.is_ordered,
      // list_types:
      name: item.name,
      needs_water_points: item.needs_water_points,
      rain_water_points: item.rain_water_points,
      remote_category: item.remote_category,
      show_in_construction_sheet: item.show_in_construction_sheet,
      show_in_execution_list: item.show_in_execution_list,
      tap_water_points: item.tap_water_points,
      type: {
        connect: {
          id: item.type.id,
        },
      },
      value: item.value,
      weight_for_construction_sheet: item.weight_for_construction_sheet,
      weight_for_execution_list: item.weight_for_execution_list,
      suggested_value: item.suggested_value
        ? {
          connect: {
            id: item.suggested_value.id,
          },
        }
        : undefined,
    };

    return createPropertyItem;
  };

  const saveUpdatedPropertyItem = (item: IProperty) => {
    const createPropertyItem = {
      document: item.document
        ? item.document.draft || !item.document.id
          ? {
            create: {
              name: item.document.name,
              is_external: !!item.document.is_external,
              src: item.document.src ? item.document.src : undefined,
              extension: item.document.extension
                ? item.document.extension
                : undefined,
              blob_name: item.document.blob_name
                ? item.document.blob_name
                : undefined,
            },
          }
          : {
            update: {
              name: item.document.name,
              is_external: !!item.document.is_external,
              src: item.document.src ? item.document.src : undefined,
              extension: item.document.extension
                ? item.document.extension
                : undefined,
              blob_name: item.document.blob_name
                ? item.document.blob_name
                : undefined,
            },
          }
        : undefined,
      is_not_applicable: item.is_not_applicable,
      is_ordered: item.is_ordered,
      // list_types:
      name: item.name,
      needs_water_points: item.needs_water_points,
      rain_water_points: item.rain_water_points,
      remote_category: item.remote_category,
      show_in_construction_sheet: item.show_in_construction_sheet,
      show_in_execution_list: item.show_in_execution_list,
      tap_water_points: item.tap_water_points,
      type: {
        connect: {
          id: item.type.id,
        },
      },
      value: item.value,
      weight_for_construction_sheet: item.weight_for_construction_sheet,
      weight_for_execution_list: item.weight_for_execution_list,
      suggested_value: item.suggested_value
        ? {
          connect: {
            id: item.suggested_value.id,
          },
        }
        : undefined,
    };

    return {
      where: {
        id: item.id,
      },
      data: createPropertyItem,
    };
  };

  const saveNewProperty = (item: IProperty) => {
    let createProperty: any = {};

    if (item.children && item.children.length > 0) {
      const children = item.children.map((child) => saveNewPropertyItem(child));

      if (children && children.length > 0) {
        createProperty.children = {
          create: children,
        };
      }
    }

    createProperty = {
      ...createProperty,
      ...saveNewPropertyItem(item),
    };

    return createProperty;
  };

  const saveUpdatedProperty = (item: IProperty) => {
    let updateProperty: any = {};

    if (item.children && item.children.length > 0) {
      const children = item.children;

      let childrenCreate: any[] = [];
      let childrenUpdate: any[] = [];

      if (children && children.length > 0) {
        childrenCreate = children
          .filter((child) => child.isNew)
          .map((child) => saveNewPropertyItem(child));
        childrenUpdate = children
          .filter((child) => child.isDirty)
          .map((child) => saveUpdatedPropertyItem(child));
      }

      updateProperty.children = {
        create: childrenCreate,
        update: childrenUpdate,
      };
    }

    updateProperty = {
      where: {
        id: item.id,
      },
      data: {
        ...updateProperty,
        ...saveNewPropertyItem(item),
      },
    };

    return updateProperty;
  };

  const saveDeletedProperty = (item: IProperty) => ({ id: item.id });

  const saveNewTemplateItem = (item: ITemplateItem) => {
    const parent = saveNewFields(item);
    let default_multi_value_children: any;

    if (
      item.default_multi_value_children
      && item.default_multi_value_children.length > 0
    ) {
      default_multi_value_children = item.default_multi_value_children
        .filter((child) => !child.delete)
        .map((child: IProperty) => saveNewProperty(child));
    }

    if (item.children && item.children.length > 0) {
      const children = item.children
        .filter((child) => !child.delete)
        .map((child: ITemplateItem) => saveNewFields(child));

      let create_default_multi_value_children: any;

      if (
        default_multi_value_children
        && default_multi_value_children.length > 0
      ) {
        create_default_multi_value_children = {
          create: default_multi_value_children,
        };
      }

      return {
        create: {
          ...parent,
          children: {
            create: children,
          },
          default_multi_value_children: create_default_multi_value_children,
        },
      };
    }

    let create_default_multi_value_children: any;

    if (default_multi_value_children && default_multi_value_children.length > 0) {
      create_default_multi_value_children = {
        create: default_multi_value_children,
      };
    }

    return {
      create: {
        ...parent,
        default_multi_value_children: create_default_multi_value_children,
      },
    };
  };

  const saveTemplate = () => {
    if (state.inProgress) return; // avoid double saves!

    if (template === undefined || !state.hasName) {
      setState({
        ...state,
        hasName: false,
      });
      return;
    }

    setState({
      ...state,
      inProgress: true,
    });

    dispatch(
      sendNotification({
        message: 'bezig met opslaan',
        level: 0,
        module: 'template.updateTemplate',
        spinner: true,
      }),
    );

    if (template) {
      let data = {};

      data = {
        ...data,
        name: template.name,
      };

      if (
        template.template_template_items
        && template.template_template_items.length > 0
      ) {
        const drafts = template.template_template_items
          .filter((item: ITemplateTemplateItem) => !!item.template_item.draft)
          .map((item: ITemplateTemplateItem) => saveNewItem(item));

        const updates = template.template_template_items
          .filter(
            (item: ITemplateTemplateItem) => !item.template_item.draft && item.template_item.dirty,
          )
          .map((item: ITemplateTemplateItem) => saveUpdatedItem(item));

        if ((drafts && drafts.length > 0) || (updates && updates.length > 0)) {
          data = {
            ...data,
            template_template_items: {
              create: drafts && drafts.length > 0 ? drafts : undefined,
              update: updates && updates.length > 0 ? updates : undefined,
            },
          };
        }
      }

      if (template.id && template.id > 0) {
        updateTemplate({
          variables: {
            id: template.id,
            data,
          },
        })
          .then((result: any) => {
            setState({
              ...state,
              template: result.data.updateTemplate,
              inProgress: false,
            });
            dispatch(
              sendNotification({
                module: 'template.updateTemplate',
                message: 'De wijzigingen zijn opgeslagen',
                level: 1,
                timeout: 2500,
              }),
            );
          })
          .catch(() => {
            setState({
              ...state,
              inProgress: false,
            });
            // remove notification -> error will appear
            dispatch(dismissNotification());
          });
      } else {
        addTemplate({
          variables: {
            data,
          },
        })
          .then((result: any) => {
            if (result && result.data && result.data.createTemplate) {
              setState({
                ...state,
                template: result.data.createTemplate,
                inProgress: false,
              });
              dispatch(dismissNotification());
              navigate(`/template/${result.data.createTemplate.id}`, { replace: true });
            } else {
              setState({
                ...state,
                inProgress: false,
              });
            }
          })
          .catch(err => {
            setState({
              ...state,
              inProgress: false,
            });
            dispatch(dismissNotification());
          });
      }
    }
  };

  const saveUpdateChild = (item: ITemplateItem) => ({
    data: saveUpdatedFields(item),
    where: {
      id: item.id,
    },
  });

  const saveDeleteChild = (item: ITemplateItem) => ({
    id: item.id,
  });

  const saveUpdatedFields = (item: ITemplateItem) => {
    let fields = {};

    fields = {
      ...fields,
      type: {
        connect: {
          id: item.type.id,
        },
      },
    };

    if (item.child_pos_construction_sheet) {
      fields = {
        ...fields,
        child_pos_construction_sheet: item.child_pos_construction_sheet,
      };
    }
    if (item.child_pos_execution_list) {
      fields = {
        ...fields,
        child_pos_execution_list: item.child_pos_execution_list,
      };
    }
    if (item.is_reporting_param) fields = { ...fields, is_reporting_param: item.is_reporting_param };
    if (item.name) fields = { ...fields, name: item.name };
    if (item.needs_water_points) fields = { ...fields, needs_water_points: item.needs_water_points };
    if (item.list_types) fields = { ...fields, list_types: item.list_types };
    if (item.reporting_label) fields = { ...fields, reporting_label: item.reporting_label };
    if (item.value) fields = { ...fields, value: item.value };
    if (item.suggested_value_category) {
      fields = {
        ...fields,
        suggested_value_category: {
          connect: {
            id: item.suggested_value_category.id,
          },
        },
      };
    }
    if (item.document && item.document.id && item.document.dirty) {
      fields = {
        ...fields,
        document: {
          update: {
            name: item.document.name,
            is_external: !!item.document.is_external,
            src: item.document.src ? item.document.src : undefined,
            extension: item.document.extension
              ? item.document.extension
              : undefined,
            blob_name: item.document.blob_name
              ? item.document.blob_name
              : undefined,
          },
        },
      };
    } else if (item.document && item.document.draft) {
      fields = {
        ...fields,
        document: {
          create: {
            name: item.document.name,
            is_external: !!item.document.is_external,
            src: item.document.src ? item.document.src : undefined,
            extension: item.document.extension
              ? item.document.extension
              : undefined,
            blob_name: item.document.blob_name
              ? item.document.blob_name
              : undefined,
          },
        },
      };
    }
    return fields;
  };

  const saveUpdatedItem = (item: ITemplateTemplateItem) => ({
    data: {
      weight_for_construction_sheet: item.weight_for_construction_sheet
        ? item.weight_for_construction_sheet
        : 0,
      weight_for_execution_list: item.weight_for_execution_list
        ? item.weight_for_execution_list
        : 0,

      template_item: saveUpdatedTemplateItem(item.template_item),
    },
    where: {
      template_id_template_item_id: {
        template_id: template!.id,
        template_item_id: item.template_item.id,
      },
    },
  });

  const saveUpdatedTemplateItem = (item: ITemplateItem) => {
    // I don't understand the difference between this and saveUpdatedFields.
    // IMO it is the same.
    const mandatoryFields = {
      type: {
        connect: {
          id: item.type.id, // otherwise keep type
        },
      },
    };

    let optionalFields = {};

    if (item.child_pos_construction_sheet) {
      optionalFields = {
        ...optionalFields,
        child_pos_construction_sheet: item.child_pos_construction_sheet,
      };
    }
    if (item.child_pos_execution_list) {
      optionalFields = {
        ...optionalFields,
        child_pos_execution_list: item.child_pos_execution_list,
      };
    }
    if (item.is_reporting_param) {
      optionalFields = {
        ...optionalFields,
        is_reporting_param: item.is_reporting_param,
      };
    }
    if (item.name) optionalFields = { ...optionalFields, name: item.name };
    if (item.needs_water_points) {
      optionalFields = {
        ...optionalFields,
        needs_water_points: item.needs_water_points,
      };
    }
    if (item.list_types) optionalFields = { ...optionalFields, list_types: item.list_types };
    if (item.reporting_label) {
      optionalFields = {
        ...optionalFields,
        reporting_label: item.reporting_label,
      };
    }
    if (item.value) optionalFields = { ...optionalFields, value: item.value };
    if (item.suggested_value_category) {
      optionalFields = {
        ...optionalFields,
        suggested_value_category: {
          connect: {
            id: item.suggested_value_category.id,
          },
        },
      };
    }
    if (item.document) {
      optionalFields = {
        ...optionalFields,
        document: {
          update: {
            name: item.document.name,
            is_external: !!item.document.is_external,
            src: item.document.src ? item.document.src : undefined,
            extension: item.document.extension
              ? item.document.extension
              : undefined,
            blob_name: item.document.blob_name
              ? item.document.blob_name
              : undefined,
          },
        },
      };
    }
    if (item.nested_type) {
      optionalFields = {
        ...optionalFields,
        nested_type: {
          connect: {
            id: item.nested_type.id,
          },
        },
      };
    }

    let default_multi_value_children: any;

    if (
      item.default_multi_value_children
      && item.default_multi_value_children.length > 0
    ) {
      let default_multi_value_children_updated = [];
      let default_multi_value_children_created = [];
      let default_multi_value_children_deleted = [];

      default_multi_value_children_created = item.default_multi_value_children
        .filter((child) => child.isNew && !child.delete)
        .map((child) => saveNewProperty(child));

      default_multi_value_children_updated = item.default_multi_value_children
        .filter((child) => child.isDirty && !child.isNew && !child.delete)
        .map((child) => saveUpdatedProperty(child));

      default_multi_value_children_deleted = item.default_multi_value_children
        .filter((child) => !!child.delete && !child.isNew)
        .map((child) => saveDeletedProperty(child));

      default_multi_value_children = {
        create: default_multi_value_children_created,
        update: default_multi_value_children_updated,
        delete: default_multi_value_children_deleted,
      };
    }

    let children;

    if (item.children && item.children.length > 0) {
      const childrenNew = item.children
        .filter((child: ITemplateItem) => child.draft && !child.delete)
        .map((child: ITemplateItem) => saveNewFields(child));
      const childrenUpdated = item.children
        .filter(
          (child: ITemplateItem) => child.dirty && !child.draft && !child.delete,
        )
        .map((child: ITemplateItem) => saveUpdateChild(child));

      const childrenDeleted = item.children
        .filter(
          (child: ITemplateItem) => child.dirty && child.delete && !child.draft,
        )
        .map((child: ITemplateItem) => saveDeleteChild(child));

      children = {
        create: childrenNew,
        update: childrenUpdated,
        delete: childrenDeleted,
      };
    }

    return {
      update: {
        ...mandatoryFields,
        ...optionalFields,
        default_multi_value_children,
        children,
      },
    };
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error || errorTypes || suggestedCategorieError || errorListTypes) {
    return <p>Er ging iets fout :(</p>;
  }

  // if (!state || !state.template) return <p>Laden...</p>;

  document.title = `3bouw | Sjabloon - ${state.template?.name}`;

  return (
    <Stack tokens={stackTokens15}>
      <h3>Werf sjabloon</h3>
      <TemplateOverview
        templateState={state}
        setTemplateState={setState}
        propertyTypes={dataTypes ? dataTypes.findManyPropertyTypes : []}
        saveTemplate={saveTemplate}
        deleteTemplateItemChildren={deleteTemplateItemChildren}
        deleteTemplateItemFromTemplate={deleteTemplateItemFromTemplate}
        listTypes={
          dataListTypes ? dataListTypes.findManyPropertyListTypes : undefined
        }
        suggestedValueCategories={
          suggestedCategorieData
            ? suggestedCategorieData.findManySuggestedValueCategories
            : undefined
        }
      />
    </Stack>
  );
};

export default Template;
