import { FormEvent, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import {
  Stack,
  StackItem,
  TextField,
  Toggle,
  TextStyles,
} from '@fluentui/react';
import {
  default_page_size,
  stackTokens15,
  textFieldStyles300,
} from '../../utils';
import { GET_EMPLOYEES, IEmployee } from '../../utils/Employee';
import useDebounce from '../../components/hooks/useDebounce';
import { useAppDispatch } from '../../redux/hooks';
import { SeverityLevel, throwError } from '../../redux/error/errorSlice';
import SalesRepsOverview from './components/SalesRepsOverview';

interface IPagedSalesRep {
  salesReps: IEmployee[];
  skip: number;
  take: number;
  filter: string;
  initialLoad: boolean;
  showLoadMore: boolean;
  lazyLoading: boolean;
  isFiltered: boolean;
  isSortedAsc: boolean;
  sortedField: string;
}

const SalesReps = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();
  const [pagedState, setPagedState] = useState<IPagedSalesRep>({
    salesReps: [],
    skip: 0,
    take: default_page_size,
    filter: '',
    showLoadMore: true,
    lazyLoading: true,
    isFiltered: false,
    initialLoad: true,
    isSortedAsc: true,
    sortedField: 'first_name',
  });

  const [filter, setFilter] = useState('');
  const [active, setActive] = useState(true);

  const debouncedValue = useDebounce(filter, 500);

  useEffect(() => {
    client.cache.reset();
    setPagedState(prevState => ({ ...prevState, salesReps: [], skip: 0 }));
  }, [filter]);

  const getOrderBy = () => {
    const orderByObject: any = {}; // deliberately kept any as type to index by string
    orderByObject[pagedState.sortedField] = pagedState.isSortedAsc
      ? 'asc'
      : 'desc';
    return orderByObject;
  };

  const getFilters = (filterString: string, active: boolean) => {
    const filterArray = filterString.split(' ');

    const filterObject: any = {
      AND: [{ active }, { sales_rep: true }],
    };
    for (let i = 0; i < filterArray.length; i++) {
      const filterValue: any = {
        OR: [
          { first_name: { contains: filterArray[i] } },
          { last_name: { contains: filterArray[i] } },
          { function: { contains: filterArray[i] } },
          { city: { contains: filterArray[i] } },
          { address: { contains: filterArray[i] } },
        ],
      };

      const numericValue = parseInt(filterArray[i], 10);
      if (numericValue) filterValue.OR.push({ zip_code1: +numericValue });

      filterObject.AND.push(filterValue);
    }

    return filterObject;
  };

  const {
    data: dataSalesReps,
    loading,
    error,
    fetchMore,
    client,
  } = useQuery(GET_EMPLOYEES, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      filter: getFilters(debouncedValue, active),
      take: pagedState.take,
      skip: pagedState.skip,
      orderBy: getOrderBy(),
    },
    onError: error => {
      dispatch(
        throwError({
          module: 'employee overview',
          message: error.message,
          level: SeverityLevel.Critical,
        }),
      );
    },
    // fetchPolicy: 'no-cache',
  });

  const onSearchValueChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined,
  ) => {
    const filterString = newValue ? newValue.toLowerCase() : '';
    setFilter(filterString);
  };

  const onChangeFilterActive = () => {
    setActive(!active);
    clearSalesReps();
  };

  const setSorting = (isSortedAsc: boolean, sortedField: string) => {
    client.cache.reset();
    clearSalesReps();
    setPagedState(prevState => ({
      ...prevState,
      isSortedAsc,
      sortedField,
    }));
  };

  const clearSalesReps = () => {
    client.cache.reset();
    setPagedState(prevState => ({
      ...prevState,
      salesReps: [],
      skip: 0,
    }));
  };

  const fetchMoreSalesReps = () => {
    // Load more guard

    fetchMore({
      variables: {
        filter: getFilters(debouncedValue, active), // inUse == active?
        take: pagedState.take,
        skip: pagedState.skip + pagedState.take,
        orderBy: getOrderBy(),
      },
    });

    setPagedState(prevState => ({
      ...prevState,
      skip: pagedState.skip + pagedState.take,
    }));
  };

  if (!isAuthenticated) return <p>Verboden</p>;
  if (error) return <p>Oeps, er ging iets mis...</p>;

  return (
    <Stack tokens={stackTokens15}>
      <h3 style={{ marginBottom: 0 }}>Verkopers</h3>

      <Stack style={{ flexDirection: 'row' }}>
        <TextField
          label='Zoeken...'
          value={filter}
          styles={textFieldStyles300}
          onChange={onSearchValueChange}
        />

        <StackItem style={{ marginLeft: 70 }}>
          <Toggle
            label='In dienst?'
            defaultChecked={active}
            onText='In dienst'
            offText='Niet in dienst'
            onChange={onChangeFilterActive}
          />
        </StackItem>
      </Stack>

      <SalesRepsOverview
        salesReps={
          dataSalesReps
            ? dataSalesReps.findManyEmployees
            : []
        }
        loading={loading}
        setSorting={setSorting}
        isSortedAsc={pagedState.isSortedAsc}
        sortedField={pagedState.sortedField}
        showLoadMore={pagedState.showLoadMore}
        loadMore
        loadMoreCallback={fetchMoreSalesReps}
        lazyLoading={pagedState.lazyLoading}
      />
    </Stack>
  );
};

export default SalesReps;
