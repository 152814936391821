import {
  IComboBoxOption,
  Icon,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  TextField,
  DayOfWeek,
  defaultDatePickerStrings,
  DatePicker,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import {
  LazyQueryExecFunction,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/nl-be';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ComboboxWithFilter } from '../../../components/parts';
import {
  convertConstructionSitesToComboBoxOptions,
  convertEmployeesToComboBoxOptions,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOADING_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_TOAST_POSITION,
  GET_CONSTRUCTION_SITES,
  GET_EMPLOYEES,
  iconLastProps,
  IEmployee,
} from '../../../utils';
import {
  convertCostCentersToComboBoxOptions,
  GET_COST_CENTERS,
  ICostCenter,
} from '../../../utils/CostCenter';
import {
  ITimesheetEntryWithType,
  SAVE_TIMESHEETS,
} from '../../../utils/Timesheet';
import useDebounce from '../../../components/hooks/useDebounce';
import { ITimesheetEntry } from '../../../utils/TimesheetEntry';
import parseNumber from '../../../utils/Numbers';
import { toastSuccess, toastError } from '../../../utils/toast';

moment.locale('nl-be');

type TimeSheetProps = {
  timesheet: ITimesheetEntryWithType[];
  setTimesheet: React.Dispatch<React.SetStateAction<ITimesheetEntryWithType[]>>;
  getTimesheet: LazyQueryExecFunction<any, OperationVariables>;
  setGroupedTimesheetFilter: React.Dispatch<any>;
  currentDate: moment.Moment;
  setCurrentDate: React.Dispatch<React.SetStateAction<moment.Moment>>;
  days: moment.Moment[];
  setDays: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
  duration: number;
  employees: number;
};

function NewTimesheetProject({
  timesheet,
  setTimesheet,
  getTimesheet,
  setGroupedTimesheetFilter,
  currentDate,
  setCurrentDate,
  days,
  setDays,
  duration,
  employees,
}: TimeSheetProps) {
  const { data: employeesData } = useQuery(GET_EMPLOYEES, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        // active: true,
        is_external: false,
      },
    },
  });

  const [constructionSiteFilter, setConstructionSiteFilter] = useState('');

  const debouncedFilter = useDebounce(constructionSiteFilter, 500);

  const { loading: projectsLoading, data: projectsData } = useQuery(
    GET_CONSTRUCTION_SITES,
    {
      fetchPolicy: 'no-cache',
      variables: {
        take: 20,
        filter: debouncedFilter
          ? {
              name: {
                contains: debouncedFilter,
              },
            }
          : undefined,
      },
    },
  );

  const { data: costCentersData } = useQuery(GET_COST_CENTERS, {
    fetchPolicy: 'no-cache',
  });

  const [currentProject, setCurrentProject] = useState<number | string>();
  const [loader, setLoader] = useState(false);

  const [saveTimesheetEntries] = useMutation(SAVE_TIMESHEETS);

  const getValues = (values: any, comments?: string) => {
    const valuesWithoutType: any[] = [];
    values.map((value: ITimesheetEntry) => {
      valuesWithoutType.push({
        id: value.id || undefined,
        hours: parseNumber(value.hours),
        entry_date: value.entry_date,
        comments,
      });
    });

    return valuesWithoutType;
  };

  const parseTimesheets = (timesheets: ITimesheetEntryWithType[]) => {
    const newTimeSheets = _.cloneDeep(timesheets);

    for (let i = 0; i < newTimeSheets.length; i++) {
      const timesheet = newTimeSheets[i];
      if (timesheet.values && timesheet.values.length > 0) {
        timesheet.comments = timesheet.values[0].comments;
      }
    }

    return newTimeSheets;
  };

  const saveTimesheet = async () => {
    try {
      if (timesheet && currentProject) {
        const res = await toast.promise(
          new Promise((resolve, reject) => {
            const allInput: any[] = [];

            timesheet.map((timesheetEntry: any) => {
              allInput.push({
                construction_site: { id: currentProject },
                employee: timesheetEntry.employee
                  ? { id: timesheetEntry.employee.id }
                  : { id: 70 },
                cost_center: timesheetEntry.cost_center
                  ? { id: timesheetEntry.cost_center.id }
                  : undefined,
                status: timesheetEntry.status,
                values: getValues(
                  timesheetEntry.values,
                  timesheetEntry.comments,
                ),
              });
            });

            saveTimesheetEntries({
              variables: { data: allInput },
              update: (cache, data: any) => {
                setTimesheet(parseTimesheets(data.data.saveTimesheetObject));
                setLoader(false);
              },
              onCompleted: async x => {
                resolve(x);
              },
              onError: async error => {
                reject(error);
              },
            });
          }),
          {
            pending: {
              position: DEFAULT_TOAST_POSITION,
              render() {
                return DEFAULT_LOADING_MESSAGE;
              },
            },
          },
          {
            autoClose: DEFAULT_TOAST_DURATION,
          },
        );

        await res;
        toastSuccess('Werkuren opgeslagen');
      }
    } catch (error: any) {
      toastError(error.message ? error.message : DEFAULT_ERROR_MESSAGE);
    }
  };

  const [isTimesheetSorted, setisTimesheetSorted] = useState(false);

  useEffect(() => {
    if (!isTimesheetSorted && timesheet) {
      const tempTimesheet = [...timesheet];
      tempTimesheet.sort(
        (obj1: ITimesheetEntryWithType, obj2: ITimesheetEntryWithType) => {
          let compareFirstName = 0;
          if (
            obj1.employee &&
            obj1.employee.first_name &&
            obj2.employee &&
            obj2.employee.first_name
          ) {
            compareFirstName = obj1.employee.first_name.localeCompare(
              obj2.employee.first_name,
            );
          }

          let compareLastName = 0;
          if (
            obj1.employee &&
            obj1.employee.last_name &&
            obj2.employee &&
            obj2.employee.last_name
          ) {
            compareLastName = obj1.employee.last_name.localeCompare(
              obj2.employee.last_name,
            );
          }

          return compareFirstName || compareLastName;
        },
      );
      setisTimesheetSorted(true);
      setTimesheet(tempTimesheet);
    }
  }, [timesheet, isTimesheetSorted]);

  return (
    <Stack style={{ width: 1200 }}>
      <Stack
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
          padding: '0px 10px',
        }}
      >
        <Stack
          horizontal
          horizontalAlign='space-between'
          verticalAlign='end'
          grow={1}
        >
          <Stack>
            <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Label style={{ marginRight: 8, minWidth: '100px' }}>
                Datum:
              </Label>
              <DatePicker
                firstDayOfWeek={DayOfWeek.Monday}
                placeholder='Select a date...'
                ariaLabel='Select a date'
                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                strings={defaultDatePickerStrings}
                onSelectDate={date => {
                  setDays([]);
                  setCurrentDate(moment(date).startOf('week'));
                  getTimesheet({
                    variables: {
                      start: moment(date).startOf('week').toISOString(),
                      construction_site: currentProject,
                      duration,
                    },
                  });
                }}
                value={currentDate.toDate()}
                formatDate={date => moment(date).format('dd DD/MM/YYYY')}
                style={{
                  width: '250px',
                }}
              />
            </Stack>
            <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Label style={{ marginRight: 8, minWidth: '100px' }}>
                Project:
              </Label>
              <StackItem style={{ width: 220 }}>
                <ComboboxWithFilter
                  options={convertConstructionSitesToComboBoxOptions(
                    projectsData && projectsData.findManyConstructionSites
                      ? projectsData.findManyConstructionSites
                      : [],
                  )}
                  value={currentProject}
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      setCurrentProject(newValue[0].key);
                      getTimesheet({
                        variables: {
                          duration,
                          construction_site: newValue[0].key,
                          start: moment(currentDate).toISOString(),
                        },
                      });
                    }
                  }}
                  allowFreeForm
                  setFilter={(value: string) => {
                    setConstructionSiteFilter(value);
                  }}
                  style={{
                    width: '250px',
                  }}
                />
              </StackItem>
            </Stack>
          </Stack>
          <Stack style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Label style={{ marginRight: 5 }}>Aantal medewerkers:</Label>
            <StackItem>{employees}</StackItem>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
          alignSelf: 'flex-end',
          alignItems: 'center',
        }}
      >
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(-1, 'w').toISOString(),
                  construction_site: currentProject,
                  duration,
                },
              });

              setCurrentDate(moment(currentDate).add(-1, 'w'));
            }}
          >
            &lt;
          </PrimaryButton>
        </StackItem>
        <StackItem style={{ fontWeight: 700, fontSize: 15 }}>
          Week {moment(currentDate).isoWeek()}:{' '}
          {moment(currentDate)
            .startOf('week')
            .locale('nl-be')
            .format('dd DD/MM/YYYY')}{' '}
          -{' '}
          {moment(currentDate)
            .startOf('week')
            .add(6, 'd')
            .format('dd DD/MM/YYYY')}
        </StackItem>
        <StackItem style={{ fontWeight: 700 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => {
              setDays([]);
              getTimesheet({
                variables: {
                  start: moment(currentDate).add(1, 'w').toISOString(),
                  construction_site: currentProject,
                  duration,
                },
              });

              setCurrentDate(moment(currentDate).add(1, 'w'));
            }}
          >
            &gt;
          </PrimaryButton>
        </StackItem>
      </Stack>

      <Stack>
        <Stack
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid rgb(229, 229, 229)',
            fontWeight: 600,
          }}
        >
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Medewerker
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Post
          </StackItem>
          <StackItem
            style={{
              width: '20%',
              padding: 10,
              fontWeight: 600,
            }}
          >
            Notities
          </StackItem>
          {days.map((day, i: number) => (
            <StackItem
              style={{
                width: '5%',
                padding: 10,
                fontWeight: 600,
              }}
              key={`timesheet-days-${i}`}
            >
              {moment(day).format('ddd')}
              <br />
              {moment(day).format('DD/MM')}
            </StackItem>
          ))}
          <StackItem style={{ width: '2%' }}>&nbsp;</StackItem>
        </Stack>
        {timesheet &&
          timesheet.length > 0 &&
          timesheet.map((timesheetEntry: any, i: number) => (
            <Stack
              style={{
                flexDirection: 'row',
                borderBottom: '1px solid rgb(239, 239, 239)',
                alignItems: 'top',
              }}
              key={`timesheet-lines-${i}`}
            >
              <StackItem
                style={{
                  width: '20%',
                  padding: 10,
                }}
              >
                <ComboboxWithFilter
                  options={convertEmployeesToComboBoxOptions(
                    (() => {
                      // Ensure employees data is available
                      const employees = employeesData?.findManyEmployees ?? [];

                      // Determine if a specific employee is selected
                      const selectedEmployeeId = timesheetEntry?.employee?.id;

                      // Start with filtering only active employees if no specific employee is selected
                      const filteredEmployees = employees.filter(
                        (employee: any) => employee.active === true,
                      );

                      if (selectedEmployeeId !== undefined) {
                        const selectedEmployeeExists = filteredEmployees.some(
                          (employee: any) => employee.id === selectedEmployeeId,
                        );

                        // If the selected employee exists but is not active, add them to the list explicitly
                        if (!selectedEmployeeExists) {
                          const selectedEmployee = employees.find(
                            (employee: any) => employee.id === selectedEmployeeId,
                          );

                          if (selectedEmployee) {
                            filteredEmployees.push(selectedEmployee);
                          }
                        }
                        // If no specific selection, ensure the list includes only active employees
                        // This part is handled by the initial filter condition
                      }

                      // Sort the filtered list
                      return filteredEmployees.sort((obj1: any, obj2: any) => {
                        const compareName =
                          obj1.first_name && obj2.first_name
                            ? obj1.first_name.localeCompare(obj2.first_name)
                            : 0;
                        const compareTitle =
                          obj1.last_name && obj2.last_name
                            ? obj1.last_name.localeCompare(obj2.last_name)
                            : 0;
                        return compareName || compareTitle;
                      });
                    })(),
                  )}
                  required
                  errorMessage={
                    !timesheetEntry ||
                    !timesheetEntry.employee ||
                    !timesheetEntry.employee.id
                      ? 'Dit veld is verplicht'
                      : ''
                  }
                  value={timesheetEntry?.employee?.id}
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = _.cloneDeep(timesheet);
                      if (result[i].employee) {
                        result[i].employee.id = +newValue[0].key;
                      } else {
                        result[i].employee = { id: +newValue[0].key };
                      }
                      setTimesheet(result);
                    }
                  }}
                />
              </StackItem>
              <StackItem
                style={{
                  width: '20%',
                  padding: 10,
                }}
              >
                <ComboboxWithFilter
                  options={convertCostCentersToComboBoxOptions(
                    costCentersData &&
                      costCentersData.findManyCostCenters.sort(
                        (obj1: ICostCenter, obj2: ICostCenter) =>
                          obj1.name &&
                          obj2.name &&
                          obj1.name.localeCompare(obj2.name),
                      ),
                  )}
                  required
                  errorMessage={
                    (timesheetEntry && !timesheetEntry.cost_center) ||
                    (timesheetEntry.cost_center &&
                      !timesheetEntry.cost_center.id)
                      ? 'Dit veld is verplicht'
                      : ''
                  }
                  value={
                    timesheetEntry &&
                    timesheetEntry.cost_center &&
                    timesheetEntry.cost_center.id
                  }
                  multiline={false}
                  callBack={(newValue: IComboBoxOption[]) => {
                    if (newValue && newValue.length > 0) {
                      const result = _.cloneDeep(timesheet);
                      if (result[i].cost_center) {
                        result[i].cost_center.id = +newValue[0].key;
                      } else {
                        result[i].cost_center = { id: +newValue[0].key };
                      }
                      setTimesheet(result);
                    }
                  }}
                />
              </StackItem>
              <StackItem
                style={{
                  width: '20%',
                  padding: 10,
                }}
              >
                <TextField
                  value={timesheetEntry.comments || ''}
                  onChange={(
                    event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string,
                  ) => {
                    const result = _.cloneDeep(timesheet);
                    result[i].comments = newValue;
                    setTimesheet(result);
                  }}
                />
              </StackItem>
              {days.map((day, key: number) => {
                const entry: any = timesheetEntry.values.find(
                  (x: any) => moment(x.entry_date).isSame(day, 'day'),
                  // eslint-disable-next-line function-paren-newline
                );
                const entryIndex: number = timesheetEntry.values.findIndex(
                  (x: any) => moment(x.entry_date).isSame(day, 'day'),
                );
                return (
                  <StackItem
                    style={{
                      width: '5%',
                      padding: 10,
                      backgroundColor:
                        key === 5 || key === 6
                          ? 'rgb(239, 239, 239)'
                          : 'transparent',
                    }}
                    key={`day-${i}-${key}`}
                  >
                    <TextField
                      name={entry ? entry.id : `day-${i}`}
                      value={entry ? entry.hours : '0'}
                      min={0}
                      onKeyDown={e => {
                        // key is tab
                        if (
                          e.key === 'Tab' &&
                          i < timesheet.length &&
                          key === days.length - 1
                        ) {
                          setTimesheet((prevState: any) => [
                            ...prevState,
                            {
                              construction_site: currentProject,
                              cost_center: undefined,
                              employee: undefined,
                              status: 'tse_stat_3136',
                              values: [],
                            },
                          ]);
                        }
                      }}
                      onChange={(
                        event: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string,
                      ) => {
                        const result = _.cloneDeep(timesheet);
                        if (entry && result[i] && result[i].values) {
                          result[i].values[entryIndex].hours = newValue;
                          setTimesheet(result);
                        } else {
                          const result = _.cloneDeep(timesheet);
                          result[i].values.push({
                            hours: newValue,
                            entry_date: moment(day).toISOString(),
                          });
                          setTimesheet(result);
                        }
                      }}
                    />
                  </StackItem>
                );
              })}
              <StackItem
                style={{
                  alignSelf: 'center',
                  width: '2%',
                  textAlign: 'right',
                }}
                onClick={() => {
                  const result = _.cloneDeep(timesheet);
                  result[i].values &&
                    result[i].values.map((value: any, x: number) => {
                      result[i].values[x].hours = 0;
                    });
                  setTimesheet(result);
                }}
              >
                <Icon iconName='Delete' styles={iconLastProps} />
              </StackItem>
            </Stack>
          ))}
      </Stack>

      <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack style={{ padding: 10 }}>
          <PrimaryButton
            disabled={!currentProject}
            style={{ marginRight: 5 }}
            onClick={() => {
              setTimesheet((prevState: any) => [
                ...prevState,
                {
                  construction_site: currentProject,
                  cost_center: undefined,
                  employee: undefined,
                  status: 'tse_stat_3136',
                  values: [],
                },
              ]);
            }}
          >
            Medewerker toevoegen
          </PrimaryButton>
        </Stack>

        <Stack style={{ padding: 10 }}>
          <PrimaryButton
            disabled={!currentProject}
            onClick={() => saveTimesheet()}
          >
            Werkuren opslaan{' '}
            {loader && (
              <Spinner style={{ marginLeft: 10 }} size={SpinnerSize.small} />
            )}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default NewTimesheetProject;
